import { Portal } from '@gorhom/portal'
import React from 'react'
import {
  StyleSheet, Pressable,
} from 'react-native'
import Animated, {
  ZoomOut, ZoomIn,
} from 'react-native-reanimated'
import { Shadow } from 'react-native-shadow-2'

import {
  Column, Overlay, Row,
} from '../../components/Primitives'
import { FIGMA_COLORS } from '../../contexts/Theme'
import ReactionOverlayButton from './ReactionOverlayButton'
import reactionToEmoticonMap from './reactionToEmoticonMap'

import type {
  ReactionType,
} from '../../clients/backend.generated'

const styles = StyleSheet.create({
  reactionShadow: { borderRadius: 50 },
  reactionOverlay: { left: 0, position: 'absolute' },
  pressableBackdrop: { width: '100%', height: '100%' },
  reactionWrapper: {
    bottom: 20,
    left: 0,
    position: 'absolute',
  },
})

const ReactionOverlay: React.FC<{
  readonly hideAllReactions: () => void,
  readonly onPress: (reaction: ReactionType) => void,
  readonly userReactions: readonly ReactionType[],
  readonly y: number
}> = ({
  hideAllReactions, onPress, userReactions, y,
}) => (
  <Portal>
    <Overlay>
      <Pressable
        accessibilityRole='button'
        onPress={hideAllReactions}
        style={styles.pressableBackdrop}
      >
        <Overlay
          width='100%'
          height='100%'
        />
      </Pressable>
      <Animated.View
        entering={ZoomIn}
        exiting={ZoomOut}
        style={[styles.reactionOverlay, { top: y + 10 }]}
      >
        <Overlay>
          <Column
            style={styles.reactionWrapper}
            margin={10}
          >
            <Shadow style={styles.reactionShadow}>
              <Row
                backgroundColor={FIGMA_COLORS.ESK_GRAY_4}
                borderRadius={50}
                padding={5}
              >
                { Object.keys(reactionToEmoticonMap).map((reactionStr) => {
                  const reaction = reactionStr as ReactionType

                  return (
                    <ReactionOverlayButton
                      key={reaction}
                      onPress={onPress}
                      reaction={reaction}
                      userReactions={userReactions}
                    />
                  )
                }) }
              </Row>
            </Shadow>
          </Column>

        </Overlay>
      </Animated.View>
    </Overlay>
  </Portal>
)

export default React.memo(ReactionOverlay)
