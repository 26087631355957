import React from 'react'
import Animated, { FadeInUp } from 'react-native-reanimated'

import { filterEmpty } from '../types'
import CmsContentComponent from './CmsContentComponent'

import type { CmsContentComponentProps } from './CmsContentComponent'

interface CmsContentProps {
  readonly animate?: boolean
  readonly data?: ReadonlyArray<CmsContentComponentProps['data'] | null> | null
  readonly keyPrefix?: string | null
}

export const CmsContent: React.FC<CmsContentProps> = ({ animate, data, keyPrefix = '' }) => {
  const filteredContent = filterEmpty(data)

  if (animate ?? false) {
    return (
      <>
        {filteredContent.map((content, index) => (
          <Animated.View
            key={`${keyPrefix}#${content.__typename}#${'id' in content ? content.id : ''}`}
            entering={FadeInUp.delay(index * 100)}
          >
            <CmsContentComponent data={content} />
          </Animated.View>
        ))}
      </>
    )
  }

  return (
    <>
      {filteredContent.map((content) => (
        <CmsContentComponent
          key={`${keyPrefix}#${content.__typename}#${'id' in content ? content.id : ''}`}
          data={content}
        />
      ))}
    </>
  )
}

export default CmsContent
