import { useMemo } from 'react'
import { Text, StyleSheet } from 'react-native'
import WithSeparator from 'react-with-separator'

import { filterEmpty } from '../types'
import { createThemedView } from '../utils/createThemedStylesHook'
import { Column, Row } from './Primitives'

import type { CmsLabelFragment } from '../clients/backend.generated'

const styles = StyleSheet.create({
  subtitle: { textAlign: 'center', fontSize: 10, fontWeight: '700' },
  title: { textAlign: 'center', fontSize: 14 },
})

const Separator = createThemedView(({ theme }) => ({
  backgroundColor: theme.colors.accent,
  height: '100%',
  width: 2,
}))

interface Props {
  readonly labels?: ReadonlyArray<CmsLabelFragment | null> | null
}

const CmsLabelsComponent: React.FC<Props> = ({ labels }) => {
  const nonEmptyLabels = useMemo(() => filterEmpty(labels), [labels])

  if (nonEmptyLabels.length === 0) return null

  return (
    <Row paddingTop={10}>
      <WithSeparator separator={<Separator />}>
        {nonEmptyLabels.map(({ id, subtitle, title }) => (
          <Column key={id} fill>
            <Text style={styles.subtitle}>{subtitle}</Text>
            <Text style={styles.title}>{title}</Text>
          </Column>
        )) }
      </WithSeparator>
    </Row>
  )
}

export default CmsLabelsComponent
