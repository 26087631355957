export default function msToString(ms: number) {
  'worklet'

  const hours = Math.floor(ms / 3600000)
  const minutes = Math.floor(ms / 60000) % 60
  const seconds = Math.floor((ms) / 1000) % 60
  const centi = Math.floor(ms / 100) % 10

  const prefix = hours > 0 ? `${hours}:` : ''

  return `${prefix}${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds},${centi}`
}
