import { useCallback } from 'react'
import { IconButton } from 'react-native-paper'

import { FIGMA_COLORS } from '../contexts/Theme'

import type { GestureResponderEvent, StyleProp, ViewStyle } from 'react-native'
import type { IconSource } from 'react-native-paper/lib/typescript/components/Icon'

export type HeaderButtonProps = {
  readonly icon: IconSource
  readonly onPress: () => void
  readonly style?: StyleProp<ViewStyle>
}

const HIT_SLOP = {
  top: 30, bottom: 30, left: 30, right: 30,
}

const HeaderButton: React.FC<HeaderButtonProps> = ({ icon, onPress, style }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnPress = useCallback((_: GestureResponderEvent) => onPress(), [onPress])

  return (
    <IconButton
      color={FIGMA_COLORS.GRAY_1}
      icon={icon}
      style={style}
      hitSlop={HIT_SLOP}
      onPress={handleOnPress}
    />
  )
}

export default HeaderButton
