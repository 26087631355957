import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useEvent } from '@kingstinct/react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect, useMemo } from 'react'
import { Shadow } from 'react-native-shadow-2'

import { WorkoutActivityType } from '../clients/backend.generated'
import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import { WorkoutActivityTypeNames } from '../types'
import { createThemedText } from '../utils/createThemedStylesHook'
import Styles, { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import DropDownMenu from './DropDownMenu'
import { Row } from './Primitives'

import type { StartStackParamList, ESKRootParamList } from '../types'
import type { DropDownMenuItemRootType } from './DropDownMenu'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'

const WORKOUT_ACTIVITY_TYPE_STORAGE_KEY = 'workoutActivityType'

export function useWorkoutActivityTypeSelection(workoutActivityType: WorkoutActivityType | undefined, navigation: NativeStackNavigationProp<ESKRootParamList & StartStackParamList, 'StartScreen'>) {
  const init = useEvent(async () => {
    const persistedValue = await AsyncStorage.getItem(WORKOUT_ACTIVITY_TYPE_STORAGE_KEY)
    if (persistedValue) {
      navigation.setParams({ workoutActivityType: persistedValue as WorkoutActivityType })
    }
  })

  useEffect(() => {
    void init()
  }, [init])

  const onSelect = useEvent(() => navigation.navigate('WorkoutActivityTypeSelectionScreen', { callbackScreen: 'StartScreen' }))

  const setValue = useEvent((value: WorkoutActivityType) => {
    void AsyncStorage.setItem(WORKOUT_ACTIVITY_TYPE_STORAGE_KEY, value)

    navigation.setParams({ workoutActivityType: value })
  })

  return useMemo(() => ({
    onSelect,
    setValue,
    value: workoutActivityType ?? WorkoutActivityType.RUNNING,
  }), [workoutActivityType, onSelect, setValue])
}

const BASE_WORKUT_ACTIVITY_TYPES = [
  WorkoutActivityType.RUNNING, WorkoutActivityType.SWIMMING, WorkoutActivityType.CYCLING, WorkoutActivityType.CROSS_COUNTRY_SKIING,
]

const Title = createThemedText(({ theme }) => ({
  color: theme.colors.text,
  fontSize: 18,
  fontWeight: '700',
  textTransform: 'uppercase',
}))

interface WorkoutActivityTypeSelectionProps {
  readonly disabled: boolean
  readonly onSelect?: () => void
  readonly setValue?: (workoutActivityType: WorkoutActivityType) => void
  readonly value: WorkoutActivityType
}

const WorkoutActivityTypeSelection: React.FC<WorkoutActivityTypeSelectionProps> = ({
  disabled, onSelect, setValue: setWorkoutActivityType, value: workoutActivityType,
}) => {
  const theme = useTheme()

  const menuItems = useMemo<readonly DropDownMenuItemRootType[]>(() => {
    const items = BASE_WORKUT_ACTIVITY_TYPES.map<DropDownMenuItemRootType>((activityType) => ({
      label: WorkoutActivityTypeNames[activityType],
      onPress: () => setWorkoutActivityType?.(activityType),
      type: 'checkable',
      status: workoutActivityType === activityType ? 'on' : 'off',
    }))

    const searchItem: DropDownMenuItemRootType = {
      label: 'Sök ...', icon: 'text-search', onPress: () => onSelect?.(), type: 'item',
    }

    if (workoutActivityType && !BASE_WORKUT_ACTIVITY_TYPES.includes(workoutActivityType)) {
      return [
        ...items,
        {
          label: WorkoutActivityTypeNames[workoutActivityType], onPress: () => { }, type: 'checkable', status: 'on',
        },
        searchItem,
      ]
    }
    return [...items, searchItem]
  }, [onSelect, setWorkoutActivityType, workoutActivityType])

  const title = useMemo(() => (workoutActivityType ? WorkoutActivityTypeNames[workoutActivityType] : 'Välj träningsform'), [workoutActivityType])

  return (
    <DropDownMenu menuItems={disabled ? [] : menuItems}>
      <Shadow
        distance={2}
        offset={DEFAULT_SHADOW_OFFSET}
        style={Styles.width100pct}
      >
        <Row backgroundColor={FIGMA_COLORS.ESK_VIT} padding={16} spaceBetween>
          <Title>
            {title}
          </Title>
          {disabled ? null : (
            <MaterialCommunityIcons
              name='chevron-down'
              size={24}
              color={theme.colors.text}
              style={Styles.alignSelfCenter}
            />
          )}
        </Row>
      </Shadow>
    </DropDownMenu>
  )
}

export default WorkoutActivityTypeSelection
