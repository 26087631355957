import React from 'react'
import { StyleSheet } from 'react-native'
import Upstream from 'react-native-markdown-display'

import { FIGMA_COLORS } from '../contexts/Theme'

import type { PropsWithChildren } from 'react'

const markdownStyles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  body: {
    fontSize: 14,
    color: FIGMA_COLORS.GRAY_2,
  },
  /* bullet_list_icon: Platform.OS === 'android'
    ? androidBulletList
    : Platform.OS === 'ios'
      ? iosBulletList
      : webBulletList,
  fence: {
    borderColor: theme.colors.onSurface,
    backgroundColor: `${theme.colors.primary}22`,
  },
  code_inline: {
    color: theme.colors.text,
    backgroundColor: `${theme.colors.primary}22`,
    borderRadius: 10,
    padding: 0,
    paddingHorizontal: 5,
    borderColor: theme.colors.onSurface,
  },
  paragraph: {
    marginTop: lineHeight / 2 - 2,
    marginBottom: lineHeight / 2 + 2,
    lineHeight,
    overflow: 'hidden',
  },
  heading1: {
    paddingTop: headingPaddingTop,
    lineHeight: lineHeight + 12,
    paddingBottom: 5,
  },
  heading2: {
    paddingTop: headingPaddingTop,
    lineHeight: lineHeight + 5,
  },
  heading3: {
    paddingTop: headingPaddingTop,
  },
  heading4: {
    paddingTop: headingPaddingTop,
  },
  heading5: {
    paddingTop: headingPaddingTop,
  },
  heading6: {
    paddingTop: headingPaddingTop,
  },
  link: {
    color: theme.colors.primary,
  },
  blockquote: {
    borderColor: theme.colors.primary,
  },
  code_block: {
    color: theme.colors.onPrimary,
  }, */
})

const Markdown: React.FC<PropsWithChildren> = ({ children }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Upstream style={markdownStyles}>
    {children}
  </Upstream>
)

export default Markdown
