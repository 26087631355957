import { FlashList } from '@shopify/flash-list'
import { useCallback, useState } from 'react'
import Animated, { FadeInUp } from 'react-native-reanimated'

import { CmsEnum_Workoutarticle_Workouttype as WorkoutType, useWorkoutListingQuery } from '../clients/backend.generated'
import Box from '../components/Box'
import FetchingComponent from '../components/FetchingComponent'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { Column } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { usePublicationState } from '../contexts/EditorMode'
import { useMergePagedResults } from '../hooks/useMergePagedResults'
import useSanitizeCmsData from '../hooks/useSanitizeCmsData'
import createThemedStylesHook, { createThemedView } from '../utils/createThemedStylesHook'

import type { WorkoutArticleListingFragment } from '../clients/backend.generated'
import type { BoxProps } from '../components/Box'
import type { ESKScreen, WorkoutTabParamList } from '../types'

const WORKOUT_TYPE_MAP: Record<keyof WorkoutTabParamList, WorkoutType> = {
  BikingWorkoutTab: WorkoutType.BIKING,
  RunningWorkoutTab: WorkoutType.RUNNING,
  SkiingWorkoutTab: WorkoutType.SKIING,
  SwimmingWorkoutTab: WorkoutType.SWIMMING,
  StrengthWorkoutTab: WorkoutType.SWIMMING,
}

function resolveIcon(kind?: WorkoutArticleListingFragment['kind'][number]): BoxProps['icon'] | undefined {
  switch (kind?.__typename) {
    case 'CmsComponentWorkoutWorkoutProgram': return 'calendar'
    case 'CmsComponentWorkoutWorkoutTip': return 'info'
    case 'CmsComponentWorkoutWorkoutSession': return 'timer'
    default: return undefined
  }
}

const useStyles = createThemedStylesHook(({ size }) => ({
  item: {
    alignSelf: 'center',
    marginVertical: 8,
    width: size.width - 32,
  },
}))

const ListFooter = createThemedView(({ insets }) => ({
  height: 120 + insets.bottom,
}))

const WorkoutArticleListingScreen: ESKScreen<'WorkoutListing'> = ({ navigation, route: { name } }) => {
  const publicationState = usePublicationState(),
        workoutType = WORKOUT_TYPE_MAP[name as keyof WorkoutTabParamList],
        [page, setPage] = useState(1),
        [{ data: queryData, fetching }, fetch] = useWorkoutListingQuery({ variables: { page, publicationState, workoutType } }),
        [pagedResults, { refetch, fetchMore }] = useMergePagedResults(queryData?.workoutArticles, { setPage, fetching, fetch }),
        data = useSanitizeCmsData(pagedResults),
        styles = useStyles()

  const onPress = useCallback((id: string) => () => {
    navigation.navigate('WorkoutArticle', { id })
  }, [navigation])

  return (
    <FlashList
      data={data}
      refreshControl={<RefetchControl onRefetch={refetch} />}
      keyExtractor={(item) => item.id}
      onEndReached={fetchMore}
      estimatedItemSize={100}
      renderItem={({
        index, item: {
          id, attributes: {
            headerImage, kind, subtitle, title,
          },
        },
      }) => (
        <Animated.View
          entering={FadeInUp.delay(index * 100)}
          style={styles.item}
        >
          <Box
            title={title}
            subtitle={subtitle}
            uri={headerImage?.data?.attributes?.url}
            chevron
            icon={resolveIcon(kind[0])}
            onPress={onPress(id)}
          />
        </Animated.View>
      )}
      ListEmptyComponent={fetching ? <FetchingComponent /> : <ListEmptyComponent refetch={refetch} />}
      ListHeaderComponent={<Column height={16} />}
      ListFooterComponent={ListFooter}
    />
  )
}

export default WorkoutArticleListingScreen
