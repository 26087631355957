import { createThemedView } from '../utils/createThemedStylesHook'
import { HEADER_HEIGHT } from '../utils/Styles'

import type { FactoryProps } from '../utils/createThemedStylesHook'

export * from '@kingstinct/react/primitives'

export const HeaderSpacer = createThemedView(({ displaysActivityIcons, insets }: FactoryProps<{ readonly displaysActivityIcons?: boolean}>) => ({
  height: insets.top + (displaysActivityIcons ? 90 : 0) + HEADER_HEIGHT,
}))

export enum BottomSpacerType {
  bottomNavigation,
  noBottomNavigation
}

export const BottomSpacer = createThemedView(({
  insets,
  extraSpace = 0, type = BottomSpacerType.bottomNavigation,
}: FactoryProps<{ readonly extraSpace?: number, readonly type?: BottomSpacerType }>) => ({
  height: insets.bottom + extraSpace + (type === BottomSpacerType.bottomNavigation ? 196 : 50),
}))
