import { Styles, useBoolean } from '@kingstinct/react'
import useBooleanWithHaptics from '@kingstinct/react/hooks/useBooleanWithHaptics'
import useEvent from '@kingstinct/react/hooks/useEvent'
import { useNavigation } from '@react-navigation/native'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import {
  Image, Platform, StyleSheet, Text as NativeText,
} from 'react-native'
import { PressableOpacity } from 'react-native-pressable-opacity'
import Animated, {
  ZoomIn,
} from 'react-native-reanimated'
import { Shadow } from 'react-native-shadow-2'

import {
  useReactToFeedItemMutation,
  useUnreactToFeedItemMutation,
} from '../../clients/backend.generated'
import {
  Column, Row, Text,
} from '../../components/Primitives'
import { FIGMA_COLORS } from '../../contexts/Theme'
import { useOpenInKlassikernThemedBrowser } from '../../utils/browser'
import { DEFAULT_SHADOW_OFFSET } from '../../utils/Styles'
import ActionRow from './ActionRow'
import ReactionOverlay from './ReactionOverlay'
import ReactionRow from './ReactionRow'

import type {
  FeedItemDefaultFragment,
  ReactionType,
} from '../../clients/backend.generated'
import type {
  GestureResponderEvent, NativeSyntheticEvent, ImageLoadEventData, LayoutChangeEvent,
} from 'react-native'

const styles = StyleSheet.create({
  column: { backgroundColor: 'white' },
  shadow: { width: '100%', borderRadius: 10 },
  image: {
    width: '100%',
    borderRadius: 10,
    minHeight: 1,
  },
  publishedAt: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 14,
    fontWeight: '400',
  },
  title: {
    color: FIGMA_COLORS.ESK_GRAY_2,
    fontSize: 14,
    fontWeight: '700',
  },
  logo: { width: 50, height: 50 },
})

interface Props {
  readonly data: FeedItemDefaultFragment
}

const NewsArticleImage: React.FC<{readonly imageUrl: string}> = ({ imageUrl }) => {
  const [ratio, setRatio] = useState(1),
        [componentWidth, setComponentWidth] = useState(100),
        [hasImageError, setImageError] = useBoolean()

  const onLoad = useEvent(({
    nativeEvent,
  }: NativeSyntheticEvent<ImageLoadEventData>) => {
    if (Platform.OS !== 'web') {
      const { source: { height, width } } = nativeEvent
      setRatio(height / width)
    }
  })

  const onLayout = useEvent(({ nativeEvent: { layout: { width } } }: LayoutChangeEvent) => {
    setComponentWidth(width)
  })

  return (!imageUrl || hasImageError) ? null : (
    <Animated.Image
      accessibilityIgnoresInvertColors
      entering={ZoomIn}
      onError={setImageError}
      onLoad={onLoad}
      resizeMode='cover'
      onLayout={onLayout}
      source={{ uri: imageUrl }}
      style={[styles.image, { height: componentWidth * ratio }]}
    />
  )
}

const NewsArticleShareComponent: React.FC<Props> = ({
  data: {
    imageUrl, publishedAt, title,
    description, shareUrl, id, type, userReactions,
    reactionCounts,
  },
}) => {
  const [, reactToFeedItem] = useReactToFeedItemMutation(),
        [, unreactToFeedItem] = useUnreactToFeedItemMutation(),
        navigation = useNavigation(),
        openInKlassikernThemedBrowser = useOpenInKlassikernThemedBrowser()

  const onPress = useEvent(() => {
    if (shareUrl) {
      void openInKlassikernThemedBrowser(shareUrl)
    } else if (id) {
      navigation.navigate('NewsArticle', { id })
    }
  })

  const [isAllReactionsVisible, showAllReactionsInternal, hideAllReactions] = useBooleanWithHaptics(false)

  const [{ y }, setCoords] = useState<{readonly x: number, readonly y: number}>({
    x: 0,
    y: 0,
  })

  const showAllReactions = useEvent(({ nativeEvent }: GestureResponderEvent) => {
    setCoords({ x: nativeEvent.pageX, y: nativeEvent.pageY })
    showAllReactionsInternal()
  })

  const onPressReaction = useEvent((reaction: ReactionType) => {
    if (userReactions.includes(reaction)) {
      void unreactToFeedItem({ id, reaction, type })
    } else {
      void reactToFeedItem({ id, reaction, type })
    }
    hideAllReactions()
  })

  return (
    <PressableOpacity
      accessibilityRole='button'
      activeOpacity={0.7}
      onPress={onPress}
    >
      <Shadow
        distance={2}
        offset={DEFAULT_SHADOW_OFFSET}
        style={[styles.shadow, Styles.borderRadius8]}
      >
        <Column
          fill
          style={styles.column}
          padding={20}
          borderRadius={10}
        >
          <Row fill paddingBottom={10}>
            <Image
              source={require('../../../assets/esk_logga_platta.png')}
              accessibilityIgnoresInvertColors
              style={styles.logo}
            />
            <Column fill centerY paddingLeft={10}>
              <Text style={styles.title}>En Svensk Klassiker</Text>
              <Text style={styles.publishedAt}>
                {dayjs(publishedAt).format('D MMMM YYYY')}
              </Text>
            </Column>
          </Row>
          <Column paddingX={5}>
            <Row paddingY={10}>
              <Text style={styles.title}>{ title }</Text>
            </Row>
            <Row
              paddingY={10}
              paddingBottom={20}
            >
              <NativeText numberOfLines={2}>{ description?.trim() }</NativeText>
            </Row>
            {(!imageUrl) ? null : <NewsArticleImage imageUrl={imageUrl} />}

          </Column>
          { reactionCounts.length > 0 ? (
            <ReactionRow
              onPress={onPressReaction}
              reactionCounts={reactionCounts}
              showAllReactions={showAllReactions}
              userReactions={userReactions}
            />
          ) : null }

          <ActionRow
            onPressReaction={onPressReaction}
            shareUrl={shareUrl}
            showAllReactions={showAllReactions}
            title={title}
            userReactions={userReactions}
          />

          { isAllReactionsVisible ? (
            <ReactionOverlay
              hideAllReactions={hideAllReactions}
              userReactions={userReactions}
              onPress={onPressReaction}
              y={y}
            />
          ) : null }
        </Column>
      </Shadow>
    </PressableOpacity>
  )
}

export default React.memo(NewsArticleShareComponent)
