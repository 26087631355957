import { ActivityIndicator } from 'react-native-paper'

import { useTheme } from '../contexts/Theme'
import { Column } from './Primitives'

const FetchingComponent = () => {
  const theme = useTheme()

  return (
    <Column
      fill
      center
    >
      <ActivityIndicator
        size={40}
        color={theme.colors.primary}
      />
    </Column>
  )
}

export default FetchingComponent
