import useBooleanWithHaptics from '@kingstinct/react/hooks/useBooleanWithHaptics'
import dayjs from 'dayjs'
import React, { useCallback, useImperativeHandle } from 'react'
import { Platform } from 'react-native'
import DateTimePickerModal from 'react-native-modal-datetime-picker'

import FormButton from './FormButton'

interface Props {
  /** Used if value is null or undefined, default is 'new Date()' */
  readonly initialDate?: Date
  readonly label: string
  readonly maximumDate?: Date
  readonly minimumDate?: Date
  readonly onChange: (date: Date) => void
  readonly value?: Date
}

interface Ref {
  readonly focus: () => void
}

const DatePicker = React.forwardRef<Ref, Props>((props, ref) => {
  const {
    initialDate, label, maximumDate, minimumDate, onChange, value,
  } = props
  const [isVisible, show, hide] = useBooleanWithHaptics()

  const onConfirm = useCallback((callback: (date: Date) => void) => (date: Date) => {
    callback(date)
    hide()
  }, [hide])

  useImperativeHandle(ref, () => ({
    focus: show,
  }))

  return (
    <>
      <FormButton
        onPress={show}
        placeholder='Välj datum'
        value={value ? dayjs(value).format('YYYY-MM-DD') : undefined}
      />
      <DateTimePickerModal
        accessibilityHint={label}
        accessibilityLabel={label}
        cancelTextIOS='Avbryt'
        confirmTextIOS='Välj'
        date={value ?? initialDate}
        display={Platform.select({ ios: 'inline' })}
        isVisible={isVisible}
        maximumDate={maximumDate}
        minimumDate={minimumDate}
        mode='date'
        onCancel={hide}
        onConfirm={onConfirm(onChange)}
        themeVariant='light'
      />
    </>
  )
})

export default DatePicker
