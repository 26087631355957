import type { Coord } from './reduceCoordinatesToMeters'

const MIN_ITEMS_FOR_OUTLIERS = 4

function getQuantile(arr: readonly number[], quantile: number) {
  const array = arr.slice().sort((a, b) => a - b)
  // Get the index the quantile is at.
  const index = (array.length - 1) * (quantile / 100.0)

  // Check if it has decimal places.
  if (index % 1 === 0) {
    return array[index]
  }
  // Get the lower index.
  const lowerIndex = Math.floor(index)
  // Get the remaining.
  const remainder = index - lowerIndex

  // Add the remaining to the lowerindex value.
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return array[lowerIndex]! + remainder * (array[lowerIndex + 1]! - array[lowerIndex]!)
}
const getMostAccurateCoordinates = (coordinates: readonly Coord[], quantileThreshold = 85, minItems = MIN_ITEMS_FOR_OUTLIERS) => {
  if (coordinates.length <= minItems || coordinates.some(({ accuracy }) => accuracy === undefined || accuracy === null)) {
    return coordinates
  }
  const quantile = getQuantile(coordinates.map(({ accuracy }) => accuracy ?? 0), quantileThreshold)

  return coordinates.filter(({ accuracy }) => accuracy! < quantile!)
}

export default getMostAccurateCoordinates
