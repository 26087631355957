import { AuthContext, useNullableState } from '@kingstinct/react'
import capitalize from 'capitalize'
import React, {
  useCallback, useContext, useEffect, useMemo, useRef,
} from 'react'
import { useForm } from 'react-hook-form'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Snackbar } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import unreachable from 'ts-unreachable'

import { useSignUpWithBankIdMutation } from '../../clients/backend.generated'
import ControlledNationalityCodeInput from '../../components/ControlledNationalityCodeInput'
import ControlledTextInput from '../../components/ControlledTextInput'
import Label from '../../components/Label'
import RoundedButton from '../../components/RoundedButton'
import { createThemedText, createThemedView } from '../../utils/createThemedStylesHook'
import Styles from '../../utils/Styles'

import type { SignUpWithBankIdInput } from '../../clients/backend.generated'
import type { ESKScreen } from '../../types'
import type { SubmitHandler } from 'react-hook-form'

const BottomPadding = createThemedView(({ insets }) => ({
  height: insets.bottom,
}))

const PersonalNumberText = createThemedText(() => ({
  fontSize: 20,
  marginBottom: 16,
  marginTop: 4,
}))

function capitalizeWords(text: string | null | undefined): string | undefined {
  return text == null ? undefined : capitalize.words(text)
}

const SignupBankIdScreen: ESKScreen<'SignupBankIdScreen'> = ({
  route: {
    params: {
      email,
      firstName,
      languageCode,
      lastName,
      nationalityCode,
      sessionId,
      personalNumber,
    },
  },
}) => {
  const { setToken } = useContext(AuthContext),
        {
          handleSubmit, setValue, ...form
        } = useForm<SignUpWithBankIdInput>({
          defaultValues: {
            email: email?.toLowerCase(),
            firstName: capitalizeWords(firstName),
            languageCode,
            lastName: capitalizeWords(lastName),
            nationalityCode,
            sessionId,
          },
        }),
        [{ fetching }, signUpWithBankIdMutation] = useSignUpWithBankIdMutation(),
        [errorMessage, setErrorMessage, unsetErrorMessage] = useNullableState<string>(),
        edges = useMemo(() => ['top'] as const, []),
        { current: personalNumberRef } = useRef(personalNumber)

  useEffect(() => {
    if (nationalityCode != null) setValue('nationalityCode', nationalityCode)
  }, [nationalityCode, setValue])

  const onSubmit = useCallback<SubmitHandler<SignUpWithBankIdInput>>(async (input) => {
    const { data, error } = await signUpWithBankIdMutation({ input })

    if (data?.signupWithBankId == null) {
      if (error?.message) {
        setErrorMessage(error.message)
      } else {
        unsetErrorMessage()
      }
    } else if (data.signupWithBankId.__typename === 'AuthenticationSuccess') {
      setToken(data.signupWithBankId.token)
    } else if (data.signupWithBankId.__typename === 'AuthenticationFailure') {
      setErrorMessage(data.signupWithBankId.message)
    } else {
      unreachable(data.signupWithBankId)
    }
  }, [
    setToken, setErrorMessage, unsetErrorMessage, signUpWithBankIdMutation,
  ])

  return (
    <SafeAreaView style={Styles.flexOne} edges={edges}>
      <KeyboardAwareScrollView
        enableOnAndroid
        extraHeight={80}
        extraScrollHeight={80}
        // https://github.com/APSL/react-native-keyboard-aware-scroll-view/issues/502
        keyboardOpeningTime={0}
      >
        <Label title='Personnummer'>
          <PersonalNumberText>{personalNumberRef}</PersonalNumberText>
        </Label>

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'words',
            autoComplete: 'name-given',
            autoCorrect: false,
            keyboardType: 'default',
          }}
          label='Förnamn'
          name='firstName'
          nextFocusName='lastName'
          rules={{
            maxLength: { value: 50, message: 'Max 50 tecken' },
            required: { value: true, message: 'Var vänlig fyll i förnamn' },
          }}
        />

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'words',
            autoComplete: 'name-family',
            autoCorrect: false,
            keyboardType: 'default',
          }}
          label='Efternamn'
          name='lastName'
          nextFocusName='email'
          rules={{
            maxLength: { value: 50, message: 'Max 50 tecken' },
            required: { value: true, message: 'Var vänlig fyll i efternamn' },
          }}
        />

        <ControlledNationalityCodeInput
          callbackScreen='SignupBankIdScreen'
          form={form}
          name='nationalityCode'
        />

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'none',
            autoComplete: 'email',
            autoCorrect: false,
            keyboardType: 'email-address',
            onSubmitEditing: handleSubmit(onSubmit),
            returnKeyType: 'done',
          }}
          label='E-postadress'
          name='email'
          rules={{
            pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: 'Ej giltig e-postadress' },
            required: { value: true, message: 'Var vänlig fyll i e-postadress' },
          }}
        />

        <RoundedButton
          title='Skapa användare'
          onPress={handleSubmit(onSubmit)}
          style={Styles.margin16}
          isLoading={fetching}
        />

        <BottomPadding />
      </KeyboardAwareScrollView>

      <Snackbar
        onDismiss={unsetErrorMessage}
        visible={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </SafeAreaView>
  )
}

export default SignupBankIdScreen
