import React, { useMemo } from 'react'
import { Image } from 'react-native'
import unreachable from 'ts-unreachable'

import { WorkoutActivityType } from '../clients/backend.generated'

import type { KlassikerWorkoutActivityType } from '../types'
import type { ImageStyle, StyleProp } from 'react-native'

interface KlassikerActivityIconProps {
  readonly additionalStyle?: StyleProp<ImageStyle>
  readonly color: string
  readonly type: KlassikerWorkoutActivityType
}

const KlassikerActivityIcon: React.FC<KlassikerActivityIconProps> = ({
  additionalStyle, color, type,
}: KlassikerActivityIconProps) => {
  const { source, style } = useMemo(() => {
    switch (type) {
      case WorkoutActivityType.CROSS_COUNTRY_SKIING: return { source: require('../../assets/esk_icon_skiing.png'), style: { height: 28, width: 37, right: 8 } }
      case WorkoutActivityType.CYCLING: return { source: require('../../assets/esk_icon_biking.png'), style: { height: 28, width: 37, right: 10 } }
      case WorkoutActivityType.RUNNING: return { source: require('../../assets/esk_icon_running.png'), style: { height: 28, width: 24, right: 2 } }
      case WorkoutActivityType.SWIMMING: return { source: require('../../assets/esk_icon_swimming.png'), style: { height: 28, width: 28, right: 4 } }
      case WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING: return { source: require('../../assets/esk_icon_strength.png'), style: { height: 24, width: 24, top: 2 } }
      default: return unreachable(type)
    }
  }, [type])

  return (
    <Image
      accessibilityIgnoresInvertColors
      source={source}
      style={[style, { tintColor: color }, additionalStyle]}
    />
  )
}

export default KlassikerActivityIcon
