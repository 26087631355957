import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Row, Styles } from '@kingstinct/react'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Shadow } from 'react-native-shadow-2'

import { FIGMA_COLORS } from '../contexts/Theme'
import { useGetAppleHeartrate } from '../hooks/useGetAppleWorkoutCoords'
import { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import { Column } from './Primitives'
import { StaticTimer, Timer } from './Timer'

import type { WorkoutByIdQuery } from '../clients/backend.generated'

const styles = StyleSheet.create({
  divider: {
    backgroundColor: FIGMA_COLORS.ESK_GRAY_3,
    height: 1,
    marginVertical: 8,
    width: '84%',
  },
  bigDigits: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 48,
    fontVariant: ['tabular-nums'],
    fontWeight: '700',
  },
  bigUnit: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 24,
    fontWeight: '500',
  },
  smallDigits: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 24,
    fontVariant: ['tabular-nums'],
    fontWeight: '700',
  },
  smallUnit: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 16,
    fontWeight: '500',
  },
  smallLabel: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 12,
  },
})

const Divider: React.FC = () => (
  <View style={styles.divider} />
)

export const adaptiveDecimals = (value: number) => {
  if (value < 100) {
    return oneDecimal(value)
  }
  return Math.round(value).toString()
}

export const oneDecimal = (value: number) => (Math.round(value * 10) / 10).toFixed(1).replace('.', ',')

export const twoDecimals = (value: number) => (Math.round(value * 100) / 100).toFixed(2).replace('.', ',')

interface WorkoutTrackerProps {
  readonly averagePaceMinPerKm: number | null
  readonly currentPaceMinPerKm: number | null
  readonly distanceInMeters: number | null
  readonly startTimestamp: number | null | undefined
  readonly isTrackingGps: boolean
}

const WorkoutTracker: React.FC<WorkoutTrackerProps> = ({
  averagePaceMinPerKm, currentPaceMinPerKm, distanceInMeters, startTimestamp, isTrackingGps,
}) => (
  <Shadow
    distance={2}
    offset={DEFAULT_SHADOW_OFFSET}
    style={Styles.width100pct}
  >
    <Column backgroundColor={FIGMA_COLORS.ESK_VIT} centerX paddingY={16}>

      <Row paddingY={isTrackingGps ? 8 : 24}>
        <Timer
          startTimestamp={startTimestamp}
          style={styles.bigDigits}
        />
      </Row>

      { isTrackingGps ? (
        <>
          <Divider />

          <Text style={styles.bigDigits}>
            {distanceInMeters ? twoDecimals(distanceInMeters / 1000) : '0,00'}
            <Text style={styles.bigUnit}>
              {' km'}
            </Text>
          </Text>
        </>
      ) : null }

      { isTrackingGps ? (
        <>
          <Divider />

          <Row paddingY={8} spaceEvenly>
            <Column centerX fill>
              <Text style={styles.smallDigits}>
                {currentPaceMinPerKm === Infinity || !currentPaceMinPerKm ? '-' : oneDecimal(currentPaceMinPerKm)}
                <Text style={styles.smallUnit}>
                  {' min/km'}
                </Text>
              </Text>
              <Text style={styles.smallLabel}>TEMPO</Text>
            </Column>
            <Column centerX fill>
              <Text style={styles.smallDigits}>
                {averagePaceMinPerKm === Infinity || !averagePaceMinPerKm ? '-' : oneDecimal(averagePaceMinPerKm)}
                <Text style={styles.smallUnit}>
                  {' min/km'}
                </Text>
              </Text>
              <Text style={styles.smallLabel}>MEDELTEMPO</Text>
            </Column>
          </Row>
        </>
      ) : null}

    </Column>
  </Shadow>
)

export const StaticWorkoutTracker: React.FC<{ readonly workout: NonNullable<WorkoutByIdQuery['workoutById']> }> = ({ workout }) => {
  const
        {
          activeWorkoutTimeInMilliseconds, averagePaceMinPerKm, distanceInMeters, startDate, endDate, appleHealthkitUUID,
        } = workout,
        appleHeart = useGetAppleHeartrate(new Date(startDate), new Date(endDate!), !!endDate && !!appleHealthkitUUID),
        heartRateLow = workout.heartRateLow || appleHeart?.heartRateLow,
        heartRateHigh = workout.heartRateHigh || appleHeart?.heartRateHigh,
        heartRateAverage = workout.heartRateAverage || appleHeart?.heartRateAverage

  return (
    <Shadow
      distance={2}
      offset={DEFAULT_SHADOW_OFFSET}
      style={Styles.width100pct}
    >
      <Column backgroundColor={FIGMA_COLORS.ESK_VIT} centerX paddingY={16}>

        <Row paddingY={8}>
          <StaticTimer
            milliseconds={activeWorkoutTimeInMilliseconds}
            style={styles.bigDigits}
          />
        </Row>

        { distanceInMeters && distanceInMeters > 0 ? (
          <>
            <Divider />
            <Text style={styles.bigDigits}>
              {twoDecimals(distanceInMeters / 1000)}
              <Text style={styles.bigUnit}>
                {' km'}
              </Text>
            </Text>
          </>

        ) : null }

        {averagePaceMinPerKm && averagePaceMinPerKm !== Infinity ? (
          <>
            <Divider />

            <Row paddingY={8} spaceEvenly>
              <Column centerX fill>
                <Text style={styles.smallDigits}>
                  {averagePaceMinPerKm === Infinity || !averagePaceMinPerKm ? '-' : oneDecimal(averagePaceMinPerKm)}
                  <Text style={styles.smallUnit}>
                    {' '}
                    min/km
                  </Text>
                </Text>
                <Text style={styles.smallLabel}>MEDELTEMPO</Text>
              </Column>
            </Row>
          </>
        ) : null}

        { heartRateLow || heartRateHigh || heartRateAverage ? (
          <>
            <Divider />
            <Row center spaceAround>
              <Text style={styles.smallUnit}>Puls</Text>
              <MaterialCommunityIcons style={Styles.marginLeft8} name='heart' color='red' />
            </Row>
            <Row paddingY={8} spaceEvenly>
              { heartRateLow ? (
                <Column centerX fill>
                  <Text style={styles.smallDigits}>
                    {heartRateLow === Infinity || !heartRateLow ? '-' : Math.round(heartRateLow)}
                  </Text>
                  <Text style={styles.smallLabel}>MIN</Text>
                </Column>
              ) : null }
              { heartRateAverage ? (
                <Column centerX fill>
                  <Text style={styles.smallDigits}>
                    {heartRateAverage === Infinity || !heartRateAverage ? '-' : Math.round(heartRateAverage)}
                  </Text>
                  <Text style={styles.smallLabel}>MEDEL</Text>
                </Column>
              ) : null }
              { heartRateHigh ? (
                <Column centerX fill>
                  <Text style={styles.smallDigits}>
                    {heartRateHigh === Infinity || !heartRateHigh ? '-' : Math.round(heartRateHigh)}
                  </Text>
                  <Text style={styles.smallLabel}>MAX</Text>
                </Column>
              ) : null }

            </Row>
          </>
        ) : null}

      </Column>
    </Shadow>
  )
}

export default WorkoutTracker
