import * as Linking from 'expo-linking'
import { openAuthSessionAsync } from 'expo-web-browser'
import qs from 'qs'
import { useCallback } from 'react'

import {
  useGoogleAuthUrlQuery,
  useGoogleFitConnectMutation,
} from '../clients/backend.generated'

const callbackUrl = Linking.createURL('google-fit-callback')

const useOnPressConnectWithGoogle = () => {
  const [{ data }] = useGoogleAuthUrlQuery({ variables: { callbackUrl } }),
        [{ fetching: isConnecting }, connectWithGoogle] = useGoogleFitConnectMutation()

  const onPressGoogleConnect = useCallback(async () => {
    if (data) {
      const result = await openAuthSessionAsync(data.googleFitOAuthUrl.url, callbackUrl)

      if (result.type === 'success') {
        const query = result.url.split('?')[1]
        const { code } = qs.parse(query!) as { readonly code: string }

        return connectWithGoogle({ code, redirectUri: data.googleFitOAuthUrl.redirectUrl })
      }
    }
    return Promise.reject()
  }, [connectWithGoogle, data])

  return [onPressGoogleConnect, isConnecting] as const
}

export default useOnPressConnectWithGoogle
