import React from 'react'

import { WorkoutActivityType } from '../clients/backend.generated'
import KlassikerActivityIcon from './KlassikerActivityIcon'

import type { KlassikerWorkoutActivityType, NewsTabParamList, WorkoutTabParamList } from '../types'
import type { MaterialTopTabNavigationOptions } from '@react-navigation/material-top-tabs'

type RouteName = keyof NewsTabParamList | keyof WorkoutTabParamList

const MAPPINGS: Record<RouteName, KlassikerWorkoutActivityType> = {
  BikingNewsTab: WorkoutActivityType.CYCLING,
  BikingWorkoutTab: WorkoutActivityType.CYCLING,
  RunningNewsTab: WorkoutActivityType.RUNNING,
  RunningWorkoutTab: WorkoutActivityType.RUNNING,
  SkiingNewsTab: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  SkiingWorkoutTab: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  StrengthWorkoutTab: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
  SwimmingNewsTab: WorkoutActivityType.SWIMMING,
  SwimmingWorkoutTab: WorkoutActivityType.SWIMMING,
}

type TabBarIconProps = Parameters<NonNullable<MaterialTopTabNavigationOptions['tabBarIcon']>>[number]

export function createTopTabBarIcon(routeName: RouteName): (props: TabBarIconProps) => React.ReactNode {
  return ({ color, focused }) => (
    <KlassikerActivityIcon
      additionalStyle={focused ? { transform: [{ scale: 1.2 }] } : undefined}
      color={color}
      type={MAPPINGS[routeName]}
    />
  )
}
