import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Styles } from '@kingstinct/react'
import { useNavigation } from '@react-navigation/native'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { ScrollView, StyleSheet, useWindowDimensions } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Surface, Text } from 'react-native-paper'
import { match } from 'ts-pattern'

import { CmsEnum_Workoutarticle_Workouttype as WorkoutType, useGetWorkoutStatsQuery, WorkoutActivityType } from '../clients/backend.generated'
import Background from '../components/Background'
import DropDownMenu from '../components/DropDownMenu'
import Header from '../components/Header'
import KlassikerActivityIcon from '../components/KlassikerActivityIcon'
import {
  BottomSpacer,
  Column, HeaderSpacer, Overlay, Row,
} from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import RoundedButton from '../components/RoundedButton'
import { adaptiveDecimals, oneDecimal } from '../components/WorkoutTracker'
import { FIGMA_COLORS } from '../contexts/Theme'
import { KlassikerWorkoutActivityTypes, WorkoutActivityTypeMappings } from '../types'
import createThemedStylesHook, { createThemedView } from '../utils/createThemedStylesHook'

import type { GetWorkoutStatsQueryVariables } from '../clients/backend.generated'

const categories = [
  {
    category: WorkoutType.BIKING,
    klassikerType: WorkoutActivityType.CYCLING,
    label: 'Cykel',
  },
  {
    category: WorkoutType.RUNNING,
    klassikerType: WorkoutActivityType.RUNNING,
    label: 'Löpning',
  },
  {
    category: WorkoutType.SWIMMING,
    klassikerType: WorkoutActivityType.SWIMMING,
    label: 'Simning',
  },
  {
    category: WorkoutType.SKIING,
    klassikerType: WorkoutActivityType.CROSS_COUNTRY_SKIING,
    label: 'Skidor',
  },
  {
    category: WorkoutType.STRENGTH,
    klassikerType: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
    label: 'Styrka',
  },
] as const

const timePeriods = [
  {
    label: 'Senaste 7 dagarna',
    from: () => dayjs().subtract(7, 'days').startOf('day').format(),
    to: () => dayjs().endOf('day').format(),
  }, {
    label: 'Föregående 7 dagarna',
    from: () => dayjs().subtract(14, 'days').startOf('day').format(),
    to: () => dayjs().endOf('day').subtract(7, 'days').format(),
  }, {
    label: 'Senaste 30 dagarna',
    from: () => dayjs().subtract(30, 'days').startOf('day').format(),
    to: () => dayjs().endOf('day').format(),
  }, {
    label: 'Föregående 30 dagarna',
    from: () => dayjs().subtract(60, 'days').startOf('day').format(),
    to: () => dayjs().endOf('day').subtract(30, 'days').format(),
  },
] as const

const defaultTimePeriod = timePeriods[0]

interface LabelAndVariables {
  readonly label: string
  readonly variables: GetWorkoutStatsQueryVariables
}

const getLabelAndVariables = (timePeriod: typeof timePeriods[number]): LabelAndVariables => ({
  variables: {
    from: timePeriod.from(),
    to: timePeriod.to(),
    allActivityTypes: KlassikerWorkoutActivityTypes.flatMap((type) => WorkoutActivityTypeMappings[type]),
    skiingActivityTypes: WorkoutActivityTypeMappings[WorkoutActivityType.CROSS_COUNTRY_SKIING],
    bikingActivityTypes: WorkoutActivityTypeMappings[WorkoutActivityType.CYCLING],
    runningActivityTypes: WorkoutActivityTypeMappings[WorkoutActivityType.RUNNING],
    swimmingActivityTypes: WorkoutActivityTypeMappings[WorkoutActivityType.SWIMMING],
    strengthActivityTypes: WorkoutActivityTypeMappings[WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING],
  },
  label: timePeriod.label,
})

const useStyles = createThemedStylesHook(({ theme }) => ({
  emptyRow: { justifyContent: 'center', padding: 10, paddingVertical: 30 },
  headerBox: { width: 100, height: 50, justifyContent: 'center' },
  headerTitle: { textAlign: 'center', fontSize: 14, fontWeight: '400' },
  highlightedBox: {
    backgroundColor: `${theme.colors.primary}20`, width: 100, height: 50, justifyContent: 'center', borderLeftColor: 'white', borderLeftWidth: 1,
  },
  leftSubtitle: { fontSize: 10, fontWeight: '400' },
  leftTitle: { fontSize: 18, fontWeight: '700' },
  row: { borderBottomColor: FIGMA_COLORS.ESK_GRAY_2, borderBottomWidth: StyleSheet.hairlineWidth },
  statNumber: { textAlign: 'center', fontSize: 18, fontWeight: '400' },
  surface: { backgroundColor: 'white', elevation: 5 },
  totalStat: { fontSize: 36, fontWeight: '700' },
  totalStatLabel: { fontSize: 10, fontWeight: '400' },
  underline: { height: 3, width: '100%', backgroundColor: FIGMA_COLORS.ESK_GREEN_2 },
  workoutLabel: {
    flex: 1, fontSize: 14, fontWeight: '400', paddingVertical: 4,
  },
}))

const Separator = createThemedView(({ theme }) => ({
  backgroundColor: theme.colors.primaryOpacity,
  width: 1,
}))

const SeparatorHorizontal = createThemedView(({ theme }) => ({
  backgroundColor: theme.colors.disabled,
  height: StyleSheet.hairlineWidth,
}))

const ArrowLink: React.FC<{ readonly title: string, readonly onPress: () => void }> = ({ title, onPress }) => (
  <TouchableOpacity accessibilityRole='button' onPress={onPress}>
    <Row padding={16} spaceBetween paddingY={24} centerY>
      <Text style={{ color: FIGMA_COLORS.ESK_GRAY_2, fontWeight: '700', fontSize: 14 }}>{title}</Text>
      <MaterialCommunityIcons name='chevron-right' color={FIGMA_COLORS.ESK_GRAY_2} size={20} />
    </Row>
  </TouchableOpacity>
)

const HomeScreen: React.FC = () => {
  const navigation = useNavigation()
  const styles = useStyles()
  const [category, setCategory] = useState(WorkoutType.BIKING)
  const [{ variables, label }, setLabelAndVariables] = useState(() => getLabelAndVariables(defaultTimePeriod))

  const [{ data, fetching }, fetch] = useGetWorkoutStatsQuery({ variables })

  const stats = match(category)
    .with(WorkoutType.BIKING, () => data?.bikingStats)
    .with(WorkoutType.RUNNING, () => data?.runningStats)
    .with(WorkoutType.SWIMMING, () => data?.swimmingStats)
    .with(WorkoutType.SKIING, () => data?.skiingStats)
    .with(WorkoutType.STRENGTH, () => data?.strengthStats)
    .exhaustive()

  const noData = !stats?.activeWorkoutTimeInMilliseconds && !stats?.activeWorkoutTimeInMilliseconds && !stats?.numberOfWorkouts && !stats?.distanceInMeters
  const { width } = useWindowDimensions()

  return (
    <Column fill width='100%' height='100%'>
      <Background />

      <ScrollView refreshControl={(
        <RefetchControl
          onRefetch={fetch}
          isFetching={fetching}
        />
      )}
      >
        <HeaderSpacer displaysActivityIcons />
        <Row padding={16} paddingBottom={8}>
          <Text>Översikt - Min Klassiker</Text>
        </Row>
        { data?.allStats ? (
          <Surface style={styles.surface}>
            <Row padding={8} spaceAround>

              <Column center>
                <Text style={styles.totalStat}>{ adaptiveDecimals(data.allStats.distanceInMeters / 1000) }</Text>
                <Text style={styles.totalStatLabel}>Total km</Text>
              </Column>

              <Separator />

              <Column center>
                <Text style={styles.totalStat}>{ data.allStats.numberOfWorkouts }</Text>
                <Text style={styles.totalStatLabel}>Aktivititer</Text>
              </Column>

              <Separator />

              <Column center>
                <Text style={styles.totalStat}>{ adaptiveDecimals((data.allStats.activeWorkoutTimeInMilliseconds / (60 * 1000 * 60))) }</Text>
                <Text style={styles.totalStatLabel}>Timmar</Text>
              </Column>

            </Row>
          </Surface>
        ) : null }
        <Row padding={16} paddingTop={32} paddingBottom={8}>
          <Text>Mina prestationer</Text>
        </Row>
        <Surface style={[styles.surface, Styles.flexOne]}>
          <ArrowLink title='UTMANINGAR' onPress={() => navigation.navigate('ChallengeListingScreen')} />

          <SeparatorHorizontal />

          <ArrowLink title='TRÄNINGSPASS' onPress={() => navigation.navigate('WorkoutHistory')} />
        </Surface>

        <Row padding={16} paddingTop={32} paddingBottom={8}>
          <Text>All min träning</Text>
        </Row>
        <Surface style={styles.surface}>
          <Row spaceAround style={styles.row}>
            { categories.map((c) => {
              const selected = category === c.category
              return (
                <TouchableOpacity key={c.label} accessibilityRole='button' onPress={() => setCategory(c.category)}>
                  <Column centerX width={width / 5}>
                    <Row height={28} marginTop={5}>
                      <KlassikerActivityIcon color={FIGMA_COLORS.ESK_GRAY_2} type={c.klassikerType} />
                    </Row>
                    <Text style={styles.workoutLabel}>{ c.label }</Text>
                    { selected ? <Row style={styles.underline} /> : null}
                  </Column>
                </TouchableOpacity>
              )
            }) }
          </Row>
          <Row spaceBetween style={styles.row}>
            <DropDownMenu menuItems={timePeriods.map((tp) => ({
              label: tp.label,
              type: 'item',
              onPress: () => {
                setLabelAndVariables(getLabelAndVariables(tp))
              },
            }))}
            >
              <Row centerY paddingLeft={10} height={50}>
                <Text style={Styles.marginRight8}>{label}</Text>
                <MaterialCommunityIcons size={20} name='chevron-down' />
              </Row>
            </DropDownMenu>
            <Column style={styles.headerBox}>
              <Text style={styles.headerTitle}>Slutfört</Text>
            </Column>
          </Row>
          { stats?.distanceInMeters ? (
            <Row spaceBetween style={styles.row}>
              <Column centerY paddingLeft={10}>
                <Text style={styles.leftTitle}>Distans</Text>
                <Text style={styles.leftSubtitle}>{ stats.distanceInMeters > 1000 ? '(km)' : '(m)' }</Text>
              </Column>

              <Column style={styles.highlightedBox}>
                <Text style={styles.statNumber}>
                  { stats.distanceInMeters > 1000 ? adaptiveDecimals(stats.distanceInMeters / 1000) : Math.floor(stats.distanceInMeters) }
                </Text>
              </Column>
            </Row>
          ) : null }
          { stats?.averagePaceMinPerKm ? (
            <Row spaceBetween style={styles.row}>
              <Column centerY paddingLeft={10}>
                <Text style={styles.leftTitle}>Medelhastighet</Text>
                <Text style={styles.leftSubtitle}>(min/km)</Text>
              </Column>
              <Column style={styles.highlightedBox}>
                <Text style={styles.statNumber}>
                  { oneDecimal(stats.averagePaceMinPerKm) }
                </Text>
              </Column>
            </Row>
          ) : null }
          { stats?.activeWorkoutTimeInMilliseconds ? (
            <Row spaceBetween style={styles.row}>
              <Column centerY paddingLeft={10}>
                <Text style={styles.leftTitle}>Total tid</Text>
                <Text style={styles.leftSubtitle}>(min)</Text>
              </Column>
              <Column style={styles.highlightedBox}>
                <Text style={styles.statNumber}>
                  { Math.floor(stats.activeWorkoutTimeInMilliseconds / (60 * 1000)) }
                </Text>
              </Column>
            </Row>
          ) : null }
          { stats?.numberOfWorkouts ? (
            <Row spaceBetween style={styles.row}>
              <Column centerY paddingLeft={10}>
                <Text style={styles.leftTitle}>Träningspass</Text>
              </Column>
              <Column style={styles.highlightedBox}>
                <Text style={styles.statNumber}>
                  { stats.numberOfWorkouts }
                </Text>
              </Column>
            </Row>
          ) : null }
          {noData ? (
            <Row style={styles.emptyRow}>
              <Text>Inga träningspass finns registrerade för denna period</Text>
            </Row>
          ) : null }
        </Surface>
        <BottomSpacer />
      </ScrollView>
      <Overlay />
      <Header right='settings' />
    </Column>
  )
}

export default HomeScreen
