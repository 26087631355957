import { MaterialCommunityIcons } from '@expo/vector-icons'
import {
  Image, StyleSheet, useColorScheme, View,
} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Text } from 'react-native-paper'

import { Row } from './Primitives'

type ConnectBrandedProps = {
  readonly onPress?: () => void,
  readonly text: string,
  readonly isEnabled: boolean,
}

const styles = StyleSheet.create({
  brandedIconWrapper: { borderRadius: 2, padding: 5 },
  brandedImage: { height: 20, width: 20 },
  brandedRow: {
    alignItems: 'flex-start',
    borderRadius: 3,
    justifyContent: 'flex-start',
    maxWidth: '100%',
    margin: 20,
    padding: 20,
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 1,
  },
  brandedText: {
    alignSelf: 'center', fontSize: 14, marginLeft: 28, flex: 1,
  },
  checkedIcon: { alignSelf: 'center' },
})

const GoogleConnectButton: React.FC<ConnectBrandedProps> = ({
  onPress, text, isEnabled,
}) => {
  const colorScheme = useColorScheme(),
        backgroundBranded = colorScheme === 'light' ? 'white' : '#4285F4',
        iconBackgroundBranded = 'white',
        fontColor = colorScheme === 'light' ? '#5e5e5e' : 'white'

  return (
    <TouchableOpacity
      accessibilityRole='button'
      onPress={onPress}
      accessibilityHint={text}
    >
      <Row
        style={[
          {
            backgroundColor: backgroundBranded,
          }, styles.brandedRow,
        ]}
      >

        <View style={[{ backgroundColor: iconBackgroundBranded }, styles.brandedIconWrapper]}>
          <Image
            style={styles.brandedImage}
            accessibilityIgnoresInvertColors
            source={require('../../assets/google_sign_in_with_128.png')}
          />
        </View>

        <Text style={[styles.brandedText, { color: fontColor }]}>{ text }</Text>

        { isEnabled ? (
          <MaterialCommunityIcons
            style={styles.checkedIcon}
            name='check'
            color='green'
            size={20}
          />
        ) : null }
      </Row>
    </TouchableOpacity>

  )
}

export default GoogleConnectButton
