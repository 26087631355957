import React, { useCallback } from 'react'
import {
  Controller,
} from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { TextInput } from 'react-native-paper'

import { FIGMA_COLORS } from '../contexts/Theme'
import ErrorText from './ErrorText'
import Label from './Label'

import type {
  FieldPath, FieldValues, UseControllerProps, UseFormReturn,
} from 'react-hook-form'
import type { TextInputProps } from 'react-native-paper/lib/typescript/components/TextInput/TextInput'

const styles = StyleSheet.create({
  textInput: { backgroundColor: 'white' },
})

interface Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  readonly form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState' | 'setFocus'>
  readonly inputProps: Partial<TextInputProps>
  readonly label: string
  readonly name: TName
  readonly nextFocusName?: FieldPath<TFieldValues>
  readonly rules: UseControllerProps<TFieldValues>['rules']
}

function ControlledTextInput<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  form: { control, formState: { errors }, setFocus }, inputProps: { style, ...inputProps }, label, name, nextFocusName, rules,
}: Props<TFieldValues, TName>) {
  const onSubmitEditing = useCallback((field: FieldPath<TFieldValues>) => () => setFocus(field), [setFocus])

  return (
    <Label title={label}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, ...rest } }) => (
          <TextInput
            accessibilityHint={label}
            accessibilityLabel={label}
            mode='outlined'
            onChangeText={onChange}
            onSubmitEditing={nextFocusName == null ? undefined : onSubmitEditing(nextFocusName)}
            placeholder={label}
            placeholderTextColor={FIGMA_COLORS.ESK_GRAY_2}
            returnKeyType={nextFocusName == null ? undefined : 'next'}
            style={[styles.textInput, style]}
            {...inputProps}
            {...rest}
          />
        )}
        rules={rules}
      />
      <ErrorText>{errors[name]?.message}</ErrorText>
    </Label>
  )
}

export default ControlledTextInput
