import { useAppState } from '@kingstinct/react'
import * as Location from 'expo-location'
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'

import type { LocationPermissionResponse } from 'expo-location'
import type { PropsWithChildren } from 'react'

const DEFAULT_VALUE = {
  backgroundPermission: null as LocationPermissionResponse | null,
  foregroundPermission: null as LocationPermissionResponse | null,
  requestForegroundPermission: async () => {
    console.warn('[@kingstinct/react] Using LocationPermissionsContext without provider')
    return Location.requestForegroundPermissionsAsync()
  },
  requestBackgroundPermission: async () => {
    console.warn('[@kingstinct/react] Using LocationPermissionsContext without provider')
    return Location.requestBackgroundPermissionsAsync()
  },
}

const LocationPermissionsContext = React.createContext(DEFAULT_VALUE)

const LocationPermissionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const appState = useAppState()
  const [backgroundPermission, setBackgroundPermission] = useState(DEFAULT_VALUE.backgroundPermission)
  const [foregroundPermission, setForegroundPermission] = useState(DEFAULT_VALUE.foregroundPermission)

  const requestForegroundPermission = useCallback(async () => {
    const res = await Location.requestForegroundPermissionsAsync()
    setForegroundPermission(res)
    return res
  }, [])

  const requestBackgroundPermission = useCallback(async () => {
    const res = await Location.requestBackgroundPermissionsAsync()
    setBackgroundPermission(res)
    return res
  }, [])

  useEffect(() => {
    if (appState === 'active') {
      void Location.getBackgroundPermissionsAsync().then(setBackgroundPermission)
      void Location.getForegroundPermissionsAsync().then(setForegroundPermission)
    }
  }, [appState])

  const value = useMemo(() => ({
    backgroundPermission,
    foregroundPermission,
    requestForegroundPermission,
    requestBackgroundPermission,
  }), [
    backgroundPermission, foregroundPermission, requestForegroundPermission, requestBackgroundPermission,
  ])

  return (
    <LocationPermissionsContext.Provider value={value}>
      {children}
    </LocationPermissionsContext.Provider>
  )
}

export const useStableBackgroundPermissions = () => {
  const { backgroundPermission, requestBackgroundPermission } = React.useContext(LocationPermissionsContext)
  return [backgroundPermission, requestBackgroundPermission] as const
}

export const useStableForegroundPermissions = () => {
  const { foregroundPermission, requestForegroundPermission } = React.useContext(LocationPermissionsContext)
  return [foregroundPermission, requestForegroundPermission] as const
}

export default LocationPermissionProvider
