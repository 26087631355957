import useEvent from '@kingstinct/react/hooks/useEvent'
import {
  useEffect, useMemo, useState,
} from 'react'

import { EMPTY } from '../utils/empty'

type PaginatedData<T extends { readonly id?: string | null }> = {
  readonly data: readonly T[],
  readonly meta: {
    readonly pagination: {
      readonly page: number
      readonly pageCount: number
    }
  }
}

type MergeOpts = {
  readonly setPage: React.Dispatch<React.SetStateAction<number>>
  readonly fetching: boolean
  readonly fetch: () => void
}

export function useMergePagedResults<T extends { readonly id?: string | null }>(data: PaginatedData<T> | null | undefined, { setPage, fetching, fetch }: MergeOpts) {
  const [pages, setPages] = useState<ReadonlyArray<ReadonlyArray<T>>>(EMPTY.ARRAY)

  useEffect(() => {
    setPages((prevPages) => {
      if (!data) {
        return EMPTY.ARRAY
      }
      const beforePages = prevPages.slice(0, data.meta.pagination.page - 1)
      return [...beforePages, data.data]
    })
  }, [data])

  const fetchMore = useEvent(() => {
    if (!fetching && data) {
      setPage((page) => ((data?.meta.pagination.pageCount || 0) > page ? page + 1 : page))
    }
  })

  const refetch = useEvent(() => setPage((page) => {
    if (page === 1) {
      fetch()
    }
    return 1
  }))

  const mergedData = useMemo(() => pages.flat(), [pages])

  return [
    mergedData,
    {
      fetchMore,
      refetch,
    },
  ] as const
}
