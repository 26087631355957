import { useEvent } from '@kingstinct/react'
import { HKAuthorizationRequestStatus } from '@kingstinct/react-native-healthkit'
import React from 'react'
import {
  Platform, ScrollView, Alert, Switch,
} from 'react-native'
import { ActivityIndicator, Text } from 'react-native-paper'

import {
  useGoogleFitDisconnectMutation, useSyncInfoQuery, useWipeWorkoutDataMutation, useWorkoutCountsQuery, WorkoutSource,
} from '../clients/backend.generated'
import Background from '../components/Background'
import GoogleConnectButton from '../components/GoogleConnectButton'
import Header from '../components/Header'
import MenuItem from '../components/MenuItem'
import { Column, HeaderSpacer, Row } from '../components/Primitives'
import RoundedButton from '../components/RoundedButton'
import SnackbarPresentationView from '../components/SnackbarPresentationView'
import { ThemeProvider } from '../contexts/Theme'
import useOnConnectWithAppleHealth, { useIsHealthkitAvailable, useSyncAppleHealthData } from '../hooks/appleHealth'
import useOnPressConnectWithGoogle from '../hooks/useOnPressConnectWithGoogle'
import { useSyncGoogleFit } from '../hooks/useSyncHealth'

import type { ESKScreen } from '../types'

const HealthConnectionScreen: ESKScreen<'Settings'> = ({ route }) => {
  const [{ data, fetching }] = useSyncInfoQuery(),
        [, googleDisconnect] = useGoogleFitDisconnectMutation(),
        syncApple = useSyncAppleHealthData(),
        syncGoogle = useSyncGoogleFit(),
        [{ data: workoutCountData }] = useWorkoutCountsQuery(),
        [, wipeData] = useWipeWorkoutDataMutation(),
        [onPressGoogleConnect] = useOnPressConnectWithGoogle(),
        [isHealthEnabled, setEnabled, status] = useOnConnectWithAppleHealth(),
        isHealthkitAvailable = useIsHealthkitAvailable(),
        isAppleHealthEnabled = status !== HKAuthorizationRequestStatus.shouldRequest && isHealthEnabled,
        toggleHealth = useEvent(async () => {
          if (!isAppleHealthEnabled) {
            await setEnabled(true)
            await syncApple()
          } else {
            await setEnabled(false)
          }
        }),
        isConnectedWithGoogleFit = data?.me.isConnectedWithGoogleFit,
        toggleGoogle = useEvent(async () => {
          if (isConnectedWithGoogleFit) {
            Alert.alert('Koppla från Google Fit', 'Vill du även radera all träningsdata vi tidigare har hämtat från Google Fit?', [
              {
                onPress: () => { void googleDisconnect({ deleteAllData: true }) },
                style: 'destructive',
                text: 'Ja, ta bort allt',
              },
              {
                onPress: () => { void googleDisconnect({ deleteAllData: false }) },
                text: 'Nej, ta bara bort kopplingen',
              },
              {
                text: 'Avbryt',
              },
            ], {
              cancelable: true,
            })
          } else {
            await onPressGoogleConnect()
            await syncGoogle()
          }
        })

  return (
    <ThemeProvider color={route?.params?.pageColor || 'green'}>
      <Column fill>
        <Background activityIcons='none' />
        <ScrollView>
          <HeaderSpacer />
          <Text
            style={{ paddingHorizontal: 20, paddingBottom: 20 }}
          >
            { `Om du använder en smartklocka eller en annan app för att logga din träning kan du koppla ihop dessa med En Svensk Klassiker genom ${Platform.OS === 'android'
              ? 'Google Fit'
              : 'Apple Hälsa eller Google Fit'}.` }
          </Text>
          { fetching ? (
            <Row padding={50} center>
              <ActivityIndicator />
            </Row>
          ) : (
            <>

              { isHealthkitAvailable
                ? (
                  <MenuItem title='Apple Hälsa' onPress={toggleHealth}>
                    <Switch
                      value={isAppleHealthEnabled}
                      onChange={toggleHealth}
                    />
                  </MenuItem>
                ) : null }

              <GoogleConnectButton
                onPress={toggleGoogle}
                text={isConnectedWithGoogleFit ? 'Koppla från Google Fit' : 'Anslut Google Fit'}
                isEnabled={!!isConnectedWithGoogleFit}
              />

            </>
          )}
          <Text style={{ padding: 20 }}>Varje gång du öppnar appen hämtas dina senaste träningspass</Text>
          {workoutCountData?.appleWorkoutCount && __DEV__ ? <RoundedButton onPress={() => { void wipeData({ source: WorkoutSource.APPLE_HEALTHKIT }) }} title={`Ta bort ${workoutCountData.appleWorkoutCount} träningspass (Apple Hälsa)`} /> : null }
          {workoutCountData?.googleFitWorkoutCount && __DEV__ ? <RoundedButton onPress={() => { void wipeData({ source: WorkoutSource.GOOGLE_FIT }) }} title={`Ta bort ${workoutCountData.googleFitWorkoutCount} träningspass (Google Fit)`} /> : null }
          {workoutCountData?.internalWorkoutCount && __DEV__ ? <RoundedButton onPress={() => { void wipeData({ source: WorkoutSource.INTERNAL }) }} title={`Ta bort ${workoutCountData.internalWorkoutCount} träningspass`} /> : null }
        </ScrollView>
        <Header
          title='Hantera träningskopplingar'
          right='close'
          backIcon='window-close'
          insetTop={Platform.OS === 'ios' ? 30 : undefined}
        />
      </Column>
      <SnackbarPresentationView />
    </ThemeProvider>

  )
}

export default HealthConnectionScreen
