import React, { useMemo } from 'react'
import { ScrollView } from 'react-native-gesture-handler'

import { useNewsDetailsQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import CmsArticleDetailsComponent from '../components/CmsArticleDetailsComponent'
import FetchingComponent from '../components/FetchingComponent'
import Header from '../components/Header'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'

import type { ESKScreen } from '../types'

const NewsDetailsScreen: ESKScreen<'NewsArticle'> = ({ route: { params: { id } } }) => {
  const [{ data, error, fetching }, refetch] = useNewsDetailsQuery({ variables: { id } })

  const newsArticle = useMemo(() => data?.newsArticle?.data?.attributes, [data?.newsArticle?.data?.attributes])

  return (
    <Column fill>
      <Background activityIcons='none' />

      <ScrollView refreshControl={<RefetchControl onRefetch={refetch} />}>
        <HeaderSpacer />

        { fetching && !newsArticle ? <FetchingComponent /> : (newsArticle ? (
          <CmsArticleDetailsComponent
            {...newsArticle}
            uri={newsArticle.image?.data?.attributes?.url ?? newsArticle.externalImageUrl}
          />
        // eslint-disable-next-line unicorn/no-nested-ternary
        ) : error ? (
          <ListEmptyComponent
            isError
            refetch={refetch}
          />
        ) : null) }

        <BottomSpacer />
      </ScrollView>
      <Header
        backIcon='keyboard-backspace'
        right='settings'
      />
    </Column>
  )
}

export default NewsDetailsScreen
