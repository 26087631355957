import { useNavigation, useRoute } from '@react-navigation/native'
import { useEffect, useRef } from 'react'
import { Image, useWindowDimensions } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { Text } from 'react-native-paper'
import Animated from 'react-native-reanimated'
import Carousel from 'react-native-reanimated-carousel'
import { Shadow } from 'react-native-shadow-2'

import { useRacesPageQuery } from '../../clients/backend.generated'
import Background from '../../components/Background'
import Banners from '../../components/Banners'
import CmsContent from '../../components/CmsContent'
import FetchingComponent from '../../components/FetchingComponent'
import Header from '../../components/Header'
import ListEmptyComponent from '../../components/ListEmptyComponent'
import {
  BottomSpacer, Column, HeaderSpacer, Overlay, Row,
} from '../../components/Primitives'
import RefetchControl from '../../components/RefetchControl'
import { FIGMA_COLORS } from '../../contexts/Theme'
import useSanitizeCmsData from '../../hooks/useSanitizeCmsData'
import createThemedStylesHook from '../../utils/createThemedStylesHook'

import type { RaceStackParamList } from '../../types'
import type { NavigationProp, RouteProp } from '@react-navigation/native'
import type { ICarouselInstance } from 'react-native-reanimated-carousel'
import type { ShadowProps } from 'react-native-shadow-2'

const useStyles = createThemedStylesHook(({ size: { width } }) => ({
  allRacesText: {
    marginTop: 70, marginLeft: 25, fontSize: 14, fontWeight: '700', marginBottom: 10,
  },
  raceContainer: {
    justifyContent: 'center', width, borderRadius: 20, flex: 1,
  },
  raceHeaderImage: {
    width: width - 40, height: 150, borderRadius: 20,
  },
  headerContainer: { marginHorizontal: 20 },
  headerTitleContainer: { backgroundColor: FIGMA_COLORS.ESK_GREEN_2, borderRadius: 20 },
  headerTitle: { textTransform: 'uppercase', fontSize: 14, fontWeight: '700' },
}))

const SHADOW_OFFSET: ShadowProps['offset'] = [0, 1]

const RaceScreen: React.FC = () => {
  const [{ data, fetching, error }, refetch] = useRacesPageQuery({ requestPolicy: 'cache-and-network' })
  const { width, height } = useWindowDimensions()

  const races = useSanitizeCmsData(data?.races?.data)

  const styles = useStyles()

  const carouselRef = useRef<(ICarouselInstance)>(null)

  const route = useRoute<RouteProp<RaceStackParamList, 'RaceScreen'>>()
  const navigateToRaceId = route?.params?.raceId
  const navigation = useNavigation<NavigationProp<RaceStackParamList, 'RaceScreen'>>()

  useEffect(() => {
    if (navigateToRaceId && races) {
      const index = races.findIndex((r) => r.id === navigateToRaceId)
      carouselRef.current?.scrollTo({
        index,
        animated: true,
        onFinished: () => navigation.setParams({ raceId: undefined }),
      })
    }
  }, [races, navigateToRaceId, navigation])

  return (
    <Column fill>
      <Background activityIcons='none' />

      { fetching && !data ? <FetchingComponent /> : (data ? (
        <Row>
          <Carousel
            ref={carouselRef}
            width={width}
            height={height}
            mode='parallax'
            data={races}
            modeConfig={{
              parallaxAdjacentItemScale: 0.95,
              parallaxScrollingScale: 1,
              parallaxScrollingOffset: 35,
            }}
            renderItem={({ item }) => (
              <Column
                style={styles.raceContainer}
              >
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  refreshControl={(
                    <RefetchControl
                      onRefetch={refetch}
                    />
                  )}
                >
                  <HeaderSpacer />
                  <Animated.View animatedProps={{ }}>
                    <Row style={styles.headerContainer}>
                      <Shadow
                        distance={2}
                        offset={SHADOW_OFFSET}
                      >
                        <Image
                          accessibilityIgnoresInvertColors
                          source={{ uri: item.attributes?.headerImage.data?.attributes?.url }}
                          style={styles.raceHeaderImage}
                        />
                      </Shadow>
                      <Overlay center>
                        <Row padding={10} style={styles.headerTitleContainer}>
                          <Text style={styles.headerTitle}>{ item?.attributes?.title }</Text>
                        </Row>
                      </Overlay>
                    </Row>
                  </Animated.View>

                  <Column padding={30}>
                    <Banners raceId={item.id} />

                    <CmsContent animate data={item.attributes.content} keyPrefix={item.id} />

                  </Column>
                  <BottomSpacer />
                </ScrollView>
              </Column>
            )}
          />
        </Row>
      ) : (
        <ListEmptyComponent
          isError={!!error}
          refetch={refetch}
        />
      )) }
      <Header
        right='settings'
        title='Lopp'
      />
    </Column>
  )
}

export default RaceScreen
