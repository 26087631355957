import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React from 'react'

import { createTopTabBarIcon } from '../components/TopTabBarIcon'
import { createTopTabHeader } from '../components/TopTabHeader'
import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import WorkoutListingScreen from './WorkoutArticleListingScreen'

import type { WorkoutTabParamList } from '../types'

const TopTab = createMaterialTopTabNavigator<WorkoutTabParamList>()

const WorkoutArticleListingTabs: React.FC = () => {
  const theme = useTheme()

  return (
    <TopTab.Navigator
      sceneContainerStyle={{
        backgroundColor: theme.colors.background,
      }}
      screenOptions={({ route }) => ({
        tabBarIcon: createTopTabBarIcon(route.name),
        tabBarLabelStyle: {
          fontSize: 12,
          textTransform: 'none',
        },
        tabBarActiveTintColor: FIGMA_COLORS.ESK_GRAY_2,
        // tabBarInactiveTintColor: FIGMA_COLORS.ESK_GRAY_2,
        tabBarIndicatorStyle: {
          height: 3,
          backgroundColor: theme.colors.accent,
        },
        tabBarStyle: {
          backgroundColor: 'transparent',
        },
      })}
      tabBar={createTopTabHeader('Träningstips')}
    >
      <TopTab.Screen
        component={WorkoutListingScreen}
        name='SkiingWorkoutTab'
        options={{ title: 'Skidor' }}
      />
      <TopTab.Screen
        component={WorkoutListingScreen}
        name='BikingWorkoutTab'
        options={{ title: 'Cykling' }}
      />
      <TopTab.Screen
        component={WorkoutListingScreen}
        name='SwimmingWorkoutTab'
        options={{ title: 'Simning' }}
      />
      <TopTab.Screen
        component={WorkoutListingScreen}
        name='RunningWorkoutTab'
        options={{ title: 'Löpning' }}
      />
      <TopTab.Screen
        component={WorkoutListingScreen}
        name='StrengthWorkoutTab'
        options={{ title: 'Styrka' }}
      />
    </TopTab.Navigator>
  )
}

export default WorkoutArticleListingTabs
