import { getPathLength } from 'geolib'

export type Coord = {
  readonly latitude: number
  readonly longitude: number
  readonly timestamp: number
  readonly altitude?: number | null
  readonly altitudeAccuracy?: number | null
  readonly accuracy?: number | null
}

function reduceCoordinatesToMeters(coordinates: readonly Coord[]): number {
  // eslint-disable-next-line functional/prefer-readonly-type, functional/prefer-tacit
  return getPathLength(coordinates as Coord[])
}

export default reduceCoordinatesToMeters
