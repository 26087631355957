import { FlashList } from '@shopify/flash-list'
import { useCallback, useState } from 'react'
import Animated, { FadeInUp } from 'react-native-reanimated'

import { useNutritionListingQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import Box from '../components/Box'
import FetchingComponent from '../components/FetchingComponent'
import Header from '../components/Header'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { usePublicationState } from '../contexts/EditorMode'
import { useMergePagedResults } from '../hooks/useMergePagedResults'
import useSanitizeCmsData from '../hooks/useSanitizeCmsData'
import createThemedStylesHook from '../utils/createThemedStylesHook'

import type { ESKScreen } from '../types'

const useStyles = createThemedStylesHook(({ size }) => ({
  item: {
    alignSelf: 'center',
    marginVertical: 8,
    width: size.width - 32,
  },
}))

const NutritionListingScreen: ESKScreen<'NutritionListing'> = ({ navigation }) => {
  const publicationState = usePublicationState(),
        [page, setPage] = useState(1),
        [{ data: queryData, error, fetching }, fetch] = useNutritionListingQuery({ variables: { page, publicationState } }),
        [pagedResults, { refetch, fetchMore }] = useMergePagedResults(queryData?.nutritionArticles, { setPage, fetching, fetch }),
        data = useSanitizeCmsData(pagedResults),
        styles = useStyles()

  const onPress = useCallback((item: typeof data[number]) => () => {
    navigation.navigate('NutritionArticle', { id: item.id })
  }, [navigation])

  return (
    <Column fill>
      <Background activityIcons='none' />

      <HeaderSpacer />

      { fetching && !data ? <FetchingComponent />
        : (
          <FlashList
            data={data}
            refreshControl={<RefetchControl onRefetch={refetch} />}
            keyExtractor={(item) => item.id}
            onEndReached={fetchMore}
            estimatedItemSize={98}
            renderItem={({ index, item }) => (
              <Animated.View
                entering={FadeInUp.delay(index * 256)}
                style={styles.item}
              >
                <Box
                  chevron
                  title={item.attributes.title}
                  subtitle={item.attributes.subtitle}
                  uri={item.attributes.headerImage?.data?.attributes?.url}
                  onPress={onPress(item)}
                />
              </Animated.View>
            )}
            ListEmptyComponent={<ListEmptyComponent isError={!!error} refetch={refetch} />}
            ListHeaderComponent={<Column height={16} />}
            ListFooterComponent={BottomSpacer}
          />
        ) }
      <Header right='settings' />
    </Column>
  )
}

export default NutritionListingScreen
