import DefaultSnackbarComponent from '@kingstinct/react/components/SnackbarComponent'
import Upstream from '@kingstinct/react/components/SnackbarPresentationView'
import React from 'react'
import { StyleSheet } from 'react-native'

import { FIGMA_COLORS } from '../contexts/Theme'

import type { SnackbarComponentProps } from '@kingstinct/react/components/SnackbarComponent'
import type { StyleProp, ViewStyle } from 'react-native'

const styles = StyleSheet.create({
  snackbarPresentationView: { paddingBottom: 30 },
})

const CustomSnackbarComponent: React.FC<SnackbarComponentProps> = (props) => (
  <DefaultSnackbarComponent {...props} buttonColor={FIGMA_COLORS.ESK_GREEN_2} />
)

interface SnackbarPresentationViewProps {
  readonly isVisibleToUser?: boolean
  readonly style?: StyleProp<ViewStyle>
}

/** Customized with bottom padding of 30 and green button color */
const SnackbarPresentationView: React.FC<SnackbarPresentationViewProps> = ({ isVisibleToUser, style }) => (
  <Upstream
    Component={CustomSnackbarComponent}
    isVisibleToUser={isVisibleToUser}
    style={[styles.snackbarPresentationView, style]}
  />
)

export default SnackbarPresentationView
