import EntypoIcons from '@expo/vector-icons/Entypo'
import { Styles, useBoolean } from '@kingstinct/react'
import React, { useCallback, useMemo, useState } from 'react'
import {
  Image, Pressable, StyleSheet, Text, View,
} from 'react-native'
import { Shadow } from 'react-native-shadow-2'

import { FIGMA_COLORS } from '../contexts/Theme'
import { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import { BoxIcon } from './BoxIcon'
import { Column, Row } from './Primitives'

import type { BoxIconProps } from './BoxIcon'
import type { LayoutChangeEvent } from 'react-native'

const styles = StyleSheet.create({
  icon: {
    alignSelf: 'flex-end',
  },
  image: {
    alignSelf: 'center',
    width: 137,
  },
  subtitle: {
    flexShrink: 1,
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 14,
    fontWeight: '400',
  },
  row: {
    borderRadius: 8,
    backgroundColor: FIGMA_COLORS.ESK_VIT,
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
  },
  title: {
    color: FIGMA_COLORS.ESK_GRAY_2,
    flexShrink: 1,
    fontSize: 14,
    fontWeight: '700',
  },
})

export interface BoxProps {
  readonly title: string
  readonly subtitle?: string | null
  readonly uri?: string | null
  readonly chevron?: boolean
  readonly icon?: BoxIconProps['name']
  readonly iconColor?: string
  readonly onPress: () => void
}

const Box: React.FC<BoxProps> = ({
  title, subtitle, uri, chevron, icon, onPress, iconColor,
}) => {
  const [height, setHeight] = useState<number>(),
        minHeight = useMemo(() => (uri ? 80 : 64), [uri]),
        [hasImageError, setImageError] = useBoolean()

  const onLayout = useCallback((event: LayoutChangeEvent) => setHeight(event.nativeEvent.layout.height), [])

  return (
    <Pressable
      accessibilityRole='button'
      onPress={onPress}
    >
      <Shadow distance={2} offset={DEFAULT_SHADOW_OFFSET} style={Styles.borderRadius8}>
        <View
          onLayout={onLayout}
          style={[styles.row, { minHeight }]}
        >

          { // eslint-disable-next-line no-nested-ternary
            (!uri || hasImageError) ? null : (height ? (
              <Image
                accessibilityIgnoresInvertColors
                onError={setImageError}
                resizeMode='cover'
                source={{ uri }}
                style={[styles.image, { height }]}
              />
            ) : <View style={styles.image} />)
          }
          <Column fill margin={8}>
            <Row marginBottom={4} spaceBetween>
              <Text numberOfLines={2} style={styles.title}>
                {title}
              </Text>
              {chevron ? <EntypoIcons color={FIGMA_COLORS.ESK_GRAY_2} name='chevron-thin-right' size={24} /> : null}
            </Row>
            <Row centerY fill spaceBetween>
              <Text numberOfLines={2} style={styles.subtitle}>
                {subtitle}
              </Text>
              {icon == null ? null : (
                <View style={styles.icon}>
                  <BoxIcon name={icon} iconColor={iconColor} />
                </View>
              )}
            </Row>
          </Column>
        </View>
      </Shadow>
    </Pressable>
  )
}

export default Box
