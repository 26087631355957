import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React from 'react'
import { Text } from 'react-native-paper'
import { PressableOpacity } from 'react-native-pressable-opacity'
import { Shadow } from 'react-native-shadow-2'

import useHandleLink from '../hooks/useHandleLink'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import { Column, Row } from './Primitives'

import type { CmsTextpuffFragment } from '../clients/backend.generated'

interface Props {
  readonly data: CmsTextpuffFragment
}

const useStyles = createThemedStylesHook(() => ({
  shadow: { width: '100%', borderRadius: 5 },
  text: { fontSize: 18, fontWeight: '700' },
}))

const CmsTextPuffComponent: React.FC<Props> = ({ data }) => {
  const handleLink = useHandleLink(data.link)
  const styles = useStyles()

  return (
    <PressableOpacity onPress={handleLink}>
      <Column marginY={10}>
        <Shadow style={styles.shadow} offset={DEFAULT_SHADOW_OFFSET} distance={2}>
          <Column width='100%' backgroundColor='white' borderRadius={5} padding={10}>
            <Row spaceBetween>
              <Text style={styles.text}>{data.title}</Text>
              <MaterialCommunityIcons name='chevron-right' size={20} />
            </Row>
            <Row>
              <Text>{data.description}</Text>
            </Row>
          </Column>
        </Shadow>
      </Column>
    </PressableOpacity>
  )
}

export default CmsTextPuffComponent
