import { Video } from 'expo-av'
import React from 'react'
import { StyleSheet } from 'react-native'

import type { CmsVideoFragment } from '../clients/backend.generated'

const styles = StyleSheet.create({
  video: { width: 200, height: 200 },
})

interface Props {
  readonly data: CmsVideoFragment
}

const CmsVideoComponent: React.FC<Props> = ({ data: { video } }) => {
  if (video.data?.attributes?.url == null) return null

  return (
    <Video source={{ uri: video.data?.attributes?.url }} style={styles.video} useNativeControls />
  )
}

export default CmsVideoComponent
