import { applicationId } from 'expo-application'
import * as IntentLauncher from 'expo-intent-launcher'
import { useCallback } from 'react'
import {
  Linking, Platform,
} from 'react-native'

const useOpenAppSettings = () => useCallback((activityAction = IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS) => {
  if (Platform.OS === 'ios') {
    void Linking.openURL('app-settings:')
  } else {
    void IntentLauncher.startActivityAsync(
      activityAction,
      { data: `package:${applicationId}` },
    )
  }
}, [])

export default useOpenAppSettings
