import * as Sentry from 'sentry-expo'

// Construct a new instrumentation instance. This is needed to communicate between the integration and React

Sentry.init({
  dsn: 'https://ed82bc7b0e8f41a4a4b1cf2dbd500c3e@o967921.ingest.sentry.io/6662237',
  enableInExpoDevelopment: false,
  debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  tracesSampleRate: 0.1,
})

export default Sentry.Browser
