import { LinearGradient } from 'expo-linear-gradient'
import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import BackButton from './BackButton'
import { Overlay, Row } from './Primitives'
import SettingsButton from './SettingsButton'

import type { PageColor } from '../contexts/Theme'
import type { FactoryProps } from '../utils/createThemedStylesHook'

type Props = {
  readonly title?: string
  readonly right?: 'close' | 'settings'
  readonly backIcon?: 'keyboard-backspace' | 'window-close'
  readonly insetTop?: number
}

const COLOR = FIGMA_COLORS.GRAY_1

const GRADIENTCOLOR: Record<PageColor, string> = {
  blue: FIGMA_COLORS.ESK_BLUE_2,
  green: FIGMA_COLORS.ESK_GREEN_3,
  greenInverted: FIGMA_COLORS.ESK_GREEN_3,
  pink: FIGMA_COLORS.ESK_PINK_2,
  yellow: FIGMA_COLORS.ESK_YELLOW_2,
}

const useStyles = createThemedStylesHook(({ insetTop, insets, right }: FactoryProps<{readonly insetTop?: number, readonly right: Props['right']}>) => ({
  backButton: { position: 'absolute', ...(right === 'close' ? { right: 10 } : { left: 10 }) },
  row: { marginTop: insetTop === undefined ? insets.top + 10 : insetTop, width: '100%' },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: '700',
    alignSelf: 'center',
    color: COLOR,
  },
  settingsButton: { position: 'absolute', right: 10 },
  gradient: { height: 35 + insets.top, width: '100%', ...StyleSheet.absoluteFillObject },
}))

const Header: React.FC<Props> = ({
  title = 'Min Klassiker', right, insetTop, backIcon = 'keyboard-backspace',
}) => {
  const { pageColor } = useTheme(),
        styles = useStyles({ insetTop, right })

  return useMemo(() => (
    <Overlay>
      <LinearGradient
        colors={[
          `${GRADIENTCOLOR[pageColor]}`, `${GRADIENTCOLOR[pageColor]}`, `${GRADIENTCOLOR[pageColor]}99`, `${GRADIENTCOLOR[pageColor]}00`,
        ]}
        style={styles.gradient}
        pointerEvents='none'
      />

      <Row
        center
        style={styles.row}
      >
        <BackButton
          icon={backIcon}
          style={styles.backButton}
        />
        <Text style={styles.title}>
          {title}
        </Text>
        { right === 'settings' ? (
          <SettingsButton style={styles.settingsButton} />
        ) : null }
      </Row>

    </Overlay>
  ), [
    pageColor, styles.gradient, styles.row, styles.backButton, styles.title, styles.settingsButton, backIcon, title, right,
  ])
}

export default Header
