import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React, { useCallback, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { Banner } from 'react-native-paper'

import { useBannersQuery, useDismissBannerMutation } from '../clients/backend.generated'
import { useIsInEditorMode } from '../contexts/EditorMode'
import { FIGMA_COLORS } from '../contexts/Theme'
import { useHandleLinkLazy } from '../hooks/useHandleLink'
import Styles from '../utils/Styles'
import { Column } from './Primitives'

import type { BannerFragment, CmsEnum_Componentmessagespage_Page as BannerPage } from '../clients/backend.generated'

const styles = StyleSheet.create({
  icon: { marginTop: -1 },
})

const SingleBanner: React.FC<{readonly banner: BannerFragment}> = ({ banner }) => {
  const handleLink = useHandleLinkLazy(),
        isInEditorMode = useIsInEditorMode(),
        [, dismissBanner] = useDismissBannerMutation()

  const icon = useCallback((props: any) => {
    const iconName = banner.attributes?.icon?.icon

    return iconName ? (
      <MaterialCommunityIcons
        {...props}
        name={iconName.replace('_', '-') as keyof typeof MaterialCommunityIcons.glyphMap}
        size={20}
        style={styles.icon}
        color={FIGMA_COLORS.ESK_GREEN_1}
      />
    ) : null
  }, [banner.attributes?.icon?.icon])

  return (
    <Banner
      key={banner.id}
      icon={icon}
      visible
      style={Styles.marginBottom16}
      theme={{ colors: { primary: FIGMA_COLORS.ESK_GREEN_1, surface: 'white' }, fonts: { thin: { fontWeight: '700' } } }}
      actions={(banner.attributes?.buttons || []).map((btn) => ({
        label: btn?.title || 'Dölj',
        onPress: () => {
          const link = btn?.link || undefined
          const { id } = banner
          if (link) {
            handleLink(link)
          }
          if (id && !isInEditorMode) {
            void dismissBanner({ bannerId: id })
          }
        },
      }))}
    >
      {banner.attributes?.title || ''}
    </Banner>
  )
}

/**
 * Banners will always be shown and in editor mode
 */
const Banners: React.FC<{readonly bannerPage?: BannerPage, readonly raceId?: string}> = ({ bannerPage, raceId }) => {
  const isInEditorMode = useIsInEditorMode(),
        [{ data }] = useBannersQuery({ requestPolicy: 'cache-and-network', variables: { } }),
        bannersToShow = useMemo(
          () => (data?.banners?.data ?? [])
            .filter((b) => {
              const isInScope = (b.attributes?.showOnPages?.some((p) => p?.page === bannerPage) || b.attributes?.races?.data.some((r) => r.id === raceId))

              return isInScope && (isInEditorMode || !data?.me.dismissedBannerIds.includes(b.id ?? ''))
            }),
          [
            bannerPage, data?.banners?.data, data?.me.dismissedBannerIds, isInEditorMode, raceId,
          ],
        )

  return (
    <Column width='100%'>
      { bannersToShow.map((b) => (<SingleBanner key={b.id} banner={b} />)) }
    </Column>
  )
}

export default Banners
