import useEvent from '@kingstinct/react/hooks/useEvent'
import React from 'react'
import {
  StyleSheet,
} from 'react-native'
import { PressableOpacity } from 'react-native-pressable-opacity'

import {
  Column, Text,
} from '../../components/Primitives'
import { FIGMA_COLORS } from '../../contexts/Theme'
import { EMPTY } from '../../utils/empty'
import reactionToEmoticonMap from './reactionToEmoticonMap'

import type {
  ReactionType,
} from '../../clients/backend.generated'

const HIT_SLOP = {
  top: 20, bottom: 20, left: 5, right: 5,
}

const styles = StyleSheet.create({
  userReactedBorder: { borderColor: FIGMA_COLORS.ESK_GRAY_1, borderWidth: StyleSheet.hairlineWidth },
})

const ReactionOverlayButton: React.FC<{
  readonly onPress: (reaction: ReactionType) => void,
  readonly reaction: ReactionType,
  readonly userReactions: readonly ReactionType[]
}> = ({ onPress, reaction, userReactions }) => {
  const onPressInternal = useEvent(() => {
    onPress(reaction)
  })

  const icon = reactionToEmoticonMap[reaction]

  return (
    <PressableOpacity
      hitSlop={HIT_SLOP}
      onPress={onPressInternal}
    >
      <Column
        padding={4}
        margin={3}
        paddingX={8}
        borderRadius={10}
        backgroundColor={userReactions.includes(reaction)
          ? 'white'
          : undefined}
        style={userReactions.includes(reaction) ? styles.userReactedBorder : EMPTY.OBJECT}
      >
        <Text>{icon}</Text>
      </Column>
    </PressableOpacity>
  )
}

export default React.memo(ReactionOverlayButton)
