import { Row, useEvent } from '@kingstinct/react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect, useMemo, useState } from 'react'
import { Switch } from 'react-native-paper'

import { useForegroundTracking } from '../hooks/useGeoTracker'
import { createThemedText } from '../utils/createThemedStylesHook'

const TRACK_WORKOUT_WITH_GPS_KEY = 'trackWorkoutWithGPS'

export function useWorkoutTrackingWithGpsSelection() {
  const [isEnabled, setDesiredValue] = useState<boolean>(false)

  useForegroundTracking(isEnabled)

  useEffect(() => {
    const init = async () => {
      const enabledSetting = await AsyncStorage.getItem(TRACK_WORKOUT_WITH_GPS_KEY)
      const enabled = enabledSetting === 'true'
      setDesiredValue(enabled)
    }
    void init()
  }, [])

  const toggle = useEvent(() => {
    setDesiredValue((desiredValue) => !desiredValue)
    if (isEnabled) {
      void AsyncStorage.removeItem(TRACK_WORKOUT_WITH_GPS_KEY)
    } else {
      void AsyncStorage.setItem(TRACK_WORKOUT_WITH_GPS_KEY, 'true')
    }
  })

  return useMemo(() => [isEnabled, toggle] as const, [isEnabled, toggle])
}

const SwitchLabel = createThemedText(() => ({
  fontSize: 16,
}))

interface WorkoutTrackingWithGpsSelectionProps {
  readonly onValueChange: () => void
  readonly value: boolean | undefined
}

const WorkoutTrackingWithGpsSelection: React.FC<WorkoutTrackingWithGpsSelectionProps> = (props) => (
  <Row centerY padding={24} paddingTop={38} paddingBottom={0} spaceAround>
    <SwitchLabel>Mät avstånd med GPS</SwitchLabel>
    <Switch {...props} />
  </Row>
)

export default WorkoutTrackingWithGpsSelection
