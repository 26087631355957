import useAppState from '@kingstinct/react/hooks/useAppState'
import {
  useEffect,
} from 'react'

import { usePingQuery } from '../clients/backend.generated'

const usePingMinaSidor = () => {
  const [, ping] = usePingQuery()
  const appState = useAppState()

  useEffect(() => {
    if (appState === 'active') {
      console.log('ping to wake up login')
      ping()
    }
  }, [ping, appState])
}

export default usePingMinaSidor
