import * as WebBrowser from 'expo-web-browser'
import { useCallback } from 'react'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'

import type { PageColor } from '../contexts/Theme'

const COLORS: Record<PageColor, { readonly controls: string; readonly toolbar: string }> = {
  blue: { controls: FIGMA_COLORS.ESK_BLUE_1, toolbar: FIGMA_COLORS.ESK_BLUE_2 },
  green: { controls: FIGMA_COLORS.ESK_GREEN_1, toolbar: FIGMA_COLORS.ESK_GREEN_4 },
  greenInverted: { controls: FIGMA_COLORS.ESK_GREEN_1, toolbar: FIGMA_COLORS.ESK_GREEN_4 },
  pink: { controls: FIGMA_COLORS.ESK_PINK_1, toolbar: FIGMA_COLORS.ESK_PINK_2 },
  yellow: { controls: '#000000', toolbar: FIGMA_COLORS.ESK_YELLOW_4 },
}

export const useOpenInKlassikernThemedBrowser = (url?: string) => {
  const { pageColor } = useTheme()
  return useCallback((overrideUrl?: string) => {
    const actualUrl = overrideUrl || url
    if (actualUrl) {
      void openInKlassikernThemedBrowser(actualUrl, pageColor)
    }
  }, [pageColor, url])
}

export const openInKlassikernThemedBrowser = async (url: string, color: PageColor) => WebBrowser.openBrowserAsync(url, {
  enableBarCollapsing: true,
  readerMode: true,
  controlsColor: COLORS[color].controls,
  // secondaryToolbarColor: FIGMA_COLORS.ESK_PINK_1,
  secondaryToolbarColor: FIGMA_COLORS.ESK_PINK_1,
  createTask: false,
  showTitle: true,
  toolbarColor: COLORS[color].toolbar,
  presentationStyle: WebBrowser.WebBrowserPresentationStyle.POPOVER,
  dismissButtonStyle: 'close',
})

export default openInKlassikernThemedBrowser
