import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import type { PropsWithChildren } from 'react'

const styles = StyleSheet.create({
  labelContainer: { marginHorizontal: 32 },
  labelText: { fontWeight: '300' },
})

type Props = PropsWithChildren<{ readonly title: string }>

const Label: React.FC<Props> = ({ children, title }) => (
  <View style={styles.labelContainer}>
    <Text style={styles.labelText}>{title}</Text>
    {children}
  </View>
)

export default Label
