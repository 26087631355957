import useEvent from '@kingstinct/react/hooks/useEvent'
import { FlashList } from '@shopify/flash-list'
import { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import Animated, { FadeInUp } from 'react-native-reanimated'

import { useShareScreenQuery } from '../../clients/backend.generated'
import Background from '../../components/Background'
import FetchingComponent from '../../components/FetchingComponent'
import Header from '../../components/Header'
import ListEmptyComponent from '../../components/ListEmptyComponent'
import { Column, HeaderSpacer } from '../../components/Primitives'
import RefetchControl from '../../components/RefetchControl'
import createThemedStylesHook from '../../utils/createThemedStylesHook'
import CmsNewsArticleShareComponent from './NewsArticleShareComponent'

import type { FeedItemDefaultFragment } from '../../clients/backend.generated'
import type { ESKScreen } from '../../types'

const useStyles = createThemedStylesHook(({ size, insets }) => ({
  item: {
    alignSelf: 'center',
    marginVertical: 8,
    width: size.width - 32,
  },
  footer: { height: 120 + insets.bottom },
}))

const ShareScreen: ESKScreen<'ShareTab'> = () => {
  const [after, setAfter] = useState<string | null>(null),
        [{ data, fetching }, refetch] = useShareScreenQuery({ variables: { after }, requestPolicy: 'cache-and-network' }),
        styles = useStyles(),
        fetchMore = useEvent(() => {
          if (!fetching && data?.feedItems.pageInfo.hasNextPage && data?.feedItems.pageInfo.endCursor) {
            setAfter(data.feedItems.pageInfo.endCursor)
          }
        }),
        nodes = useMemo(() => data?.feedItems.edges.map((e) => e.node), [data?.feedItems.edges])

  const renderItem = useCallback(({ index, item }: { readonly index: number, readonly item: FeedItemDefaultFragment }) => (
    <Animated.View
      entering={FadeInUp.delay(index * 100)}
      style={styles.item}
    >
      <CmsNewsArticleShareComponent
        data={item}
      />
    </Animated.View>
  ), [styles.item])

  return (
    <Column fill>
      <Background />

      <FlashList
        data={nodes}
        refreshControl={<RefetchControl onRefetch={refetch} />}
        onEndReached={fetchMore}
        estimatedItemSize={460}
        renderItem={renderItem}
        ListEmptyComponent={fetching ? <FetchingComponent /> : <ListEmptyComponent refetch={refetch} />}
        ListHeaderComponent={<HeaderSpacer />}
        ListFooterComponent={(
          <View style={styles.footer}>
            <ActivityIndicator animating={fetching && !!after} />
          </View>
        )}
      />
      <Header
        right='settings'
      />
    </Column>

  )
}

export default ShareScreen
