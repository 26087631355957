import React, { useMemo } from 'react'
import { ScrollView } from 'react-native-gesture-handler'

import { useNutritionDetailsQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import CmsArticleDetailsComponent from '../components/CmsArticleDetailsComponent'
import FetchingComponent from '../components/FetchingComponent'
import Header from '../components/Header'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'

import type { ESKScreen } from '../types'

const NutritionDetailsScreen: ESKScreen<'NewsArticle'> = ({ route: { params: { id } } }) => {
  const [
    {
      data, error, fetching,
    },
    refetch,
  ] = useNutritionDetailsQuery({ variables: { id } })

  const nutritionArticle = useMemo(() => data?.nutritionArticle?.data?.attributes, [data?.nutritionArticle?.data?.attributes])

  return (
    <Column fill>
      <Background activityIcons='none' />

      <ScrollView refreshControl={<RefetchControl onRefetch={refetch} />}>
        <HeaderSpacer />

        { fetching && !nutritionArticle ? <FetchingComponent /> : (nutritionArticle ? (
          <CmsArticleDetailsComponent {...nutritionArticle} />
        // eslint-disable-next-line unicorn/no-nested-ternary
        ) : error ? (
          <ListEmptyComponent
            isError
            refetch={refetch}
          />
        ) : null) }

        <BottomSpacer />
      </ScrollView>

      <Header
        backIcon='keyboard-backspace'
        right='settings'
        title='Kostråd'
      />
    </Column>
  )
}

export default NutritionDetailsScreen
