import { FlashList } from '@shopify/flash-list'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import Animated, { FadeInUp } from 'react-native-reanimated'

import {
  CmsEnum_Componentcategoriessporttype_Sporttype as SportType, useNewsListingQuery,
} from '../clients/backend.generated'
import Box from '../components/Box'
import FetchingComponent from '../components/FetchingComponent'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { Column } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { usePublicationState } from '../contexts/EditorMode'
import { useMergePagedResults } from '../hooks/useMergePagedResults'
import useSanitizeCmsData from '../hooks/useSanitizeCmsData'
import { useOpenInKlassikernThemedBrowser } from '../utils/browser'
import createThemedStylesHook, { createThemedView } from '../utils/createThemedStylesHook'

import type { NewsArticleEntityListingFragment } from '../clients/cache.generated'
import type { ESKScreen, NewsTabParamList, SanitizedCmsType } from '../types'

const SPORT_TYPE_MAP: Record<keyof NewsTabParamList, SportType> = {
  BikingNewsTab: SportType.BIKING,
  RunningNewsTab: SportType.RUNNING,
  SkiingNewsTab: SportType.SKIING,
  SwimmingNewsTab: SportType.SWIMMING,
}

const useStyles = createThemedStylesHook(({ size }) => ({
  item: {
    alignSelf: 'center',
    marginVertical: 8,
    width: size.width - 32,
  },
}))

const ListFooter = createThemedView(({ insets }) => ({
  height: 120 + insets.bottom,
}))

const NewsListingScreen: ESKScreen<'NewsListing'> = ({ navigation, route: { name } }) => {
  const publicationState = usePublicationState(),
        sportType = SPORT_TYPE_MAP[name as keyof NewsTabParamList],
        [page, setPage] = useState(1),
        [{ data: queryData, fetching }, fetch] = useNewsListingQuery({ variables: { page, publicationState, sportType } }),
        [pagedResults, { refetch, fetchMore }] = useMergePagedResults(queryData?.newsArticles, { setPage, fetching, fetch }),
        data = useSanitizeCmsData(pagedResults),
        styles = useStyles(),
        openInKlassikernThemedBrowser = useOpenInKlassikernThemedBrowser()

  const renderItem = useCallback(({ index, item }: { readonly index: number, readonly item: SanitizedCmsType<NewsArticleEntityListingFragment> }) => (
    <Animated.View
      entering={FadeInUp.delay(index * 100)}
      style={styles.item}
    >
      <Box
        icon='chevron'
        onPress={() => {
          if (item.attributes?.externalUrl) {
            void openInKlassikernThemedBrowser(item.attributes.externalUrl)
          } else {
            navigation.navigate('NewsArticle', { id: item.id })
          }
        }}
        subtitle={dayjs(item.attributes.publishedAt).format('D MMMM YYYY')}
        title={item.attributes.title}
        uri={item.attributes?.image?.data?.attributes?.url ?? item.attributes.externalImageUrl}
      />
    </Animated.View>
  ), [navigation, openInKlassikernThemedBrowser, styles.item])

  return (
    <FlashList
      data={data}
      refreshControl={<RefetchControl onRefetch={refetch} />}
      keyExtractor={(item) => item.id}
      onEndReached={fetchMore}
      estimatedItemSize={100}
      renderItem={renderItem}
      ListEmptyComponent={fetching ? <FetchingComponent /> : <ListEmptyComponent refetch={refetch} />}
      ListHeaderComponent={<Column height={16} />}
      ListFooterComponent={ListFooter}
    />
  )
}

export default NewsListingScreen
