import { useEvent, useAppState, useDebounce } from '@kingstinct/react'
import useAddSnackbar from '@kingstinct/react/hooks/useAddSnackbar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Device from 'expo-device'
import * as Location from 'expo-location'
import { useState, useEffect, useMemo } from 'react'
import { Platform, InteractionManager } from 'react-native'
import { uniqBy } from 'remeda'

import { useAddCoordinatesToWorkoutMutation } from '../clients/backend.generated'
import { DevicePlatform } from '../clients/cache.generated'
import sentry from '../clients/sentry'
import { useStableBackgroundPermissions, useStableForegroundPermissions } from '../contexts/LocationPermissionsContext'
import {
  deleteWorkoutData, DISTANCE_INTERVAL, getAllStoredWorkoutIds, LAST_WORKOUT_ID_STORAGE_KEY, LOCATION_TASK_NAME, persistCoords, readStoredCoords, sortCoords, TIME_INTERVAL,
} from '../utils/geo'
import getMostAccurateCoordinates from '../utils/getMostAccutateCoordinates'
import reduceCoordinatesToMeters from '../utils/reduceCoordinatesToMeters'
import useOpenAppSettings from './useOpenAppSettings'

import type { Coord } from '../utils/reduceCoordinatesToMeters'
import type { LocationObject } from 'expo-location'

export const useForegroundTracking = (shouldRequest = false) => {
  const [foregroundPermissions, requestForegroundPermissionsAsync] = useStableForegroundPermissions()
  const openSettings = useOpenAppSettings()

  const status = foregroundPermissions?.status
  const canAskAgain = foregroundPermissions?.canAskAgain

  useEffect(() => {
    if (shouldRequest) {
      if (status === Location.PermissionStatus.UNDETERMINED && canAskAgain) {
        void requestForegroundPermissionsAsync()
      }/* else if (status === Location.PermissionStatus.DENIED) {
        showSnackbar('Vänligen slå på Platstjänster för Min Klassiker', {
          actions: [{ label: 'Inställningar', onPress: () => { void openSettings() } }],
        })
      } this is handled with a banner instead */
    }
  }, [
    canAskAgain, openSettings, requestForegroundPermissionsAsync, shouldRequest, status,
  ])

  return foregroundPermissions
}

export const useBackgroundTracking = (hasActiveWorkout: boolean) => {
  const [permissions, requestBackgroundPermissionsAsync] = useStableBackgroundPermissions()

  // const showSnackbar = useAddSnackbar()
  // const openSettings = useOpenAppSettings()

  const requestBackgroundPermissions = useEvent(async () => {
    if (permissions) {
      if (permissions.canAskAgain) {
        const res = await requestBackgroundPermissionsAsync()
        return res
      }
      /* showSnackbar('Vänligen slå på Platstjänster i bakgrunden för Min Klassiker', {
        actions: [
          {
            label: 'Inställningar',
            onPress: () => { void openSettings() },
          },
        ],
      }) */
    }
    return undefined
  })

  useEffect(() => {
    if (permissions?.granted) {
      const init = async () => {
        const hasStarted = await Location.hasStartedLocationUpdatesAsync(LOCATION_TASK_NAME)

        if (!hasStarted) {
          if (hasActiveWorkout) {
            await Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
              accuracy: Location.Accuracy.Highest,
              mayShowUserSettingsDialog: true,
              activityType: Location.ActivityType.Fitness,
              timeInterval: TIME_INTERVAL,
              distanceInterval: DISTANCE_INTERVAL,
            })
          }
        } else if (!hasActiveWorkout) {
          await Location.stopLocationUpdatesAsync(LOCATION_TASK_NAME)
        }
      }
      void init()
    }
  }, [hasActiveWorkout, permissions])

  return [permissions, requestBackgroundPermissions] as const
}

export const useDumpCoords = () => {
  const [, addCoordinatesToWorkout] = useAddCoordinatesToWorkoutMutation()
  const dump = useEvent(async () => {
    const workoutIds = await getAllStoredWorkoutIds()

    await workoutIds.reduce(async (prev, workoutId) => {
      await prev
      const coords = await readStoredCoords(workoutId)

      sentry.addBreadcrumb({
        message: 'dumping coords',
        data: { workoutId, coords: coords.length },
      })

      if (coords && workoutId && coords.length > 0) {
        const res = await addCoordinatesToWorkout({
          workoutId,
          coordinates: coords.map((c) => ({
            latitude: c.latitude,
            longitude: c.longitude,
            timestamp: new Date(c.timestamp).toISOString(),
            altitude: c.altitude,
            accuracy: c.accuracy,
            altitudeAccuracy: c.altitudeAccuracy,
          })),
          deviceInfo: {
            recordedOnDevicePlatform: Platform.OS === 'ios' ? DevicePlatform.ios : DevicePlatform.android,
            recordedOnDeviceOsVersion: Device.osVersion,
            recordedOnDeviceBrand: Device.brand,
            recordedOnDeviceModelName: Device.modelName,
            recordedOnDeviceManufacturer: Device.manufacturer,
            recordedOnDeviceDesignName: Device.designName,
            recordedOnDeviceYearClass: Device.deviceYearClass,
            recordedOnDeviceName: Device.deviceName,
            recordedOnDeviceOsName: Device.osName,
            recordedOnDevicePlatformApiLevel: Device.platformApiLevel,
          },
        })

        if (res.error) {
          sentry.captureException(res.error, {
            extra: {
              message: 'error dumping coords',
              data: { workoutId, coords: coords.length },
            },
          })

          return // don't delete but continue with the rest
        }
      }

      await deleteWorkoutData(workoutId)
    }, Promise.resolve())
  })

  return dump
}

const useGeoTracker = (activeWorkoutId: string | null | undefined) => {
  const isTracking = !!activeWorkoutId,
        [coords, setCoords] = useState<readonly Coord[]>(),
        foregroundPermissions = useForegroundTracking(),
        isTrackingAndGranted = isTracking && foregroundPermissions?.status === 'granted',
        debouncedCoords = useDebounce(coords, 2000),
        appState = useAppState()

  useEffect(() => {
    if (activeWorkoutId) {
      void AsyncStorage.setItem(LAST_WORKOUT_ID_STORAGE_KEY, activeWorkoutId)
    } else {
      void AsyncStorage.removeItem(LAST_WORKOUT_ID_STORAGE_KEY)
      setCoords(undefined)
    }
  }, [activeWorkoutId])

  useEffect(() => {
    if (debouncedCoords && debouncedCoords.length > 0 && activeWorkoutId) {
      void InteractionManager.runAfterInteractions(() => {
        if (activeWorkoutId) {
          void persistCoords(debouncedCoords, activeWorkoutId)
        }
      })
    }
  }, [debouncedCoords, activeWorkoutId])

  const reloadCoords = useEvent(async (workoutId: string) => {
    const stored = await readStoredCoords(workoutId)
    // eslint-disable-next-line functional/immutable-data
    setCoords((prevCoords) => uniqBy((prevCoords ? [...stored, ...prevCoords] : stored), ((c) => c.timestamp)).sort(sortCoords))
  })

  useEffect(() => { // load when returning from non-active state
    if (appState === 'active' && activeWorkoutId) {
      void reloadCoords(activeWorkoutId)
    }
  }, [reloadCoords, appState, activeWorkoutId])

  useEffect(() => { // persist immediately if going into background
    if (appState !== 'active' && coords && coords.length > 0 && activeWorkoutId) {
      void persistCoords(coords, activeWorkoutId)
    }
  }, [appState, coords, activeWorkoutId])

  const callback = useEvent((c: LocationObject) => {
    if (activeWorkoutId) {
      const newCoord: Coord = {
        latitude: c.coords.latitude,
        longitude: c.coords.longitude,
        timestamp: c.timestamp,
        accuracy: c.coords.accuracy,
        altitude: c.coords.altitude,
        altitudeAccuracy: c.coords.altitudeAccuracy,
      }

      void InteractionManager.runAfterInteractions(() => {
        setCoords((prevCoords) => {
          if (prevCoords) {
            const sortedCoords = [...prevCoords, newCoord]

            return sortedCoords
          }
          return [newCoord]
        })
      })
    }
  })

  useEffect(() => {
    let listener: Location.LocationSubscription | undefined
    if (isTrackingAndGranted) {
      const setup = async () => {
        listener = await Location.watchPositionAsync({
          accuracy: Location.Accuracy.Highest,
          mayShowUserSettingsDialog: true,
          timeInterval: TIME_INTERVAL,
          distanceInterval: DISTANCE_INTERVAL,
        }, callback)
      }

      void setup()

      return () => {
        listener?.remove()
      }
    }
    return () => { }
  }, [callback, isTrackingAndGranted])

  const mostAccurateCoords = useMemo(() => (coords ? getMostAccurateCoordinates(coords) : null), [coords])

  const distance = useMemo(() => (mostAccurateCoords ? reduceCoordinatesToMeters(mostAccurateCoords) : null), [mostAccurateCoords])

  return [distance, useCurrentSpeed(mostAccurateCoords), mostAccurateCoords] as const
}

const useCurrentSpeed = (mostAccurateCoords: readonly Coord[] | null) => {
  const currentSpeed = useMemo(() => {
    if (!mostAccurateCoords) {
      return null
    }
    const last5 = mostAccurateCoords.slice(-5)
    const first = last5[0]
    // eslint-disable-next-line unicorn/prefer-at
    const last = last5[last5.length - 1]
    if (last && first && last !== first) {
      const timespanMs = last.timestamp - first.timestamp
      const distanceInMeters = reduceCoordinatesToMeters(last5)

      return timespanMs / (60 * distanceInMeters)
    }
    return null
  }, [mostAccurateCoords])

  return currentSpeed
}

export default useGeoTracker
