import useEvent from '@kingstinct/react/hooks/useEvent'
import React from 'react'
import {
  StyleSheet,
} from 'react-native'
import { PressableOpacity } from 'react-native-pressable-opacity'
import Animated, {
  ZoomOut, ZoomInEasyUp,
} from 'react-native-reanimated'

import {
  Column, Text,
} from '../../components/Primitives'
import { FIGMA_COLORS } from '../../contexts/Theme'
import { EMPTY } from '../../utils/empty'
import reactionToEmoticonMap from './reactionToEmoticonMap'

import type {

  ReactionType,
} from '../../clients/backend.generated'
import type { GestureResponderEvent } from 'react-native'

const styles = StyleSheet.create({
  userReactedBorder: { borderColor: FIGMA_COLORS.ESK_GRAY_1, borderWidth: StyleSheet.hairlineWidth },
})

type Props = {
  readonly showAllReactions: (e: GestureResponderEvent) => void,
  readonly onPress: (reaction: ReactionType) => void,
  readonly reaction: ReactionType
  readonly count: number
  readonly hasUserReacted: boolean
}

const ReactionRowItem: React.FC<Props> = ({
  showAllReactions, onPress, hasUserReacted, reaction, count,
}) => {
  const onPressInternal = useEvent(() => onPress(reaction))
  const iconAndCount = `${reactionToEmoticonMap[reaction]} ${count}`

  return (
    <Animated.View
      key={reaction}
      entering={ZoomInEasyUp}
      exiting={ZoomOut}
    >
      <PressableOpacity
        onPress={onPressInternal}
        onLongPress={showAllReactions}
      >
        <Column
          padding={4}
          margin={3}
          backgroundColor={FIGMA_COLORS.ESK_GRAY_4}
          borderRadius={10}
          style={hasUserReacted ? styles.userReactedBorder : EMPTY.OBJECT}
        >
          <Text style={{ color: FIGMA_COLORS.GRAY_2 }}>
            { iconAndCount }
          </Text>
        </Column>
      </PressableOpacity>
    </Animated.View>
  )
}

export default React.memo(ReactionRowItem)
