import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { useCallback, useMemo } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import * as DropdownMenu from 'zeego/dropdown-menu'

import { useTheme } from '../contexts/Theme'

import type IconsType from '@expo/vector-icons/build/MaterialCommunityIcons'
import type { PropsWithChildren } from 'react'

const ICON_TO_IOS_MAP = {
  'account-plus': 'person.badge.plus',
  'camera-outline': 'camera',
  'clock': 'clock',
  'close': 'xmark',
  'comment': 'bubble.right.fill',
  'flag-variant': 'flag.fill',
  'image-multiple': 'photo.on.rectangle.angled',
  'paperclip': 'paperclip',
  'playlist-plus': 'text.badge.plus',
  'repeat': 'repeat',
  'text-search': 'magnifyingglass',
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Icon: typeof IconsType = ({ ...props }) => {
  const theme = useTheme()

  return (
    <MaterialCommunityIcons
      color={theme.colors.text}
      size={20}
      {...props}
    />
  )
}

export type DropDownMenuItemType = {
  readonly onPress: () => void,
  readonly label: string,
  readonly icon?: keyof typeof ICON_TO_IOS_MAP,
  readonly type: 'item'
  readonly disabled?: boolean,
  readonly destructive?: boolean,
  readonly hidden?: boolean,
} | {
  readonly type: 'checkable',
  readonly label: string,
  readonly onPress: () => void,
  readonly status: 'mixed' | 'off' | 'on',
  readonly hidden?: boolean,
}

export type DropDownMenuItemRootType = DropDownMenuItemType | {
  readonly type: 'group',
  readonly key: string,
  readonly items: readonly DropDownMenuItemType[]
}

const styles = StyleSheet.create({
  item: { flexDirection: 'row', justifyContent: 'space-between', padding: 10 },
  separator: { height: 1, width: '100%' },
  itemIcon: { marginLeft: 20 },
  lastItem: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
  firstItem: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
  menuContent: {
    borderRadius: 5, paddingVertical: 5, shadowColor: 'black', shadowOpacity: 0.5, shadowRadius: 5, shadowOffset: { height: 0, width: 0 },
  },
})

interface DropDownMenuProps {
  readonly menuItems: readonly DropDownMenuItemRootType[]
}

const DropDownMenu: React.FC<PropsWithChildren<DropDownMenuProps>> = ({ menuItems, children }) => {
  const theme = useTheme()

  const renderItem = useCallback((item: DropDownMenuItemType, index: number) => {
    const first = index === 0
    const last = index === menuItems.length - 1

    if (item.hidden && Platform.OS === 'web') {
      return null
    }

    if (item.type === 'checkable') {
      return (
        <DropdownMenu.CheckboxItem
          key={item.label}
          onValueChange={item.onPress}
          value={item.status}
          hidden={item.hidden}
          style={[
            styles.item,
            first ? styles.firstItem : null,
            last ? styles.lastItem : null,
          ]}
        >
          <DropdownMenu.ItemIndicator style={styles.itemIcon}>
            <Icon name='check' color={theme.colors.text} />
          </DropdownMenu.ItemIndicator>
          <DropdownMenu.ItemTitle style={{ color: theme.colors.text }}>
            {item.label}
          </DropdownMenu.ItemTitle>
        </DropdownMenu.CheckboxItem>
      )
    }

    const {
      onPress, label, icon, destructive,
    } = item

    return (
      <DropdownMenu.Item
        key={label}
        onSelect={onPress}
        style={[
          styles.item,
          first ? styles.firstItem : null,
          last ? styles.lastItem : null,
        ]}
        destructive={item.destructive}
        disabled={item.disabled}
        hidden={item.hidden}
      >
        <DropdownMenu.ItemIcon
          iosIconName={icon ? ICON_TO_IOS_MAP[icon] : undefined}
          style={styles.itemIcon}
        >
          <Icon
            name={icon}
            color={destructive ? theme.colors.error : theme.colors.text}
          />
        </DropdownMenu.ItemIcon>
        <DropdownMenu.ItemTitle
          style={{ color: destructive ? theme.colors.error : theme.colors.text }}
        >
          {label}
        </DropdownMenu.ItemTitle>
      </DropdownMenu.Item>
    )
  }, [menuItems.length, theme.colors.error, theme.colors.text])

  const renderRootItem = useCallback((item: DropDownMenuItemRootType, index: number) => {
    const first = index === 0
    const last = index === menuItems.length - 1
    const hadGroupBefore = !first && menuItems[index - 1]?.type === 'group'
    if (item.type === 'group') {
      return (
        <React.Fragment key={item.key}>
          { !first && !hadGroupBefore ? (
            <DropdownMenu.Separator
              key={`${item.key}before`}
              style={[
                styles.separator,
                { backgroundColor: theme.colors.onSurface },
                first ? styles.firstItem : null,
                last ? styles.lastItem : null,
              ]}
            />
          ) : null }
          <DropdownMenu.Group key={`${item.key}group`}>
            {item.items.map(renderItem)}
          </DropdownMenu.Group>
          { !last ? (
            <DropdownMenu.Separator
              key={`${item.key}after`}
              style={[
                styles.separator,
                { backgroundColor: theme.colors.onSurface },
                first ? styles.firstItem : null,
                last ? styles.lastItem : null,
              ]}
            />
          ) : null }
        </React.Fragment>
      )
    }
    return renderItem(item, index)
  }, [menuItems, renderItem, theme.colors.onSurface])

  const itemsWithItems = useMemo(() => menuItems.filter((g) => g.type !== 'group' || g.items.some((i) => !i.hidden)), [menuItems])

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <View>
          { children }
        </View>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content style={[
        styles.menuContent, {
          backgroundColor: theme.colors.surface,
        },
      ]}
      >
        { itemsWithItems.map(renderRootItem) }
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

export default DropDownMenu
