import { useNavigation } from '@react-navigation/native'
import { useCallback } from 'react'

import { useTheme } from '../contexts/Theme'
import HeaderButton from './HeaderButton'

import type { HeaderButtonProps } from './HeaderButton'

type Props = {
  readonly style?: HeaderButtonProps['style']
}

const SettingsButton: React.FC<Props> = ({ style }) => {
  const navigation = useNavigation(),
        { pageColor } = useTheme(),
        onPressSettings = useCallback(() => navigation.navigate('Settings', { pageColor }), [navigation, pageColor])

  return (
    <HeaderButton
      icon='cog-outline'
      style={style}
      onPress={onPressSettings}
    />
  )
}

export default SettingsButton
