import { FlashList } from '@shopify/flash-list'
import React, { useCallback, useMemo, useState } from 'react'
import {
  StyleSheet, Text, TouchableNativeFeedback, View,
} from 'react-native'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'

import Background from '../components/Background'
import Flag from '../components/Flag'
import Header from '../components/Header'
import { Column } from '../components/Primitives'
import SearchBar from '../components/SearchBar'
import { CountryNames } from '../types'
import sanitize from '../utils/sanitize'
import Styles from '../utils/Styles'

import type { NationalityCode } from '../clients/backend.generated'
import type { ESKScreen } from '../types'

const COUNTRIES = Object.entries(CountryNames)
  .slice()
  .map(([code, name]) => [code as NationalityCode, name, sanitize(name)] as const)
  .sort(([, a], [, b]) => a.localeCompare(b))

const styles = StyleSheet.create({
  item: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 16,
  },
  text: {
    marginLeft: 16,
  },
})

interface CountryItemProps {
  readonly code: NationalityCode
  readonly name: string
  readonly onPress: (code: NationalityCode) => void
}

const CountryItem: React.FC<CountryItemProps> = ({ code, name, onPress }) => {
  const handleOnPress = useCallback(() => onPress(code), [code, onPress])

  return (
    <TouchableNativeFeedback
      accessibilityHint={name}
      accessibilityLabel={name}
      onPress={handleOnPress}
    >
      <View style={styles.item}>
        <Flag code={code} />
        <Text style={styles.text}>{name}</Text>
      </View>
    </TouchableNativeFeedback>
  )
}

const NationalityCodeSelectionScreen: ESKScreen<'NationalityCodeSelectionScreen'> = ({ navigation, route: { params: { callbackScreen } } }) => {
  const [searchString, setSearchString] = useState(''),
        insets = useSafeAreaInsets()

  const data = useMemo(() => {
    const q = sanitize(searchString.trim())

    return (q.length === 0) ? COUNTRIES : COUNTRIES.filter(([,, lower]) => lower.includes(q))
  }, [searchString])

  const edges = useMemo(() => ['top', 'left', 'right'] as const, [])

  const onPress = useCallback((nationalityCode: NationalityCode) => navigation.navigate(callbackScreen, { nationalityCode }), [callbackScreen, navigation])

  return (
    <SafeAreaView style={Styles.flexOne} edges={edges}>
      <Background activityIcons='none' waveMode='top' />

      <SearchBar
        autoFocus
        onChangeText={setSearchString}
        placeholder='Sök land'
        value={searchString}
      />

      <FlashList
        data={data}
        keyExtractor={([code]) => code}
        keyboardShouldPersistTaps='handled'
        estimatedItemSize={64}
        renderItem={({ item: [code, name] }) => (
          <CountryItem {...{ code, name, onPress }} />
        )}
        ListHeaderComponent={<Column height={16} />}
        ListFooterComponent={<Column height={insets.bottom} />}
      />

      <Header title='Välj nationalitet' />
    </SafeAreaView>
  )
}

export default NationalityCodeSelectionScreen
