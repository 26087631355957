import {
  DefaultTheme as DefaultNavigationTheme,
} from '@react-navigation/native'
import * as SystemUI from 'expo-system-ui'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import {
  Platform, StatusBar, useColorScheme, View,
} from 'react-native'
import {
  Provider as PaperProvider,
  DefaultTheme as DefaultPaperTheme,
  useTheme as useThemePaper,
} from 'react-native-paper'

import Styles from '../utils/Styles'

import type { $DeepPartial } from '@callstack/react-theme-provider'
import type { Theme as RNTheme } from '@react-navigation/native/lib/typescript/src/types'
import type { PropsWithChildren } from 'react'
import type { Theme as PaperThemeType } from 'react-native-paper/lib/typescript/types'

export const FIGMA_COLORS = {
  ESK_BLUE_1: '#82A8E1',
  ESK_BLUE_2: '#B9D2F9',
  ESK_BLUE_3: '#E5EFFF',
  ESK_BLUE_4: '#4F7ABB',
  ESK_GRAY_1: '#4A4A4A',
  ESK_GRAY_2: '#626360',
  ESK_GRAY_3: '#B4B6B1',
  ESK_GRAY_4: '#F2F2F2',
  ESK_GREEN_1: '#39863B',
  ESK_GREEN_2: '#ADD279',
  ESK_GREEN_3: '#E7F3D5',
  ESK_GREEN_4: '#F5FCEA',
  ESK_PINK_1: '#F197B2',
  ESK_PINK_2: '#FFCCDC',
  ESK_PINK_3: '#FFEDF2',
  ESK_PINK_4: '#DB517B',
  ESK_PURPLE_1: '#B696CD',
  ESK_PURPLE_2: '#E9D6F6',
  ESK_PURPLE_3: '#F9F0FF',
  ESK_PURPLE_4: '#7B4D9B',
  ESK_VIT: '#FFFFFF',
  ESK_YELLOW_1: '#FFE068',
  ESK_YELLOW_2: '#FFE893',
  ESK_YELLOW_3: '#FFF7D9',
  ESK_YELLOW_4: '#F9D139',
  GRAY_1: '#333333',
  GRAY_2: '#4F4F4F',
  YELLOW: '#F2C94C',
}

export type PageColor = 'blue' | 'green' | 'greenInverted' | 'pink' | 'yellow'

const accent = FIGMA_COLORS.ESK_GREEN_2
const primary = FIGMA_COLORS.ESK_GREEN_1
const onPrimary = '#ffffff'
const primaryOpacity = `${primary}80`

const background = FIGMA_COLORS.ESK_GREEN_4

type PaperColors = PaperThemeType['colors'];

interface MinKlassikerThemeColors extends PaperColors {
  readonly primaryOpacity: string
  readonly onPrimary: string
}

interface MinKlassikerThemeSizes {
  readonly titleFontSize: number
}

export interface MinKlassikerTheme extends PaperThemeType {
  readonly colors: MinKlassikerThemeColors
  readonly sizes: MinKlassikerThemeSizes
  readonly pageColor: PageColor
}

const sizes = {
  titleFontSize: 17,
}

export const DefaultTheme: MinKlassikerTheme = {
  ...DefaultPaperTheme,
  pageColor: 'green',
  colors: {
    ...DefaultPaperTheme.colors,
    background,
    accent,
    primary,
    onSurface: '#4A4A4A',
    onPrimary,
    primaryOpacity,
    text: FIGMA_COLORS.ESK_GRAY_2,
    surface: FIGMA_COLORS.ESK_GREEN_3,
  },
  /* fonts: {
    light: {
      fontFamily: 'Tisa Sans Pro',
      fontWeight: '400',
    },
    medium: {
      fontFamily: 'Tisa Pro',
      fontWeight: '400',
    },
    regular: {
      fontFamily: 'Proxima Nova',
      fontWeight: '700',
    },
    thin: {
      fontFamily: 'Tisa Sans Pro',
      fontWeight: '400',
    },
  }, */
  sizes,
}

export const PinkTheme: MinKlassikerTheme = {
  ...DefaultTheme,
  pageColor: 'pink',
  colors: {
    ...DefaultTheme.colors,
    background: FIGMA_COLORS.ESK_PINK_3,
    accent: FIGMA_COLORS.ESK_PINK_2,
    primary: FIGMA_COLORS.ESK_PINK_1,
    surface: 'white',
  },
  sizes,
}

export const BlueTheme: MinKlassikerTheme = {
  ...DefaultTheme,
  pageColor: 'blue',
  colors: {
    ...DefaultTheme.colors,
    background: FIGMA_COLORS.ESK_BLUE_3,
    accent: FIGMA_COLORS.ESK_BLUE_1,
    primary: FIGMA_COLORS.ESK_BLUE_4,
    surface: FIGMA_COLORS.ESK_BLUE_2,
  },
  sizes,
}

export const GreenInvertedTheme: MinKlassikerTheme = {
  ...DefaultTheme,
  pageColor: 'greenInverted',
  colors: {
    ...DefaultTheme.colors,
    background,
  },
  sizes,
}

export const YellowTheme: MinKlassikerTheme = {
  ...DefaultTheme,
  pageColor: 'yellow',
  colors: {
    ...DefaultTheme.colors,
    background: FIGMA_COLORS.ESK_YELLOW_3,
    accent: FIGMA_COLORS.ESK_YELLOW_2,
    primary: FIGMA_COLORS.ESK_YELLOW_1,
    surface: FIGMA_COLORS.ESK_YELLOW_2,
  },
  sizes,
}

export const NavigationLightTheme: RNTheme = {
  ...DefaultNavigationTheme,
  colors: {
    ...DefaultNavigationTheme.colors,
    background,
    primary,
  },
}

const Themes: Record<PageColor, MinKlassikerTheme> = {
  pink: PinkTheme,
  green: DefaultTheme,
  greenInverted: GreenInvertedTheme,
  blue: BlueTheme,
  yellow: YellowTheme,
}

type ThemeProviderProps = PropsWithChildren<{
  readonly color: PageColor
}>

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children, color = 'green',
}) => {
  const colorScheme = useColorScheme(),
        theme = Themes[color],
        viewRef = useRef<View>(null)

  useLayoutEffect(() => {
    viewRef.current?.setNativeProps({
      [`${(colorScheme || 'light').toString()}-theme`]: true,
    })
  }, [viewRef, colorScheme])

  useEffect(() => {
    if (Platform.OS === 'android') {
      void SystemUI.setBackgroundColorAsync(theme.colors.background)
    }
  }, [colorScheme, theme])

  return (
    <PaperProvider theme={theme}>
      <StatusBar backgroundColor='transparent' barStyle='dark-content' translucent />
      <View ref={viewRef} style={Styles.flexOne}>
        { children }
      </View>
    </PaperProvider>
  )
}

export const useTheme = useThemePaper as (
  overrides?: $DeepPartial<MinKlassikerTheme> | undefined
) => MinKlassikerTheme

export default { ThemeProvider }
