import { offlineExchange } from '@urql/exchange-graphcache'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import { makeAsyncStorage } from '@urql/storage-rn'
import jwtDecode from 'jwt-decode'
import {
  createClient, dedupExchange, errorExchange, fetchExchange,
} from 'urql'

import { BACKEND_ROOT_URL } from '../env'
import schema from './backend.schema'
import {
  FeedItemDefaultFragmentDoc,
  ActiveWorkoutDocument,
  UserChallengeByIdDocument,
  UserChallengeStatus,
  CmsEnum_Userchallenge_Goaltype as GoalType,
} from './cache.generated'

import type { GraphQLErrorExtensions } from '../types'
import type {
  CmsContentPageEntityResponse,
  CmsNewsArticleEntityResponse,
  CmsRaceEntityResponse,
  CmsUploadFileEntity,
  CmsUploadFileEntityResponse,
  FeedItemDefaultFragment,
  GraphCacheConfig,
  UserChallengeByIdQuery,
} from './cache.generated'
import type { CombinedErrorWithExtensions, CreateUrqlClient } from '@kingstinct/react/contexts/Urql'

const graphqlRootUrl = `${BACKEND_ROOT_URL}/graphql`

export const CACHE_DATA_KEY = 'graphcache-data'
export const CACHE_METADATA_KEY = 'graphcache-metadata'

export const cacheStorage = makeAsyncStorage({
  dataKey: CACHE_DATA_KEY, // The AsyncStorage key used for the data (defaults to graphcache-data)
  metadataKey: CACHE_METADATA_KEY, // The AsyncStorage key used for the metadata (defaults to graphcache-metadata)
  maxAge: 30, // How long to persist the data in storage (defaults to 7 days)
})

const createCacheConfig = (userId?: string): GraphCacheConfig => ({
  schema,
  updates: {
    Mutation: {
      joinUserChallenge: (_, variables, cache) => {
        cache.updateQuery({ query: UserChallengeByIdDocument, variables: { id: variables.challengeId } }, (prev) => {
          if (!prev) {
            return { __typename: 'Query' as const, userChallenge: { __typename: 'CmsUserChallengeEntityResponse' as const, data: undefined } }
          }
          const result: UserChallengeByIdQuery = ({
            ...prev,
            userChallenge: {
              __typename: 'CmsUserChallengeEntityResponse',
              ...prev.userChallenge,
              data: {
                __typename: 'CmsUserChallengeEntity',
                ...prev.userChallenge?.data,
                attributes: {
                  __typename: 'CmsUserChallenge',
                  id: variables.challengeId,
                  userProgress: 0,
                  title: '',
                  startDate: new Date().toISOString(),
                  endDate: new Date().toISOString(),
                  goalAmount: 0,
                  tintColor: '',
                  content: [],
                  headerImage: {
                    __typename: 'CmsUploadFileEntityResponse',
                    data: null,
                  },
                  goalType: GoalType.WORKOUT_COUNT,
                  ...prev.userChallenge?.data?.attributes,
                  userStatus: UserChallengeStatus.HAS_JOINED,
                },
              },
            },
          })

          return result
        })

        return true
      },
      wipeWorkoutData: (_result, args, cache) => {
        cache.invalidate('Query', 'me')
      },
      startWorkout: (parent, args, cache) => {
        const activeWorkout = {
          _id: args.workoutId!,
          startDate: args.startDate!,
          ...parent.startWorkout,
        }

        cache.updateQuery({
          query: ActiveWorkoutDocument,
        }, () => ({
          __typename: 'Query' as const,
          activeWorkout,
        }))
      },
      endWorkout: (_, __, cache) => {
        cache.updateQuery({
          query: ActiveWorkoutDocument,
        }, () => ({
          __typename: 'Query' as const,
          activeWorkout: null,
        }))
      },
      deleteWorkout: (_, { workoutId }, cache) => {
        cache.invalidate('Query', 'me')
        cache.invalidate('Query', 'workoutById', { workoutId })
      },
    },
  },
  optimistic: {
    startWorkout: (args, cache) => {
      const activeWorkout = {
        __typename: 'Workout' as const,
        _id: args.workoutId!,
        startDate: args.startDate!,
      }

      cache.updateQuery({
        query: ActiveWorkoutDocument,
      }, () => ({
        __typename: 'Query' as const,
        activeWorkout,
      }))

      return activeWorkout
    },
    endWorkout: ({ endDate, workoutId }, cache) => {
      const activeWorkoutQuery = cache.readQuery({
        query: ActiveWorkoutDocument,
      })

      cache.invalidate('Query', 'me')
      cache.updateQuery({
        query: ActiveWorkoutDocument,
      }, () => ({
        __typename: 'Query' as const,
        activeWorkout: null,
      }))

      return {
        __typename: 'Workout',
        _id: workoutId,
        activeWorkoutTimeInMilliseconds: endDate && activeWorkoutQuery?.activeWorkout ? new Date(endDate).valueOf() - new Date(activeWorkoutQuery.activeWorkout.startDate).valueOf() : 0,
        endDate,
      }
    },
    googleFitConnect: () => ({
      __typename: 'User',
      _id: userId,
      isConnectedWithGoogleFit: true,
    }),
    googleFitDisconnect: () => ({
      __typename: 'User',
      _id: userId,
      isConnectedWithGoogleFit: false,
    }),
    setShowConnectWorkoutTracking: ({ shouldBeVisible }) => ({
      __typename: 'User',
      _id: userId,
      showConnectWorkoutTracking: shouldBeVisible,
    }),
    reactToFeedItem: (variables, cache) => {
      const feedItem = cache.readFragment(FeedItemDefaultFragmentDoc, {
        id: variables.feedItemId,
        type: variables.feedItemType,
        __typename: 'FeedItem',
      } as FeedItemDefaultFragment)
      if (feedItem) {
        let foundReaction = false

        const reactionCounts = (feedItem.reactionCounts || []).map((rc) => {
          if (rc.reaction === variables.reaction) {
            foundReaction = true
            return { ...rc, count: rc.count + 1 }
          }
          return rc
        })

        const userReactions = (feedItem.userReactions || []).includes(variables.reaction) ? feedItem.userReactions : [...(feedItem.userReactions || []), variables.reaction]

        return {
          ...feedItem,
          __typename: 'FeedItem',
          reactions: [],
          reactionCounts: foundReaction
            ? reactionCounts
            : [...reactionCounts, { reaction: variables.reaction, count: 1, __typename: 'ReactionCount' }],
          id: variables.feedItemId,
          type: variables.feedItemType,
          userReactions,
        }
      }
      return {
        __typename: 'FeedItem',
        id: variables.feedItemId,
        reactionCounts: [{ count: 1, reaction: variables.reaction, __typename: 'ReactionCount' }],
        title: '',
        userReactions: [variables.reaction],
        type: variables.feedItemType,
        reactions: [],
        authorId: null,
        description: null,
        imageUrl: null,
        publishedAt: null,
        shareUrl: null,
      }
    },
    unreactToFeedItem: (variables, cache) => {
      const feedItem = cache.readFragment(FeedItemDefaultFragmentDoc, {
        id: variables.feedItemId,
        type: variables.feedItemType,
        __typename: 'FeedItem',
      } as FeedItemDefaultFragment)

      const reactionCounts = feedItem?.reactionCounts
        ?.map((rc) => ({
          ...rc,
          count: rc.reaction === variables.reaction || !variables.reaction ? rc.count - 1 : rc.count,
        }))
        .filter((rc) => rc.count > 0)

      return ({
        ...feedItem,
        __typename: 'FeedItem',
        title: feedItem?.title || '',
        reactionCounts: reactionCounts || [],
        reactions: [],
        id: variables.feedItemId,
        type: variables.feedItemType,
        userReactions: variables.reaction && feedItem ? feedItem.userReactions.filter((r) => r !== variables.reaction) : [],
      })
    },
    joinUserChallenge: (variables, cache) => {
      // const userChallenge = cache.readFragment(UserChallengeDefaultFragmentDoc, {
      //   id: variables.challengeId,
      //   __typename: 'CmsUserChallenge',
      // } as UserChallengeDefaultFragment)

      cache.updateQuery({ query: UserChallengeByIdDocument, variables: { id: variables.challengeId } }, (prev) => {
        if (!prev) {
          return { __typename: 'Query' as const, userChallenge: { __typename: 'CmsUserChallengeEntityResponse' as const, data: undefined } }
        }
        const result: UserChallengeByIdQuery = ({
          ...prev,
          userChallenge: {
            __typename: 'CmsUserChallengeEntityResponse',
            ...prev.userChallenge,
            data: {
              __typename: 'CmsUserChallengeEntity',
              ...prev.userChallenge?.data,
              attributes: {
                __typename: 'CmsUserChallenge',
                id: variables.challengeId,
                userProgress: 0,
                title: '',
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
                goalAmount: 0,
                tintColor: '',
                content: [],
                headerImage: {
                  __typename: 'CmsUploadFileEntityResponse',
                  data: null,
                },
                goalType: GoalType.WORKOUT_COUNT,
                ...prev.userChallenge?.data?.attributes,
                userStatus: UserChallengeStatus.HAS_JOINED,
              },
            },
          },
        })

        return result
      })

      return true
    },
  },
  resolvers: {
    Query: {
      feedItems: relayPagination(),
    },
    User: {
      workouts: relayPagination(),
    },
  },
  keys: {
    CmsBannerEntityResponseCollection: () => null,
    CmsChallenge: (d) => `${d.__typename}`,
    CmsChallengeEntityResponse: (d) => `${d.__typename}`,
    CmsSetting: (d) => `${d.__typename}`,
    CmsSettingEntityResponse: (d) => `${d.__typename}`,
    CmsComponentLinkGenericLink: (l) => `${l.__typename}:${l.id}:${l.externalUrl}`,
    CmsContentPage: (p) => `${p.__typename}:${p.updatedAt}`,
    CmsContentPageEntityResponse: (d: CmsContentPageEntityResponse) => `${d.__typename}:${d.id}`,
    // eslint-disable-next-line react/destructuring-assignment
    CmsNewsArticle: (n) => (('id' in n && n.id) ? `${n.__typename}:${n.id}` : null),
    CmsNewsArticleEntityResponse: (d: CmsNewsArticleEntityResponse) => `${d.__typename}:${d.id}`,
    CmsNewsArticleEntityResponseCollection: () => null,
    CmsNutritionArticleEntityResponseCollection: () => null,
    CmsPagination: () => null,
    CmsRace: (d) => `${d.__typename}:${d?.title}`,
    CmsRaceEntityResponse: (d: CmsRaceEntityResponse) => `${d.__typename}:${d.data?.id}`,
    CmsRaceEntityResponseCollection: (d) => d.__typename,
    CmsRaceRelationResponseCollection: () => null,
    CmsResponseCollectionMeta: () => null,

    // Provide cache keys based on the url since the API does not provide ids' for any upload file entity
    CmsUploadFile: (d) => `${d.__typename}:${d.url}`,
    CmsUploadFileEntity: (d: CmsUploadFileEntity) => `${d.__typename}:${d.attributes?.url}`,
    CmsUploadFileEntityResponse: (d: CmsUploadFileEntityResponse) => `${d.__typename}:${d.data?.attributes?.url}`,
    Coordinate: () => null,
    CmsWorkoutArticleEntityResponseCollection: () => null,
    FeedItem: (d) => `${d.__typename}:${d.id}:${d.type}`,
    GoogleFitOAuthData: (d) => `${d.__typename}:z${d.redirectUrl}`,
    ReactionCount: (rc) => `${rc.__typename}:${rc.reaction}`,
    WorkoutSimpleStat: () => null,
    WorkoutStat: () => null,
    CmsUserChallengeEntityResponse: () => null,
    CmsUserChallengeEntityResponseCollection: () => null,
  },
  storage: cacheStorage,
})

const createClientWithToken: CreateUrqlClient<GraphQLErrorExtensions> = ({
  token, onError, clearToken,
}) => {
  const userId = token ? (jwtDecode(token) as { readonly sub: string }).sub : undefined
  const client = createClient({
    url: graphqlRootUrl,
    requestPolicy: 'cache-and-network',
    fetchOptions: () => ({
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }),
    exchanges: [
      dedupExchange,
      offlineExchange(createCacheConfig(userId)),
      errorExchange({
        onError: (e, operation) => {
          const error = e as unknown as CombinedErrorWithExtensions<GraphQLErrorExtensions>
          onError(error, operation)
          if (error.graphQLErrors) {
            const authError = error.graphQLErrors.find((e) => e.extensions?.code === 'UNAUTHENTICATED')

            if (authError) {
              clearToken()
            }
          }
        },
      }),
      fetchExchange,
    ],
  })

  return client
}

export default createClientWithToken
