import dayjs from 'dayjs'
import { StyleSheet, Text } from 'react-native'
import { Title } from 'react-native-paper'

import { FIGMA_COLORS } from '../contexts/Theme'
import CmsContent from './CmsContent'
import CmsLabelsComponent from './CmsLabelsComponent'
import FullWidthImage from './FullWidthImage'
import { Column } from './Primitives'

import type { NewsArticleDetailFragment, NutritionArticleDetailFragment } from '../clients/backend.generated'
import type { FullWidthImageProps } from './FullWidthImage'

const styles = StyleSheet.create({
  publishedAt: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 14,
    fontWeight: '400',
    marginVertical: 16,
  },
  title: {
    color: FIGMA_COLORS.GRAY_2,
    fontSize: 28,
    fontWeight: '700',
    marginVertical: 16,
    textAlign: 'center',
  },
})

interface Props extends Pick<NutritionArticleDetailFragment, 'content' | 'headerImage' | 'labels' | 'title'>{
  readonly imageOverlay?: FullWidthImageProps['overlay']
  readonly publishedAt?: NewsArticleDetailFragment['publishedAt']
  /** Overrides 'headerImage' prop */
  readonly uri?: string | null
}

const CmsArticleDetailsComponent: React.FC<Props> = ({
  content, headerImage, imageOverlay, labels, publishedAt, title, uri,
}) => (
  <>
    <FullWidthImage overlay={imageOverlay} uri={uri ?? headerImage?.data?.attributes?.url} />

    <CmsLabelsComponent labels={labels} />

    <Column marginX={32}>
      {!publishedAt ? null : (
        <Text style={styles.publishedAt}>
          {publishedAt ? dayjs(publishedAt).format('D MMMM YYYY') : 'Ej publicerad ännu'}
        </Text>
      )}

      <Title style={styles.title}>
        {title}
      </Title>

      <Column marginY={16}>
        <CmsContent animate data={content} />
      </Column>
    </Column>
  </>
)

export default CmsArticleDetailsComponent
