import 'expo-dev-client'
// Prohibit excessive font scaling and use sv locale
import './src/utils/init'

import { PortalProvider } from '@gorhom/portal'
import { AuthProvider, useEvent } from '@kingstinct/react'
import { SnackbarProvider } from '@kingstinct/react/contexts/Snackbar'
import { StringsProvider } from '@kingstinct/react/contexts/Strings'
import UrqlProvider from '@kingstinct/react/contexts/Urql'
import useAddSnackbar from '@kingstinct/react/hooks/useAddSnackbar'
import React, { useMemo } from 'react'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

import sentry from './src/clients/sentry'
import createClientWithToken from './src/clients/urql'
import EditorProvider from './src/contexts/EditorMode'
import LocationPermissionProvider from './src/contexts/LocationPermissionsContext'
import { ThemeProvider } from './src/contexts/Theme'
import { BACKEND_ROOT_URL } from './src/env'
import Navigation from './src/Navigation'
import safeStringify from './src/utils/safeStringify'
import Styles from './src/utils/Styles'

import type { GraphQLErrorExtensions } from './src/types'
import type { OperationDefinitionNode } from 'graphql'

const AppInner = () => {
  const showSnackbar = useAddSnackbar()

  return (
    <LocationPermissionProvider>
      <AuthProvider>
        <UrqlProvider<GraphQLErrorExtensions>
          onError={useEvent((error, operation) => {
            let handledError = false
            if (error.networkError) {
              showSnackbar(__DEV__ ? `Anrop till ${BACKEND_ROOT_URL} misslyckades` : `Nätverksanrop misslyckades, vänligen kontrollera din uppkoppling`, {
                id: 'network-error',
              })
              handledError = true
            } else if (error.graphQLErrors) {
              const isAuthenticationError = error.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHENTICATED')
              if (isAuthenticationError) {
                showSnackbar(`Din session har gått ut, vänligen logga in igen`, {
                  id: 'unauthorized',
                })
                handledError = true
              }
              if (__DEV__) {
                const operationDefinition = operation.query.definitions.find((d) => d.kind === 'OperationDefinition') as OperationDefinitionNode | undefined
                const opName = operationDefinition?.name?.value
                showSnackbar(`URQL Error (${opName}): ${JSON.stringify(error)}`, {
                  id: opName || 'urql-generic-error',
                })
              }
            }
            sentry.captureException(error, {
              level: handledError ? 'info' : 'error',
              extra: { operation: safeStringify(operation) },
            })
          })}
          createClient={createClientWithToken}
        >
          <EditorProvider>
            <Navigation />
          </EditorProvider>
        </UrqlProvider>
      </AuthProvider>
    </LocationPermissionProvider>
  )
}

function App() {
  return (
    <StringsProvider strings={useMemo(() => ({
      'Network request failed': 'Nätverksanropet misslyckades',
      'Something went wrong, please try again': 'Något gick fel, försök igen',
      'Try again': 'Försök igen',
      'You are offline': 'Vi kunde inte nå servern, vänligen kontrollera din anslutning',
    }), [])}
    >
      <GestureHandlerRootView style={Styles.flexOne}>
        <ThemeProvider color='green'>
          <PortalProvider>
            <SnackbarProvider>
              <AppInner />
            </SnackbarProvider>
          </PortalProvider>
        </ThemeProvider>
      </GestureHandlerRootView>
    </StringsProvider>
  )
}

export default sentry.wrap(App)
