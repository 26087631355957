import { FlashList } from '@shopify/flash-list'
import capitalize from 'capitalize'
import React, { useCallback, useMemo, useState } from 'react'
import {
  StyleSheet, Text, TouchableNativeFeedback, View,
} from 'react-native'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'

import { WorkoutActivityType } from '../../clients/backend.generated'
import Background from '../../components/Background'
import Header from '../../components/Header'
import KlassikerActivityIcon from '../../components/KlassikerActivityIcon'
import { Column } from '../../components/Primitives'
import SearchBar from '../../components/SearchBar'
import { useTheme } from '../../contexts/Theme'
import { KlassikerWorkoutActivityTypeMappings, WorkoutActivityTypeNames } from '../../types'
import sanitize from '../../utils/sanitize'
import Styles from '../../utils/Styles'

import type { StartStackParamList } from '../../types'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'

const WORKOUT_ACTIVITY_TYPES = Object.entries(KlassikerWorkoutActivityTypeMappings)
  .filter(([key]) => (key as WorkoutActivityType) !== WorkoutActivityType.UTILITY_BIKING)
  .map(([key, value]) => {
    const type = key as WorkoutActivityType
    const name = capitalize(WorkoutActivityTypeNames[type].replace('_', ''))

    return [
      type, name, name.toLowerCase(), value,
    ] as const
  })
  .sort(([, a], [, b]) => a.localeCompare(b))

const styles = StyleSheet.create({
  item: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 16,
  },
  text: {
    position: 'absolute',
    marginLeft: 56,
  },
})

interface WorkoutActivityTypeItemProps {
  readonly color: string
  readonly item: typeof WORKOUT_ACTIVITY_TYPES[number]
  readonly onPress: (type: WorkoutActivityType) => void
}

const WorkoutActivityTypeItem: React.FC<WorkoutActivityTypeItemProps> = ({
  color,
  item: [
    type,
    name,, klassikerType,
  ], onPress,
}) => {
  const handleOnPress = useCallback(() => onPress(type), [type, onPress])

  return (
    <TouchableNativeFeedback
      accessibilityHint={name}
      accessibilityLabel={name}
      onPress={handleOnPress}
    >
      <View style={styles.item}>
        <KlassikerActivityIcon
          color={color}
          type={klassikerType}
        />
        <Text style={styles.text}>{name}</Text>
      </View>
    </TouchableNativeFeedback>
  )
}

const WorkoutActivityTypeSelectionScreen: React.FC<NativeStackScreenProps<StartStackParamList, 'WorkoutActivityTypeSelectionScreen'>> = ({ navigation, route: { params: { callbackScreen } } }) => {
  const [searchString, setSearchString] = useState(''),
        insets = useSafeAreaInsets(),
        theme = useTheme()

  const data = useMemo(() => {
    const q = sanitize(searchString.trim())

    return (q.length === 0) ? WORKOUT_ACTIVITY_TYPES : WORKOUT_ACTIVITY_TYPES.filter(([,, lower]) => lower.includes(q))
  }, [searchString])

  const edges = useMemo(() => ['top', 'left', 'right'] as const, [])

  const onPress = useCallback((workoutActivityType: WorkoutActivityType) => navigation.navigate(callbackScreen, { workoutActivityType }), [callbackScreen, navigation])

  return (
    <SafeAreaView style={Styles.flexOne} edges={edges}>
      <Background activityIcons='none' waveMode='top' />

      <SearchBar
        onChangeText={setSearchString}
        placeholder='Sök träningsform'
        value={searchString}
      />

      <FlashList
        data={data}
        keyExtractor={([code]) => code}
        keyboardShouldPersistTaps='handled'
        estimatedItemSize={64}
        renderItem={({ item }) => (
          <WorkoutActivityTypeItem {...{ color: theme.colors.primary, item, onPress }} />
        )}
        ListHeaderComponent={<Column height={16} />}
        ListFooterComponent={<Column height={insets.bottom} />}
      />

      <Header title='Välj träningsform' />
    </SafeAreaView>
  )
}

export default WorkoutActivityTypeSelectionScreen
