import { MaterialTopTabBar } from '@react-navigation/material-top-tabs'
import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { Text, View } from 'react-native'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import BackButton from './BackButton'
import Background from './Background'
import SettingsButton from './SettingsButton'

import type { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'

const useStyles = createThemedStylesHook(({ insets }) => ({
  back: {
    bottom: 0,
    left: 10,
    position: 'absolute',
  },
  container: { zIndex: 100 },
  gradient: {
    height: 24,
    position: 'absolute',
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingTop: insets.top,
  },
  settings: {
    bottom: 0,
    position: 'absolute',
    right: 10,
  },
  title: {
    color: FIGMA_COLORS.GRAY_2,
    fontSize: 28,
    fontWeight: '700',
    textAlign: 'center',
  },
}))

interface Props extends MaterialTopTabBarProps {
  readonly title: string
}

const TopTabHeader: React.FC<Props> = ({ title, ...props }) => {
  const styles = useStyles()
  const { colors: { background } } = useTheme()

  return (
    <View style={styles.container}>
      <Background activityIcons='none' waveMode='top' isTopBar />
      <View style={styles.header}>
        <BackButton style={styles.back} />
        <Text style={styles.title}>{title}</Text>
        <SettingsButton style={styles.settings} />
      </View>
      <MaterialTopTabBar {...props} />
      <View>
        <LinearGradient
          colors={[background, `${background}00`]}
          style={styles.gradient}
          pointerEvents='none'
        />
      </View>
    </View>
  )
}

export function createTopTabHeader(title: string) {
  return (props: MaterialTopTabBarProps) => <TopTabHeader title={title} {...props} />
}
