import React from 'react'

import Markdown from './Markdown'
import { Column } from './Primitives'

import type { CmsRichTextFragment } from '../clients/backend.generated'

interface Props {
  readonly data: CmsRichTextFragment
}

const CmsRichTextComponent: React.FC<Props> = ({ data }) => (
  <Column fill marginBottom={10}>
    <Markdown>{data.richText}</Markdown>
  </Column>
)

export default CmsRichTextComponent
