import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import useEvent from '@kingstinct/react/hooks/useEvent'
import React from 'react'
import {
  Clipboard,
  Platform,
  Share, StyleSheet,
} from 'react-native'
import { PressableOpacity } from 'react-native-pressable-opacity'

import {
  ReactionType,
} from '../../clients/backend.generated'
import {
  Column, Row,
} from '../../components/Primitives'
import { FIGMA_COLORS, useTheme } from '../../contexts/Theme'

import type {
  GestureResponderEvent, ShareAction, ShareContent, ShareOptions,
} from 'react-native'

const styles = StyleSheet.create({
  actionRow: { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 },
  iconButton: {
    alignItems: 'center', flex: 1, justifyContent: 'center', alignSelf: 'center',
  },
})

type Props = {
  readonly shareUrl?: string | null,
  readonly userReactions: readonly ReactionType[],
  readonly title: string,
  readonly showAllReactions: (e: GestureResponderEvent) => void,
  readonly onPressReaction: (reaction: ReactionType) => void
}

const share = async (content: ShareContent, options?: ShareOptions | undefined): Promise<ShareAction> => {
  if (Platform.OS === 'web' && !navigator.share) {
    Clipboard.setString('url' in content ? content.url : content.message)
    return Promise.resolve({
      action: 'sharedAction',
      activityType: undefined,
    })
  }

  return Share.share(content, options)
}

const ActionRow: React.FC<Props> = ({
  shareUrl, userReactions, title, showAllReactions, onPressReaction,
}) => {
  const theme = useTheme()

  const onPressLike = useEvent(() => {
    onPressReaction(ReactionType.LIKE)
  })

  return (
    <Row
      spaceEvenly
      marginX={-20}
      marginTop={10}
      backgroundColor={FIGMA_COLORS.ESK_GRAY_4}
      marginBottom={-20}
      height={60}
      style={styles.actionRow}
    >
      <PressableOpacity
        style={styles.iconButton}
        onPress={onPressLike}
        onLongPress={showAllReactions}
      >
        <Column fill center>
          <MaterialCommunityIcons
            name='thumb-up'
            color={userReactions.includes(ReactionType.LIKE) ? theme.colors.primary : FIGMA_COLORS.ESK_GRAY_2}
            size={30}
          />

        </Column>
      </PressableOpacity>
      { shareUrl ? (
        <>
          <Column marginY={10} width={1} backgroundColor={FIGMA_COLORS.ESK_GRAY_2} />
          <PressableOpacity
            onPress={async () => share({
              message: shareUrl,
              title,
              url: shareUrl,
            }, {
              tintColor: theme.colors.primary,
            })}
            style={styles.iconButton}
          >
            <Column fill center>

              <MaterialCommunityIcons
                name='share'
                color={FIGMA_COLORS.ESK_GRAY_2}
                size={30}
              />

            </Column>
          </PressableOpacity>
        </>
      ) : null }
    </Row>
  )
}

export default React.memo(ActionRow)
