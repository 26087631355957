import { createNavigationContainerRef } from '@react-navigation/native'

import type { ESKRootParamList } from './types'

export const navigationRef = createNavigationContainerRef()

export function navigate(name: string, params?: any) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name as keyof ESKRootParamList, params)
  }
}
