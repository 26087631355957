import { Row, Styles } from '@kingstinct/react'
import React from 'react'
import { Alert, ScrollView, Text } from 'react-native'
import { Button } from 'react-native-paper'

import { useDeleteWorkoutMutation, useWorkoutByIdQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import Header from '../components/Header'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import SnackbarPresentationView from '../components/SnackbarPresentationView'
import WorkoutMapView from '../components/WorkoutMapView'
import { StaticWorkoutTracker } from '../components/WorkoutTracker'
import { ThemeProvider, useTheme } from '../contexts/Theme'
import useGetAppleWorkoutCoords from '../hooks/useGetAppleWorkoutCoords'
import { WorkoutActivityTypeNames } from '../types'
import dayjs from '../utils/dayjs'
import getRegion from '../utils/getRegion'

import type { ModalParamList } from '../types'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'

const WorkoutDetails: React.FC<NativeStackScreenProps<ModalParamList, 'WorkoutDetailsPage'>> = ({ navigation, route: { params } }) => {
  const [{ data }] = useWorkoutByIdQuery({ variables: { workoutId: params.workoutId } })
  const [{ fetching: fetchingDelete }, deleteWorkout] = useDeleteWorkoutMutation()
  const theme = useTheme()
  const appleCoords = useGetAppleWorkoutCoords(data?.workoutById.appleHealthkitUUID)
  const coords = data?.workoutById.coordinates || appleCoords

  return (
    <ThemeProvider color={params.pageColor}>
      <Column fill>
        <Background activityIcons='none' />

        { data?.workoutById ? (
          <ScrollView style={Styles.flexOne}>
            <HeaderSpacer />

            <Column height={8} />

            <StaticWorkoutTracker
              workout={data.workoutById}
            />

            { coords && coords.length > 0 && data.workoutById.distanceInMeters && data.workoutById.distanceInMeters > 0 ? (
              <Row centerX paddingY={16}>
                <WorkoutMapView
                  coords={coords}
                  region={getRegion(coords)}
                  cacheEnabled
                />
              </Row>
            ) : null }

            <Text style={[Styles.margin4, Styles.paddingTop16, Styles.textCenter]}>
              {`${dayjs(data.workoutById.startDate).format('dddd Do MMMM YYYY')}`}
            </Text>
            <Text style={[Styles.textCenter]}>{`${dayjs(data.workoutById.startDate).format('H:mm')} - ${dayjs(data.workoutById.endDate).format('H:mm')}`}</Text>

            <Column height={24} />

            <Button
              disabled={fetchingDelete}
              color={theme.colors.error}
              mode='text'
              onPress={() => {
                Alert.alert('Konfirmera', `Är du säker på att du vill radera träningspasset ${WorkoutActivityTypeNames[params.activityType].toLowerCase()} som ufördes ${dayjs(data.workoutById.startDate).fromNow()}?`, [
                  {
                    onPress: async () => {
                      await deleteWorkout({ workoutId: params.workoutId })
                      if (navigation.canGoBack()) {
                        navigation.goBack()
                      } else {
                        navigation.pop()
                      }
                    },
                    style: 'destructive',
                    text: 'Ja',
                  },
                  {
                    text: 'Avbryt',
                  },
                ], {
                  cancelable: true,
                })
              }}
            >
              Ta bort
            </Button>

            <BottomSpacer />
          </ScrollView>
        ) : null }
        <Header
          right='settings'
          title={WorkoutActivityTypeNames[params.activityType]}
        />
      </Column>
      <SnackbarPresentationView />
    </ThemeProvider>
  )
}

export default WorkoutDetails
