import React, { useCallback } from 'react'
import { View } from 'react-native'

import { GenderCode } from '../clients/backend.generated'
import Styles from '../utils/Styles'
import RadioButton from './RadioButton'

interface GenderInputProps {
  readonly onChange: (value: GenderCode) => void
  readonly value?: GenderCode
}

const GenderCodeInput: React.FC<GenderInputProps> = ({ onChange, value }) => {
  const onPress = useCallback((genderCode: GenderCode) => () => {
    onChange(genderCode)
  }, [onChange])

  return (
    <View style={[Styles.flexOne, Styles.flexRow, Styles.marginTop4]}>
      <RadioButton
        label='Kvinna'
        onPress={onPress(GenderCode.F)}
        value={value === GenderCode.F}
      />

      <View style={Styles.flexOne} />

      <RadioButton
        label='Man'
        onPress={onPress(GenderCode.M)}
        value={value === GenderCode.M}
      />

      <View style={Styles.flexOne} />
    </View>
  )
}

export default GenderCodeInput
