import { AuthContext, useNullableState } from '@kingstinct/react'
import { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Linking } from 'react-native'
import { Button, Snackbar } from 'react-native-paper'
import unreachable from 'ts-unreachable'

import { useLoginMutation } from '../../clients/backend.generated'
import Background from '../../components/Background'
import ControlledTextInput from '../../components/ControlledTextInput'
import Header from '../../components/Header'
import { Column } from '../../components/Primitives'
import RoundedButton from '../../components/RoundedButton'

import type { LoginMutationVariables } from '../../clients/backend.generated'

const LoginScreen: React.FC = () => {
  const { setToken } = useContext(AuthContext),
        { handleSubmit, ...form } = useForm<LoginMutationVariables>(),
        [{ fetching }, loginMutation] = useLoginMutation(),
        [errorMessage, setErrorMessage, unsetErrorMessage] = useNullableState<string>()

  const onResetPassword = useCallback(() => {
    void Linking.openURL('https://minasidor.ensvenskklassiker.se/password/Reset')
  }, [])

  const onSubmit = useCallback(async (input: LoginMutationVariables) => {
    const { data, error } = await loginMutation(input)

    if (data?.login == null) {
      if (error?.message) {
        setErrorMessage(error?.message)
      } else {
        unsetErrorMessage()
      }
    } else if (data.login.__typename === 'AuthenticationSuccess') {
      setToken(data.login.token)
    } else if (data.login.__typename === 'AuthenticationFailure') {
      setErrorMessage(data.login.message)
    } else {
      unreachable(data.login)
    }
  }, [
    setToken, loginMutation, unsetErrorMessage, setErrorMessage,
  ])

  return (
    <Column fill paddingTop={100}>
      <Background waveMode='bottom' />

      <ControlledTextInput
        form={form}
        inputProps={{
          autoCapitalize: 'none',
          autoComplete: 'email',
          autoCorrect: false,
          keyboardType: 'email-address',
        }}
        label='E-postadress eller kundnummer'
        name='username'
        nextFocusName='password'
        rules={{
          pattern: { value: /\d+|^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: 'Ej giltig e-postadress eller kundnummer' },
          required: { value: true, message: 'Var vänlig fyll i e-postadress eller kundnummer' },
        }}
      />

      <ControlledTextInput
        form={form}
        inputProps={{
          autoCapitalize: 'none',
          onSubmitEditing: handleSubmit(onSubmit),
          returnKeyType: 'done',
          secureTextEntry: true,
        }}
        label='Lösenord'
        name='password'
        rules={{
          minLength: { value: 6, message: 'Minst 6 tecken' },
          required: { value: true, message: 'Var vänlig fyll i lösenord' },
        }}
      />

      <RoundedButton
        title='Logga in'
        onPress={handleSubmit(onSubmit)}
        isLoading={fetching}
      />

      <Column height={16} />

      <Button
        mode='text'
        onPress={onResetPassword}
      >
        Glömt lösenord?
      </Button>

      <Header />

      <Snackbar
        onDismiss={unsetErrorMessage}
        visible={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </Column>
  )
}

export default LoginScreen
