import React, { useMemo } from 'react'
import { Platform } from 'react-native'
import { Button } from 'react-native-paper'

import { FIGMA_COLORS } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'

const useStyles = createThemedStylesHook(({ theme }) => ({
  content: {
    height: 56,
    justifyContent: 'flex-start',
  },
  label: {
    fontFamily: Platform.select({ android: 'sans-serif' }),
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
    textTransform: 'none',
  },
  style: {
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    borderRadius: 4,
    borderWidth: 1,
    flex: 1,
    marginTop: 4,
  },
}))

interface Props {
  readonly icon?: React.ReactNode
  readonly onPress: () => void
  readonly placeholder: string
  readonly value?: string
}

const FormButton: React.FC<Props> = ({
  icon, onPress, placeholder, value,
}) => {
  const styles = useStyles()

  const renderIcon = useMemo(() => (icon ? () => icon : undefined), [icon])

  return (
    <Button
      contentStyle={styles.content}
      icon={renderIcon}
      labelStyle={[styles.label, { color: value ? undefined : FIGMA_COLORS.ESK_GRAY_2 }]}
      onPress={onPress}
      style={styles.style}
    >
      {value ?? placeholder}
    </Button>
  )
}

export default FormButton
