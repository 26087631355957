import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React, { useCallback, useState } from 'react'
import { StyleSheet, Text, TouchableHighlight } from 'react-native'
import Animated, { FadeInUp, FadeOutUp } from 'react-native-reanimated'
import { Shadow } from 'react-native-shadow-2'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import Styles, { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import Markdown from './Markdown'
import { Column, Row } from './Primitives'

import type { CmsExpandablepuffFragment } from '../clients/backend.generated'
import type { PageColor } from '../contexts/Theme'

/** Same as wave color */
const HEADER_COLORS: Record<PageColor, string> = {
  blue: FIGMA_COLORS.ESK_BLUE_2,
  green: FIGMA_COLORS.ESK_GREEN_3,
  greenInverted: FIGMA_COLORS.ESK_GREEN_4,
  pink: FIGMA_COLORS.ESK_PINK_2,
  yellow: FIGMA_COLORS.ESK_YELLOW_2,
}

const styles = StyleSheet.create({
  title: {
    color: FIGMA_COLORS.ESK_GRAY_1,
    fontSize: 18,
    fontWeight: '700',
  },
})

interface Props {
  readonly data: CmsExpandablepuffFragment
}

const CmsExpandablePuffComponent: React.FC<Props> = ({ data: { richText, title } }) => {
  const [expanded, setExpanded] = useState(false)

  const theme = useTheme()

  const onPress = useCallback(() => setExpanded((value) => !value), [])

  return (
    <Column marginBottom={8} width='100%'>
      <Shadow distance={2} offset={DEFAULT_SHADOW_OFFSET} style={[Styles.borderRadius8, Styles.width100pct]}>
        <TouchableHighlight
          accessibilityRole='button'
          onPress={onPress}
          style={[Styles.borderTopRadius8, { backgroundColor: HEADER_COLORS[theme.pageColor] }]}
          underlayColor={theme.colors.primary}
        >
          <Row centerY paddingX={16} paddingY={12} spaceBetween>
            <Text style={styles.title}>{title}</Text>
            <MaterialCommunityIcons color={FIGMA_COLORS.ESK_GRAY_1} name={expanded ? 'menu-up' : 'menu-down'} size={24} />
          </Row>
        </TouchableHighlight>

        <Column backgroundColor={FIGMA_COLORS.ESK_VIT} style={Styles.borderBottomRadius8}>
          {expanded ? (
            <Animated.View
              entering={FadeInUp}
              exiting={FadeOutUp}
              style={Styles.padding16}
            >
              <Markdown>
                {richText}
              </Markdown>
            </Animated.View>
          ) : null}
        </Column>
      </Shadow>
    </Column>
  )
}

export default CmsExpandablePuffComponent
