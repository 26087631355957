import FeatherIcons from '@expo/vector-icons/Feather'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React from 'react'
import unreachable from 'ts-unreachable'

import { FIGMA_COLORS } from '../contexts/Theme'

export interface BoxIconProps {
  readonly name: 'calendar' | 'chevron' | 'info' | 'timer' | 'trophy'
  readonly iconColor?: string
}

export const BoxIcon: React.FC<BoxIconProps> = ({ name, iconColor }) => {
  const color = iconColor || FIGMA_COLORS.ESK_GRAY_2
  switch (name) {
    case 'calendar': return <FeatherIcons color={color} name='calendar' size={20} />
    case 'chevron': return <MaterialCommunityIcons color={color} name='chevron-right' size={32} />
    case 'info': return <MaterialCommunityIcons color={color} name='information-outline' size={20} />
    case 'timer': return <MaterialCommunityIcons color={color} name='timer-outline' size={20} />
    case 'trophy': return <MaterialCommunityIcons color={color} name='trophy' size={20} />
    default: return unreachable(name)
  }
}
