import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { PressableOpacity } from 'react-native-pressable-opacity'

import { useTheme } from '../contexts/Theme'
import Styles from '../utils/Styles'

interface RadioButtonProps {
  readonly label: string
  readonly onPress: () => void
  readonly value: boolean | undefined
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, onPress, value }) => {
  const theme = useTheme()

  return (
    <PressableOpacity
      accessibilityHint={label}
      accessibilityLabel={label}
      onPress={onPress}
    >
      <View style={[Styles.alignItemsCenter, Styles.flexRow]}>
        <MaterialCommunityIcons
          color={value ? theme.colors.primary : undefined}
          name={value ? 'checkbox-marked-circle-outline' : 'checkbox-blank-circle-outline'}
          size={28}
          style={Styles.marginRight8}
        />
        <Text>{label}</Text>
      </View>
    </PressableOpacity>
  )
}

export default RadioButton
