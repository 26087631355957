import 'dayjs/locale/sv'
import dayjs from 'dayjs'
/* eslint-disable functional/immutable-data */
import { Text, TextInput } from 'react-native'
import '../clients/sentry'

// Construct a new instrumentation instance. This is needed to communicate between the integration and React

dayjs.locale('sv')

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.__reanimatedWorkletInit = () => {}// eslint-disable-line functional/immutable-data, no-underscore-dangle

const maxFontSizeMultiplier = 1.3

// https://stackoverflow.com/questions/50400238/react-native-accessibility-limit-fontsize-scaling
// @ts-expect-error defaultProps is missing on Text
if (Text.defaultProps == null) {
  // @ts-expect-error defaultProps is missing on Text
  Text.defaultProps = { maxFontSizeMultiplier }
} else {
  // @ts-expect-error defaultProps is missing on Text
  Text.defaultProps.maxFontSizeMultiplier = maxFontSizeMultiplier
}

// @ts-expect-error defaultProps is missing on TextInput
if (TextInput.defaultProps == null) {
  // @ts-expect-error  defaultProps is missing on TextInput
  TextInput.defaultProps = { maxFontSizeMultiplier }
} else {
// @ts-expect-error defaultProps is missing on TextInput
  TextInput.defaultProps.maxFontSizeMultiplier = maxFontSizeMultiplier
}
