import { Column } from '@kingstinct/react/primitives'
import React, { useMemo } from 'react'
import { ScrollView } from 'react-native-gesture-handler'

import { useWorkoutDetailsQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import { BoxIcon } from '../components/BoxIcon'
import CmsArticleDetailsComponent from '../components/CmsArticleDetailsComponent'
import FetchingComponent from '../components/FetchingComponent'
import Header from '../components/Header'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { BottomSpacer, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { FIGMA_COLORS } from '../contexts/Theme'
import { createThemedText, createThemedView } from '../utils/createThemedStylesHook'

import type { ESKScreen } from '../types'

const ImageOverlayContainer = createThemedView(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: theme.colors.accent,
  borderRadius: 16,
  bottom: 26,
  flexDirection: 'row',
  justifyContent: 'center',
  paddingHorizontal: 16,
  paddingVertical: 6,
  position: 'absolute',
}))

const ImageOverlayLabel = createThemedText(() => ({
  color: FIGMA_COLORS.ESK_GRAY_2,
  fontSize: 14,
  fontWeight: '700',
  marginLeft: 8,
  textAlign: 'center',
}))

const WorkoutArticleScreen: ESKScreen<'WorkoutArticle'> = ({ route: { params: { id } } }) => {
  const [{ data, error, fetching }, refetch] = useWorkoutDetailsQuery({ variables: { id } })

  const workoutArticle = useMemo(() => data?.workoutArticle?.data?.attributes, [data?.workoutArticle?.data?.attributes])

  const ImageOverlayIcon = useMemo(() => {
    switch (workoutArticle?.kind[0]?.__typename) {
      case 'CmsComponentWorkoutWorkoutProgram': return <BoxIcon name='calendar' />
      case 'CmsComponentWorkoutWorkoutSession': return <BoxIcon name='timer' />
      default: return <BoxIcon name='info' />
    }
  }, [workoutArticle?.kind])

  const imageOverlayLabel = useMemo(() => {
    switch (workoutArticle?.kind[0]?.__typename) {
      case 'CmsComponentWorkoutWorkoutProgram': return 'TRÄNINGSPROGRAM'
      case 'CmsComponentWorkoutWorkoutSession': return 'TRÄNINGSPASS'
      case 'CmsComponentWorkoutWorkoutTip': return 'TRÄNINGSTIPS'
      default: return 'TRÄNING'
    }
  }, [workoutArticle?.kind])

  const imageOverlay = useMemo(() => (
    <ImageOverlayContainer>
      {ImageOverlayIcon}
      <ImageOverlayLabel>{imageOverlayLabel}</ImageOverlayLabel>
    </ImageOverlayContainer>
  ), [ImageOverlayIcon, imageOverlayLabel])

  return (
    <Column fill>
      <Background activityIcons='none' />

      <ScrollView refreshControl={<RefetchControl onRefetch={refetch} />}>
        <HeaderSpacer />

        { fetching && !workoutArticle ? <FetchingComponent /> : (workoutArticle ? (
          <CmsArticleDetailsComponent
            {...workoutArticle}
            imageOverlay={imageOverlay}
          />
        // eslint-disable-next-line unicorn/no-nested-ternary
        ) : error ? (
          <ListEmptyComponent
            isError
            refetch={refetch}
          />
        ) : null) }

        <BottomSpacer />
      </ScrollView>

      <Header
        backIcon='keyboard-backspace'
        right='settings'
      />
    </Column>
  )
}

export default WorkoutArticleScreen
