/* eslint-disable functional/immutable-data */
import React, { useEffect } from 'react'
import { Text } from 'react-native'
import {
  useAnimatedReaction, useSharedValue, withRepeat, withTiming,
} from 'react-native-reanimated'
import { ReText, withPause } from 'react-native-redash'

import msToString from '../utils/msToString'

import type { StyleProp, TextStyle } from 'react-native'

interface StaticTimerProps {
  readonly milliseconds: number
  readonly style: StyleProp<TextStyle>
}

export const StaticTimer: React.FC<StaticTimerProps> = ({ milliseconds, style }) => (
  <Text style={style}>
    { msToString(milliseconds) }
  </Text>
)

interface TimerProps {
  readonly startTimestamp: number | null | undefined
  readonly style: StyleProp<TextStyle>
}

export const Timer: React.FC<TimerProps> = ({ startTimestamp, style }) => {
  const running = !!startTimestamp
  const startedAt = useSharedValue(0)
  const text = useSharedValue('00:00')
  const paused = useSharedValue(false)
  const progress = useSharedValue(0)

  useEffect(() => {
    paused.value = !running
  }, [paused, running])

  useEffect(() => {
    if (startTimestamp) startedAt.value = startTimestamp
  }, [startTimestamp, startedAt])

  useEffect(() => {
    progress.value = withPause(
      withRepeat(
        withTiming(10),
        -1,
      ),
      paused,
    )
  }, [paused, progress])

  // Using progress as an input because this hook have to react to it
  useAnimatedReaction(() => [paused.value, progress.value, startedAt.value] as const, ([paus,, start]) => {
    text.value = paus ? '00:00,0' : msToString(start ? Date.now() - start : 0)
  }, [progress])

  return (
    <ReText
      text={text}
      style={style}
    />
  )
}
