import React, { useCallback, useRef } from 'react'
import { Animated, View } from 'react-native'
import { Divider } from 'react-native-paper'

import Background from '../../components/Background'
import BankIdButton from '../../components/BankIdButton'
import RoundedButton from '../../components/RoundedButton'
import { useTheme } from '../../contexts/Theme'
import createThemedStylesHook from '../../utils/createThemedStylesHook'
import Styles from '../../utils/Styles'

import type { ESKScreen } from '../../types'

const useStyles = createThemedStylesHook(() => ({
  container: { flex: 1 },
  logo: {
    width: 200,
    height: 200,
    alignSelf: 'center',
    marginVertical: 50,
    marginTop: 100,
  },
}))

const AuthStartScreen: ESKScreen<'AuthStartScreen'> = ({ navigation }) => {
  const onSignup = useCallback(() => navigation.navigate('SignupScreen'), [navigation])
  const onLogin = useCallback(() => navigation.navigate('LoginScreen'), [navigation])
  const styles = useStyles()
  const theme = useTheme()
  const scrollY = useRef(new Animated.Value(0))

  return (
    <View style={Styles.flexOne}>
      <Background waveMode='bottom' />

      <Animated.ScrollView
        onScroll={Animated.event(
          // scrollX = e.nativeEvent.contentOffset.x
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: scrollY.current,
                },
              },
            },
          ],
          {
            useNativeDriver: true,
          },
        )}
      >
        <Animated.Image
          source={require('../../../assets/esk_logga_platta.png')}
          style={[
            styles.logo, {
              transform: [
                { scale: scrollY.current.interpolate({ inputRange: [-200, 200], outputRange: [1.3, 0.9], extrapolate: 'clamp' }) },
                { translateY: scrollY.current.interpolate({ inputRange: [0, 500], outputRange: [0, 100], extrapolate: 'clamp' }) },
              ],
            },
          ]}
          accessibilityIgnoresInvertColors
        />
        <BankIdButton />
        <Divider style={Styles.marginVertical16} />
        <RoundedButton
          onPress={onSignup}
          title='Skapa konto'
          style={Styles.margin4}
        />
        <RoundedButton
          onPress={onLogin}
          title='Logga in'
          backgroundColor='white'
          borderColor={theme.colors.primary}
          titleColor={theme.colors.primary}
          style={Styles.margin4}
        />
      </Animated.ScrollView>
    </View>
  )
}

export default AuthStartScreen
