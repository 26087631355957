import { ReactionType } from '../../clients/backend.generated'

const reactionToEmoticonMap: Record<ReactionType, string> = {
  [ReactionType.LIKE]: '👍',
  [ReactionType.CLAP]: '👏',
  [ReactionType.LOVE]: '❤️',
  [ReactionType.FIST_BUMP]: '👊',
  [ReactionType.STAR]: '🤩',
  [ReactionType.STRONG_ARM]: '💪',
  [ReactionType.WOW]: '😮',
}

export default reactionToEmoticonMap
