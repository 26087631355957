import { Row, Styles, useEvent } from '@kingstinct/react'
import ObjectId from 'bson-objectid'
import { requestForegroundPermissionsAsync } from 'expo-location'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, ScrollView } from 'react-native'
import { Banner } from 'react-native-paper'

import {
  CmsEnum_Componentmessagespage_Page as BannerPage,
  useActiveWorkoutQuery,
  useStartWorkoutMutation,
  useEndWorkoutMutation,
} from '../clients/backend.generated'
import Background from '../components/Background'
import Banners from '../components/Banners'
import Header from '../components/Header'
import HealthConnectionBanner from '../components/HealthConnectionBanner'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import RoundedButton from '../components/RoundedButton'
import WorkoutActivityTypeSelection, { useWorkoutActivityTypeSelection } from '../components/WorkoutActivityTypeSelection'
import WorkoutMapView from '../components/WorkoutMapView'
import WorkoutTracker, { oneDecimal } from '../components/WorkoutTracker'
import WorkoutTrackingWithGpsSelection, { useWorkoutTrackingWithGpsSelection } from '../components/WorkoutTrackingWithGpsSelection'
import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import useGeoTracker, { useBackgroundTracking, useDumpCoords, useForegroundTracking } from '../hooks/useGeoTracker'
import useOpenAppSettings from '../hooks/useOpenAppSettings'
import getRegion from '../utils/getRegion'

import type { ESKRootParamList, StartStackParamList } from '../types'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'

const getActiveWorkoutTimeInMilliseconds = (startDate: string): number => Date.now() - new Date(startDate).valueOf()

export const useAveragePaceMinPerKm = (startDate: string | null | undefined, distanceInMeters: number | null | undefined) => {
  if (!startDate) {
    return 0
  }
  const activeWorkoutTimeInMilliseconds = getActiveWorkoutTimeInMilliseconds(startDate)
  if (distanceInMeters && activeWorkoutTimeInMilliseconds) {
    return (activeWorkoutTimeInMilliseconds / (60 * distanceInMeters))
  }
  return 0
}
export const useAverageSpeedKmPerHr = (startDate: string | null | undefined, distanceInMeters: number) => {
  if (!startDate) {
    return 0
  }
  const activeWorkoutTimeInMilliseconds = getActiveWorkoutTimeInMilliseconds(startDate)
  if (distanceInMeters && activeWorkoutTimeInMilliseconds) {
    return oneDecimal((3600 * distanceInMeters) / activeWorkoutTimeInMilliseconds)
  }
  return 0
}

const ForegroundTrackingBanner: React.FC<{readonly hasActiveWorkout: boolean, readonly isTrackingGps: boolean}> = ({ hasActiveWorkout, isTrackingGps }) => {
  const foregroundPermissions = useForegroundTracking(hasActiveWorkout)
  const openSettings = useOpenAppSettings()

  return (
    <Banner
      visible={!foregroundPermissions?.granted && isTrackingGps}
      actions={foregroundPermissions?.canAskAgain ? [
        {
          label: 'Aktivera',
          onPress: () => { void requestForegroundPermissionsAsync() },
        },
      ] : [
        {
          label: 'Inställningar',
          onPress: () => { void openSettings() },
        },
      ]}
    >
      Slå på platstjänster för att mäta avstånd
    </Banner>
  )
}

const BackgroundTrackingBanner: React.FC<{readonly hasActiveWorkout: boolean, readonly isTrackingGps: boolean}> = ({ hasActiveWorkout, isTrackingGps }) => {
  const foregroundPermissions = useForegroundTracking()
  const openSettings = useOpenAppSettings()
  const [backgroundPermissions, requestBackgroundPermissions] = useBackgroundTracking(hasActiveWorkout)
  const [hidden, setHidden] = useState(false)

  return (
    <Banner
      visible={!backgroundPermissions?.granted && !!foregroundPermissions?.granted && isTrackingGps && !hidden && Platform.OS === 'ios'}
      actions={backgroundPermissions?.canAskAgain ? [
        {
          label: 'Senare',
          onPress: () => { setHidden(true) },
        },
        {
          label: 'Aktivera',
          onPress: () => { void requestBackgroundPermissions() },
        },
      ] : [
        {
          label: 'Senare',
          onPress: () => { setHidden(true) },
        },
        {
          label: 'Inställningar',
          onPress: () => { void openSettings() },
        },
      ]}
    >
      Slå på platstjänster i bakgrunden för stabilare avståndsmätning
    </Banner>
  )
}

const StartScreen: React.FC<NativeStackScreenProps<ESKRootParamList & StartStackParamList, 'StartScreen'>> = ({ navigation, route: { params } }) => {
  const [
    {
      data: activeWorkoutData, stale, fetching, error,
    },
  ] = useActiveWorkoutQuery()
  const [, startWorkout] = useStartWorkoutMutation()
  const [, endWorkout] = useEndWorkoutMutation()
  const activeWorkout = activeWorkoutData?.activeWorkout
  const isRunning = !!activeWorkout
  const [isTrackingGps, toggleGpsTracking] = useWorkoutTrackingWithGpsSelection()

  const workoutActivityTypeSelection = useWorkoutActivityTypeSelection(params?.workoutActivityType, navigation)

  const dumpCoords = useDumpCoords()

  const theme = useTheme()

  useEffect(() => {
    if (!isRunning && !stale && !fetching && !error) {
      void dumpCoords()
    }
  }, [
    dumpCoords, isRunning, stale, fetching, error,
  ])

  const onPress = useEvent(() => {
    if (isRunning) {
      void endWorkout({
        workoutId: activeWorkout._id,
        endDate: new Date().toISOString(),
      }).then(() => {
        navigation.navigate('WorkoutDetailsPage', { workoutId: activeWorkout._id, pageColor: theme.pageColor, activityType: workoutActivityTypeSelection.value })
      })
    } else {
      void startWorkout({
        workoutId: new ObjectId().toHexString(),
        activityType: workoutActivityTypeSelection.value,
        startDate: new Date().toISOString(),
      })
    }
  })

  const [distanceInMeters, currentPaceMinPerKm, coords] = useGeoTracker(isTrackingGps ? activeWorkout?._id : null)

  const averagePaceMinPerKm = useAveragePaceMinPerKm(activeWorkout?.startDate, distanceInMeters)

  const scrollRef = useRef<ScrollView>(null)

  const hasCoords = coords ? coords.length > 0 : false

  useEffect(() => {
    if (isTrackingGps && isRunning && hasCoords) {
      setImmediate(() => {
        scrollRef.current?.scrollTo({ y: 100, animated: true })
      })
    }
  }, [isTrackingGps, isRunning, hasCoords])

  return (
    <Column fill>
      <Background />
      <ScrollView style={Styles.flexOne} ref={scrollRef}>
        <HeaderSpacer />
        <HealthConnectionBanner />
        <Banners bannerPage={BannerPage.StartTab} />
        <BackgroundTrackingBanner hasActiveWorkout={isRunning} isTrackingGps={isTrackingGps} />
        <ForegroundTrackingBanner hasActiveWorkout={isRunning} isTrackingGps={isTrackingGps} />

        <Column height={8} />

        <WorkoutActivityTypeSelection
          disabled={isRunning}
          {...workoutActivityTypeSelection}
        />
        { !isRunning ? (
          <WorkoutTrackingWithGpsSelection
            onValueChange={toggleGpsTracking}
            value={isTrackingGps}
          />
        ) : null }

        <Column height={16} />

        {activeWorkout?.startDate ? (
          <WorkoutTracker
            averagePaceMinPerKm={averagePaceMinPerKm}
            distanceInMeters={distanceInMeters}
            currentPaceMinPerKm={currentPaceMinPerKm}
            startTimestamp={new Date(activeWorkout.startDate).valueOf()}
            isTrackingGps={isTrackingGps}
          />
        ) : null}

        <Column padding={24}>
          <RoundedButton
            backgroundColor={FIGMA_COLORS.ESK_GREEN_1}
            title={isRunning ? 'Avsluta träning' : 'Påbörja träning'}
            onPress={onPress}
          />
        </Column>

        { coords && isTrackingGps && isRunning ? (
          <Row centerX>
            <WorkoutMapView
              coords={coords}
              followsUserLocation
              region={Platform.OS === 'android' ? getRegion(coords) : undefined}
              showsUserLocation
            />
          </Row>
        ) : null }

        <BottomSpacer />
      </ScrollView>
      <Header
        right='settings'
      />
    </Column>
  )
}

export default StartScreen
