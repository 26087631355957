// eslint-disable-next-line import/no-unresolved
import { BACKEND_ROOT_URL as BACKEND_ROOT_URL_FROM_ENV } from '@env'
import Constants from 'expo-constants'

type ConfigProps = {
  readonly BACKEND_ROOT_URL: string
}

const extra = Constants.manifest?.extra as ConfigProps

const APP_SCHEME = Constants.manifest?.scheme as string
const BACKEND_ROOT_URL = BACKEND_ROOT_URL_FROM_ENV || extra.BACKEND_ROOT_URL

if (__DEV__) {
  console.log('Pointing on BACKEND_ROOT_URL', BACKEND_ROOT_URL)
}

export {
  APP_SCHEME,
  BACKEND_ROOT_URL,
}
