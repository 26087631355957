import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import {
  Controller,
} from 'react-hook-form'
import { Shadow } from 'react-native-shadow-2'

import { CountryNames } from '../types'
import ErrorText from './ErrorText'
import Flag from './Flag'
import FormButton from './FormButton'
import Label from './Label'

import type { AuthStackParamList } from '../types'
import type { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form'

interface Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  readonly callbackScreen: AuthStackParamList['NationalityCodeSelectionScreen']['callbackScreen']
  readonly form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  readonly name: TName
}

function ControlledNationalityCodeInput<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  callbackScreen, form: { control, formState: { errors } }, name,
}: Props<TFieldValues, TName>) {
  const navigation = useNavigation()

  const onSelectCountry = useCallback(() => navigation.navigate('NationalityCodeSelectionScreen', { callbackScreen }), [callbackScreen, navigation])

  return (
    <Label title='Land'>
      <Controller
        control={control}
        name={name}
        render={({ field: { value } }) => (
          <FormButton
            icon={value ? <Shadow distance={2}><Flag code={value} /></Shadow> : undefined}
            onPress={onSelectCountry}
            placeholder='Välj land'
            value={CountryNames[value]}
          />
        )}
        rules={{
          required: { value: true, message: 'Var vänlig fyll i land' },
        }}
      />
      <ErrorText>{errors.nationalityCode?.message}</ErrorText>
    </Label>
  )
}

export default ControlledNationalityCodeInput
