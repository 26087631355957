import React from 'react'

import FullWidthImage from './FullWidthImage'

import type { CmsImageFragment } from '../clients/backend.generated'

interface Props {
  readonly data: CmsImageFragment
}

const CmsImageComponent: React.FC<Props> = ({ data: { image } }) => (
  <FullWidthImage uri={image.data?.attributes?.url} />
)

export default CmsImageComponent
