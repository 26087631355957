import React, { useCallback, useEffect, useState } from 'react'
import {
  RefreshControl,
} from 'react-native'

import { useTheme } from '../contexts/Theme'

import type { RefreshControlProps } from 'react-native'

type Props = Omit<RefreshControlProps, 'onRefresh' | 'refreshing'> & {
  readonly onRefetch: (() => Promise<unknown> | unknown) | undefined,
  readonly isFetching?: boolean,
  readonly onStatusChange?: (isLoading: boolean) => void,
}

// eslint-disable-next-line prefer-arrow-callback
export default React.forwardRef<RefreshControl, Props>(function RefetchControl({
  onRefetch, isFetching, onStatusChange, ...props
}, ref) {
  const [isRefetching, setIsRefetching] = useState(false)
  const theme = useTheme()
  const onRefetchWithPull = useCallback(async () => {
    if (isFetching === undefined) {
      setIsRefetching(true)
    }

    try {
      if (onRefetch) {
        await onRefetch()
      }
    } finally {
      if (isFetching === undefined) {
        setIsRefetching(false)
      }
    }
  }, [isFetching, onRefetch])

  useEffect(() => {
    onStatusChange?.(isRefetching)
  }, [isRefetching, onStatusChange])

  useEffect(() => {
    if (isFetching !== undefined) {
      setIsRefetching(isFetching)
    }
  }, [isFetching])

  return (
    <RefreshControl
      ref={ref}
      {...props}
      refreshing={isRefetching}
      onRefresh={onRefetchWithPull}
      tintColor={theme.colors.primary}
    />
  )
})
