import React from 'react'
import { match } from 'ts-pattern'

import useHandleLink from '../hooks/useHandleLink'
import CmsExpandablePuffComponent from './CmsExpandablePuffComponent'
import CmsImageComponent from './CmsImageComponent'
import CmsMiniPuffComponent from './CmsMiniPuffComponent'
import CmsPuffComponent from './CmsPuffComponent'
import CmsRichTextComponent from './CmsRichTextComponent'
import CmsTextPuffComponent from './CmsTextPuffComponent'
import CmsVideoComponent from './CmsVideoComponent'
import ErrorText from './ErrorText'
import MenuItem from './MenuItem'
import RoundedButton from './RoundedButton'

import type {
  CmsContentFragment, CmsContentNewsArticleFragment, CmsContentRaceFragment, CmsContentSettingsFragment,
} from '../clients/backend.generated'

export interface CmsContentComponentProps {
  readonly data?: CmsContentFragment | CmsContentNewsArticleFragment | CmsContentRaceFragment | CmsContentSettingsFragment
}

const CmsContentComponent: React.FC<CmsContentComponentProps> = ({ data }) => {
  const handleLink = useHandleLink(data && 'link' in data ? data.link : undefined)

  return match(data)
    .with(undefined, () => null)
    .with({ __typename: 'CmsComponentContentButtonWithLink' }, ({ title }) => <RoundedButton onPress={handleLink} title={title} />)
    .with({ __typename: 'CmsComponentContentExpandablepuff' }, (d) => <CmsExpandablePuffComponent data={d} />)
    .with({ __typename: 'CmsComponentContentImage' }, (d) => <CmsImageComponent data={d} />)
    .with({ __typename: 'CmsComponentContentMinipuff' }, (d) => <CmsMiniPuffComponent data={d} />)
    .with({ __typename: 'CmsComponentContentPuff' }, (d) => <CmsPuffComponent data={d} />)
    .with({ __typename: 'CmsComponentContentRichText' }, (d) => <CmsRichTextComponent data={d} />)
    .with({ __typename: 'CmsComponentContentTextpuff' }, (d) => <CmsTextPuffComponent data={d} />)
    .with({ __typename: 'CmsComponentContentVideo' }, (d) => <CmsVideoComponent data={d} />)
    .with({ __typename: 'CmsComponentContentSettingsButton' }, ({ title }) => <MenuItem onPress={handleLink} title={title} />)
    .with({ __typename: 'CmsError' }, () => <ErrorText>Misslyckades att visa detta innehåll</ErrorText>)
    .exhaustive()
}

export default CmsContentComponent
