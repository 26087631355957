import useAddSnackbar from '@kingstinct/react/hooks/useAddSnackbar'
import useAppState from '@kingstinct/react/hooks/useAppState'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, {
  useEffect, useMemo, useState, createContext, useContext,
} from 'react'

import { CmsPublicationState } from '../clients/backend.generated'

import type { PropsWithChildren } from 'react'

type EditorContextData = {
  readonly isInEditorMode: boolean
  readonly toggleEditorMode: (setTo?: boolean) => void
}

export const EditorContext = createContext<EditorContextData>({
  isInEditorMode: false,
  toggleEditorMode: () => {},
})

const EDITOR_MODE = 'IS_EDITOR_MODE_ENABLED'

const EditorProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [editorMode, setEditorMode] = useState(false)
  const showSnackbar = useAddSnackbar()
  const appState = useAppState()

  useEffect(() => {
    const init = async () => {
      const token = await AsyncStorage.getItem(EDITOR_MODE)
      setEditorMode(!!token)
    }
    void init()
  }, [])

  useEffect(() => {
    if (editorMode) {
      void AsyncStorage.setItem(EDITOR_MODE, JSON.stringify(true))
    } else {
      void AsyncStorage.removeItem(EDITOR_MODE)
    }
  }, [editorMode])

  const value = useMemo<EditorContextData>(() => ({
    isInEditorMode: editorMode,
    toggleEditorMode: (setTo?: boolean) => {
      if (setTo === undefined) {
        setEditorMode((prev) => !prev)
      } else {
        setEditorMode(setTo)
      }
    },
  }), [editorMode])

  useEffect(() => {
    if (appState === 'active' && editorMode) {
      const showSnackbarWithButton = () => {
        showSnackbar('Du navigerar appen i redaktörsläge, det gör att du kan se även icke publicerat innehåll', {
          id: 'editor-mode',
          actions: [{ label: 'Slå av', onPress: () => setEditorMode(false) }],
        })
      }
      void showSnackbarWithButton()
    }
  }, [appState, showSnackbar, editorMode])

  return (
    <EditorContext.Provider value={value}>
      {children}
    </EditorContext.Provider>
  )
}

export const useToggleEditorMode = () => {
  const { toggleEditorMode } = useContext(EditorContext)

  return toggleEditorMode
}

export const useIsInEditorMode = (): boolean => {
  const { isInEditorMode } = useContext(EditorContext)
  return isInEditorMode
}

export const usePublicationState = (): CmsPublicationState => {
  const { isInEditorMode } = useContext(EditorContext)
  return isInEditorMode ? CmsPublicationState.PREVIEW : CmsPublicationState.LIVE
}

export default EditorProvider
