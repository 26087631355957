import React from 'react'
import { Searchbar } from 'react-native-paper'

import createThemedStylesHook from '../utils/createThemedStylesHook'

const useStyles = createThemedStylesHook(({ theme }) => ({
  searchBar: {
    backgroundColor: theme.colors.background,
    marginTop: 48,
  },
}))

interface SearchBarProps {
  readonly autoFocus?: boolean
  readonly placeholder: string
  readonly value: string
  readonly onChangeText: (value: string) => void
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const styles = useStyles()

  return (
    <Searchbar
      {...props}
      style={styles.searchBar}
    />
  )
}

export default SearchBar
