import React from 'react'
import { HelperText } from 'react-native-paper'

const ErrorText: React.FC<{ readonly children: unknown }> = ({ children }) => (
  <HelperText type='error'>
    {children as JSX.Element}
  </HelperText>
)

export default ErrorText
