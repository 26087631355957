import AsyncStorage from '@react-native-async-storage/async-storage'
import * as TaskManager from 'expo-task-manager'
import { uniqBy } from 'remeda'

import sentry from '../clients/sentry'
import safeStringify from './safeStringify'

import type { Coord } from './reduceCoordinatesToMeters'
import type { LocationObject } from 'expo-location'

export const LOCATION_TASK_NAME = 'location-task'

export const getAllStoredWorkoutIds = async () => {
  const allKeys = await AsyncStorage.getAllKeys()
  const workoutKeys = allKeys.filter((k) => k.startsWith(`${COORDS_ASYNC_STORAGE_KEY}:`))
  const cleanKeys = workoutKeys.map((k) => k.split(':')[1]).filter((a) => a && a.length > 0)

  sentry.addBreadcrumb({
    message: 'getAllStoredWorkoutIds',
    data: { workoutKeys },
  })

  return cleanKeys as readonly string[]
}

export const deleteWorkoutData = async (workoutId: string) => {
  sentry.addBreadcrumb({
    message: 'deleteWorkoutData',
    data: { workoutId },
  })
  await AsyncStorage.removeItem(`${COORDS_ASYNC_STORAGE_KEY}:${workoutId}`)
}

export const readStoredCoords = async (workoutId: string): Promise<readonly Coord[]> => {
  const storedCoords = await AsyncStorage.getItem(`${COORDS_ASYNC_STORAGE_KEY}:${workoutId}`)

  const coords = storedCoords ? JSON.parse(storedCoords) : []

  sentry.addBreadcrumb({
    message: 'readStoredCoords',
    data: { coordCount: coords.length, workoutId },
  })

  return coords as readonly Coord[]
}

export const persistCoords = async (coords: readonly Coord[], workoutId: string): Promise<void> => {
  if (coords && coords.length > 0) {
    sentry.addBreadcrumb({
      message: 'persistCoords',
      data: { coordCount: coords.length, workoutId },
    })
    // eslint-disable-next-line functional/immutable-data
    await AsyncStorage.setItem(`${COORDS_ASYNC_STORAGE_KEY}:${workoutId}`, JSON.stringify(uniqBy(coords, (c) => c.timestamp).sort(sortCoords)))
  }
}

TaskManager.defineTask(LOCATION_TASK_NAME, async ({ data, error }) => {
  if (error) {
    sentry.captureException(error)
    // Error occurred - check `error.message` for more details.
    return
  }
  if (data) {
    sentry.addBreadcrumb({
      message: 'Location Task Got Data',
      data: {
        data: safeStringify(data),
      },
    })
    const { locations } = data as { readonly locations: readonly LocationObject[]}

    const activeWorkoutId = await AsyncStorage.getItem(LAST_WORKOUT_ID_STORAGE_KEY)
    if (activeWorkoutId) {
      const d = await readStoredCoords(activeWorkoutId)
      const newData = [
        ...d, ...locations.map<Coord>(({
          coords: {
            altitude, latitude, longitude, accuracy, altitudeAccuracy,
          }, timestamp,
        }) => ({
          latitude, longitude, timestamp, altitude, accuracy, altitudeAccuracy, workoutId: activeWorkoutId,
        })),
      ]
      await persistCoords(newData, activeWorkoutId)
    }

    // do something with the locations captured in the background
  }
})

const COORDS_ASYNC_STORAGE_KEY = 'GEOTRACKER'
export const LAST_WORKOUT_ID_STORAGE_KEY = 'lastActiveWorkoutId'

export const sortCoords = (a: Coord, b: Coord) => a.timestamp - b.timestamp

export const TIME_INTERVAL = 5000
export const DISTANCE_INTERVAL = 10
