import useAppState from '@kingstinct/react/hooks/useAppState'
import useEvent from '@kingstinct/react/hooks/useEvent'
import * as Application from 'expo-application'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { useEffect } from 'react'
import { Platform } from 'react-native'

import { useRegisterExpoPushTokenMutation } from '../clients/backend.generated'
import sentry from '../clients/sentry'
import { useToggleEditorMode } from '../contexts/EditorMode'
import { useHandleLinkLazy } from './useHandleLink'

import type { CmsLinkFragment, DevicePlatform } from '../clients/backend.generated'

Notifications.setNotificationHandler({
  handleNotification: async (notification) => {
    console.log('handleNotification', notification)
    return Promise.resolve({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    })
  },

})

async function registerForPushNotificationsAsync(askForPermissions: boolean): Promise<{ readonly token: string, readonly experienceId: string } | null> {
  if (Device.isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== 'granted' && askForPermissions) {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      if (askForPermissions) {
        sentry.addBreadcrumb({
          message: 'Did not get push permission',
          category: 'notifications',
        })
      }

      return null
    }

    if (Platform.OS === 'android') {
      void Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.DEFAULT,
        vibrationPattern: [
          0, 250, 250, 250,
        ],
      })
    }

    const experienceId = __DEV__ ? `@kingstinct/min-klassiker-dev`
      : (Application.applicationId === 'se.ensvenskklassiker.minklassiker'
        ? `@kingstinct/min-klassiker-prod`
        : `@kingstinct/min-klassiker-staging`
      )

    const { data } = await Notifications.getExpoPushTokenAsync({
      experienceId,
    })

    return { token: data, experienceId }
  }

  return null
}

export const useAskForPushPermissions = () => {
  const [, registerExpoToken] = useRegisterExpoPushTokenMutation()

  const register = useEvent(async () => {
    const data = await registerForPushNotificationsAsync(true)
    if (data) {
      const { token, experienceId } = data
      sentry.addBreadcrumb({
        message: 'registerExpoToken',
        category: 'notifications',
        data: { token, experienceId },
      })
      await registerExpoToken({
        osVersion: Device.osVersion,
        token,
        experienceId,
        platform: Platform.OS as DevicePlatform,
      })
    }
  })

  return register
}

const usePushNotifications = () => {
  const [, registerExpoToken] = useRegisterExpoPushTokenMutation()

  const toggleEditorMode = useToggleEditorMode()

  const handleLink = useHandleLinkLazy()

  const appState = useAppState()

  useEffect(() => {
    if (appState === 'active' && Platform.OS !== 'web') {
      void Notifications.dismissAllNotificationsAsync()
    }
  }, [appState])

  const register = useEvent(async () => {
    const data = await registerForPushNotificationsAsync(false)
    if (data) {
      const { token, experienceId } = data
      sentry.addBreadcrumb({
        message: 'registerExpoToken',
        category: 'notifications',
        data: { token, experienceId },
      })
      await registerExpoToken({
        osVersion: Device.osVersion,
        token,
        experienceId,
        platform: Platform.OS as DevicePlatform,
      })
    }
  })

  useEffect(() => {
    void register()
  }, [register])

  /* useEffect(() => {

    const pushTokensListener = Notifications.addPushTokenListener(() => {
      register()
    })

    return () => pushTokensListener.remove()
  }, [register]) */

  useEffect(() => {
    // This listener is fired whenever a notification is received while the app is foregrounded
    const notificationReceivedListener = Notifications.addNotificationReceivedListener((notification) => {
      sentry.addBreadcrumb({
        message: 'addNotificationReceivedListener',
        category: 'notifications',
        data: notification.request,
      })

      // const link = notification.request.content.data.link as CmsLinkFragment | undefined

      // if (link) {
      // handleLink(link)
      // }

      // const title = notification.request.content.title || notification.request.content.body || notification.request.content.subtitle || 'Got push with undefined title'
      // showSnackbar(title)
    })

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded,
    // backgrounded, or killed)
    const notificationResponseReceivedListener = Notifications.addNotificationResponseReceivedListener((response) => {
      sentry.addBreadcrumb({
        message: 'addNotificationResponseReceivedListener',
        category: 'notifications',
        data: response,
      })

      // const title = response.notification.request.content.title || response.notification.request.content.body || response.notification.request.content.subtitle || 'Got push with undefined title'

      // showSnackbar(title)

      const link = response.notification.request.content.data?.link as CmsLinkFragment | undefined
      const isPreview = !!response.notification.request.content.data?.isPreview

      if (link) {
        if (isPreview) {
          toggleEditorMode(true)
        }
        handleLink(link)
      }
    })

    return () => {
      notificationReceivedListener.remove()
      notificationResponseReceivedListener.remove()
    }
  }, [handleLink, toggleEditorMode])
}

export default usePushNotifications
