import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { AuthContext, UrqlContext } from '@kingstinct/react'
import React, { useCallback, useContext } from 'react'
import { Platform, ScrollView, View } from 'react-native'
import { ActivityIndicator, Switch, Text } from 'react-native-paper'

import { useSettingsPageQuery } from '../clients/backend.generated'
import { cacheStorage } from '../clients/urql'
import Background from '../components/Background'
import CmsContent from '../components/CmsContent'
import Header from '../components/Header'
import MenuItem from '../components/MenuItem'
import {
  BottomSpacer, BottomSpacerType, HeaderSpacer, Row,
} from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import RoundedButton from '../components/RoundedButton'
import SnackbarPresentationView from '../components/SnackbarPresentationView'
import { EditorContext } from '../contexts/EditorMode'
import { FIGMA_COLORS, ThemeProvider } from '../contexts/Theme'
import useOpenAppSettings from '../hooks/useOpenAppSettings'
import { useOpenInKlassikernThemedBrowser } from '../utils/browser'
import createThemedStylesHook from '../utils/createThemedStylesHook'

import type { ESKScreen } from '../types'

const useStyles = createThemedStylesHook(({ insets }) => ({
  logoutButton: { marginBottom: insets.bottom, flex: 1, marginTop: 20 },
  container: { flex: 1, alignItems: 'center', justifyContent: 'center' },
  profilePictureWrapper: { backgroundColor: 'white', padding: 16, borderRadius: 100 },
  nameText: { color: FIGMA_COLORS.GRAY_2, fontSize: 18, fontWeight: '700' },
}))

const SettingsScreen: ESKScreen<'Settings'> = ({ route }) => {
  const { clearToken } = useContext(AuthContext),
        { isInEditorMode, toggleEditorMode } = useContext(EditorContext),
        [{ data, fetching }, refetch] = useSettingsPageQuery(),
        styles = useStyles(),
        openInKlassikernThemedBrowser = useOpenInKlassikernThemedBrowser(),
        { reloadClient } = useContext(UrqlContext),
        onPress = useCallback(() => {
          void openInKlassikernThemedBrowser('https://ensvenskklassiker.se')
        }, [openInKlassikernThemedBrowser]),
        openSettings = useOpenAppSettings()

  return (
    <ThemeProvider color={route?.params?.pageColor || 'green'}>
      <View>
        <Background activityIcons='none' />
        <ScrollView
          refreshControl={(
            <RefetchControl
              onRefetch={refetch}
            />
          )}
        >
          { fetching ? (
            <Row padding={50} center>
              <ActivityIndicator />
            </Row>
          ) : (
            <>
              <HeaderSpacer />
              <Row center>
                <View style={styles.profilePictureWrapper}>
                  <MaterialCommunityIcons
                    name='account-circle-outline'
                    size={32}
                    color={FIGMA_COLORS.ESK_GRAY_2}
                  />
                </View>
              </Row>

              { data?.me.__typename === 'User' ? (
                <>
                  <Row center padding={20}>
                    <Text style={styles.nameText}>{ `${data.me.firstName} ${data.me.lastName}` }</Text>
                  </Row>
                  { data.me.isSuperUser || isInEditorMode ? (
                    <Row center spaceAround padding={20}>
                      <Text>Redaktörsläge</Text>
                      <Switch
                        value={isInEditorMode}
                        onValueChange={toggleEditorMode}
                      />
                    </Row>
                  ) : null }
                </>
              ) : null }

              <CmsContent data={data?.setting?.data?.attributes?.content} />

              <MenuItem title='Notis & Platsinställningar' onPress={openSettings} />
              <MenuItem title='Vanliga frågor och svar' onPress={onPress} />
              <MenuItem title='Integritetspolicy' onPress={onPress} />
              <MenuItem title='Användarvillkor' onPress={onPress} />

              <RoundedButton
                onPress={() => {
                  void cacheStorage.clear()
                  reloadClient()
                }}
                title='Rensa Cache'
                style={styles.logoutButton}
              />
              <RoundedButton
                onPress={clearToken}
                title='Logga ut'
                style={styles.logoutButton}
              />
              <BottomSpacer
                type={BottomSpacerType.noBottomNavigation}
              />
            </>
          )}

        </ScrollView>
        <Header
          title='Inställningar'
          right='close'
          backIcon='window-close'
          insetTop={Platform.OS === 'ios' ? 30 : undefined}
        />
      </View>
      <SnackbarPresentationView />
    </ThemeProvider>

  )
}

export default SettingsScreen
