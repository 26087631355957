import { useEvent } from '@kingstinct/react'
import Healthkit, {
  HKQuantityTypeIdentifier, HKUnit, HKWorkoutActivityType, HKWorkoutTypeIdentifier, UnitOfEnergy, UnitOfLength,
} from '@kingstinct/react-native-healthkit'
import useAddSnackbar from '@kingstinct/react/hooks/useAddSnackbar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Alert, Platform } from 'react-native'

import {
  useUpsertAppleWorkoutsMutation, useLastWorkoutQuery, WorkoutSource, useWipeWorkoutDataMutation,
} from '../clients/backend.generated'
import { WorkoutActivityType } from '../clients/cache.generated'
import useAppleHealthAuthorizationStatus from './useAppleHealthAuthorizationStatus'

import type { HealthkitReadAuthorization } from '@kingstinct/react-native-healthkit'

const storageKey = 'HEALTHKIT_ENABLED'

export const authorizationsToRequest: readonly HealthkitReadAuthorization[] = [
  HKQuantityTypeIdentifier.distanceCycling,
  HKQuantityTypeIdentifier.distanceDownhillSnowSports,
  HKQuantityTypeIdentifier.distanceSwimming,
  HKQuantityTypeIdentifier.distanceWalkingRunning,
  HKQuantityTypeIdentifier.distanceWheelchair,
  HKQuantityTypeIdentifier.heartRate,
  HKWorkoutTypeIdentifier,
  'HKWorkoutRouteTypeIdentifier',
]

export const useIsHealthkitAvailable = () => {
  const [isAvailable, setAvailable] = useState(false)

  useEffect(() => {
    const init = async () => {
      const available = Platform.OS === 'ios' && await Healthkit.isHealthDataAvailable()
      setAvailable(available)
    }
    void init()
  }, [])

  return isAvailable
}

const useControlAppleHealth = () => {
  const [isEnabled, setEnabled] = useState(false)
  const [, wipeAllData] = useWipeWorkoutDataMutation()
  const [permissionStatus, requestPermissions] = useAppleHealthAuthorizationStatus(authorizationsToRequest)

  const onPressAppleHealthConnect = useCallback(async (shouldEnable: boolean) => {
    if (shouldEnable) {
      setEnabled(true)
      await requestPermissions()
      await AsyncStorage.setItem(storageKey, 'true')
    } else {
      setEnabled(false)
      void AsyncStorage.removeItem(storageKey)

      Alert.alert('Apple Hälsa frånkopplad', 'Vill du även radera all träningsdata vi tidigare har hämtat från Apple Hälsa?', [
        {
          onPress: async () => {
            await wipeAllData({ source: WorkoutSource.APPLE_HEALTHKIT })
          },
          style: 'destructive',
          text: 'Ja, ta bort allt',
        },
        {
          text: 'Nej, behåll träningsdata',
        },
      ])
    }
  }, [wipeAllData, requestPermissions])

  useEffect(() => {
    const init = async () => {
      const enabledSetting = await AsyncStorage.getItem(storageKey)
      const enabled = enabledSetting === 'true'
      setEnabled(enabled)
    }
    void init()
  }, [])

  return [isEnabled, onPressAppleHealthConnect, permissionStatus] as const
}

const activityTypeMapper: Record<HKWorkoutActivityType, WorkoutActivityType> = {
  [HKWorkoutActivityType.americanFootball]: WorkoutActivityType.AMERICAN_FOOTBALL,
  [HKWorkoutActivityType.archery]: WorkoutActivityType.ARCHERY,
  [HKWorkoutActivityType.australianFootball]: WorkoutActivityType.AUSTRALIAN_FOOTBALL,
  [HKWorkoutActivityType.badminton]: WorkoutActivityType.BADMINTON,
  [HKWorkoutActivityType.barre]: WorkoutActivityType.BARRE,
  [HKWorkoutActivityType.baseball]: WorkoutActivityType.BASEBALL,
  [HKWorkoutActivityType.basketball]: WorkoutActivityType.BASKETBALL,
  [HKWorkoutActivityType.bowling]: WorkoutActivityType.BOWLING,
  [HKWorkoutActivityType.boxing]: WorkoutActivityType.BOXING,
  [HKWorkoutActivityType.climbing]: WorkoutActivityType.CLIMBING,
  [HKWorkoutActivityType.coreTraining]: WorkoutActivityType.CORE_TRAINING,
  [HKWorkoutActivityType.cricket]: WorkoutActivityType.CRICKET,
  [HKWorkoutActivityType.crossCountrySkiing]: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  [HKWorkoutActivityType.crossTraining]: WorkoutActivityType.CROSS_TRAINING,
  [HKWorkoutActivityType.curling]: WorkoutActivityType.CURLING,
  [HKWorkoutActivityType.cycling]: WorkoutActivityType.CYCLING,
  [HKWorkoutActivityType.dance]: WorkoutActivityType.DANCE,
  [HKWorkoutActivityType.danceInspiredTraining]: WorkoutActivityType.DANCE_INSPIRED_TRAINING,
  [HKWorkoutActivityType.discSports]: WorkoutActivityType.DISC_SPORTS,
  [HKWorkoutActivityType.downhillSkiing]: WorkoutActivityType.DOWNHILL_SKIING,
  [HKWorkoutActivityType.elliptical]: WorkoutActivityType.ELLIPTICAL,
  [HKWorkoutActivityType.equestrianSports]: WorkoutActivityType.EQUESTRIAN_SPORTS,
  [HKWorkoutActivityType.fencing]: WorkoutActivityType.FENCING,
  [HKWorkoutActivityType.fishing]: WorkoutActivityType.FISHING,
  [HKWorkoutActivityType.fitnessGaming]: WorkoutActivityType.FITNESS_GAMING,
  [HKWorkoutActivityType.flexibility]: WorkoutActivityType.FLEXIBILITY,
  [HKWorkoutActivityType.functionalStrengthTraining]: WorkoutActivityType.FUNCTIONAL_STRENGTH_TRAINING,
  [HKWorkoutActivityType.golf]: WorkoutActivityType.GOLF,
  [HKWorkoutActivityType.gymnastics]: WorkoutActivityType.GYMNASTICS,
  [HKWorkoutActivityType.handball]: WorkoutActivityType.HANDBALL,
  [HKWorkoutActivityType.handCycling]: WorkoutActivityType.HAND_CYCLING,
  [HKWorkoutActivityType.highIntensityIntervalTraining]: WorkoutActivityType.HIGH_INTENSITY_INTERVAL_TRAINING,
  [HKWorkoutActivityType.hiking]: WorkoutActivityType.HIKING,
  [HKWorkoutActivityType.hockey]: WorkoutActivityType.HOCKEY,
  [HKWorkoutActivityType.hunting]: WorkoutActivityType.HUNTING,
  [HKWorkoutActivityType.jumpRope]: WorkoutActivityType.JUMP_ROPE,
  [HKWorkoutActivityType.kickboxing]: WorkoutActivityType.KICKBOXING,
  [HKWorkoutActivityType.lacrosse]: WorkoutActivityType.LACROSSE,
  [HKWorkoutActivityType.martialArts]: WorkoutActivityType.MARTIAL_ARTS,
  [HKWorkoutActivityType.mindAndBody]: WorkoutActivityType.MIND_AND_BODY,
  [HKWorkoutActivityType.mixedCardio]: WorkoutActivityType.MIXED_CARDIO,
  [HKWorkoutActivityType.mixedMetabolicCardioTraining]: WorkoutActivityType.MIXED_METABOLIC_CARDIO_TRAINING,
  [HKWorkoutActivityType.other]: WorkoutActivityType.OTHER,
  [HKWorkoutActivityType.paddleSports]: WorkoutActivityType.PADDLE_SPORTS,
  [HKWorkoutActivityType.pilates]: WorkoutActivityType.PILATES,
  [HKWorkoutActivityType.play]: WorkoutActivityType.PLAY,
  [HKWorkoutActivityType.preparationAndRecovery]: WorkoutActivityType.PREPARATION_AND_RECOVERY,
  [HKWorkoutActivityType.preparationAndRecovery]: WorkoutActivityType.PREPARATION_AND_RECOVERY,
  [HKWorkoutActivityType.racquetball]: WorkoutActivityType.RACQUETBALL,
  [HKWorkoutActivityType.rowing]: WorkoutActivityType.ROWING,
  [HKWorkoutActivityType.rugby]: WorkoutActivityType.RUGBY,
  [HKWorkoutActivityType.running]: WorkoutActivityType.RUNNING,
  [HKWorkoutActivityType.sailing]: WorkoutActivityType.SAILING,
  [HKWorkoutActivityType.skatingSports]: WorkoutActivityType.SKATING_SPORTS,
  [HKWorkoutActivityType.snowboarding]: WorkoutActivityType.SNOWBOARDING,
  [HKWorkoutActivityType.snowSports]: WorkoutActivityType.SNOW_SPORTS,
  [HKWorkoutActivityType.soccer]: WorkoutActivityType.SOCCER,
  [HKWorkoutActivityType.softball]: WorkoutActivityType.SOFTBALL,
  [HKWorkoutActivityType.squash]: WorkoutActivityType.SQUASH,
  [HKWorkoutActivityType.stairClimbing]: WorkoutActivityType.STAIR_CLIMBING,
  [HKWorkoutActivityType.stairs]: WorkoutActivityType.STAIRS,
  [HKWorkoutActivityType.stepTraining]: WorkoutActivityType.STEP_TRAINING,
  [HKWorkoutActivityType.surfingSports]: WorkoutActivityType.SURFING_SPORTS,
  [HKWorkoutActivityType.swimming]: WorkoutActivityType.SWIMMING,
  [HKWorkoutActivityType.tableTennis]: WorkoutActivityType.TABLE_TENNIS,
  [HKWorkoutActivityType.taiChi]: WorkoutActivityType.TAI_CHI,
  [HKWorkoutActivityType.tennis]: WorkoutActivityType.TENNIS,
  [HKWorkoutActivityType.trackAndField]: WorkoutActivityType.TRACK_AND_FIELD,
  [HKWorkoutActivityType.traditionalStrengthTraining]: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
  [HKWorkoutActivityType.volleyball]: WorkoutActivityType.VOLLEYBALL,
  [HKWorkoutActivityType.walking]: WorkoutActivityType.WALKING,
  [HKWorkoutActivityType.waterFitness]: WorkoutActivityType.WATER_FITNESS,
  [HKWorkoutActivityType.waterPolo]: WorkoutActivityType.WATER_POLO,
  [HKWorkoutActivityType.waterSports]: WorkoutActivityType.WATER_SPORTS,
  [HKWorkoutActivityType.wheelchairRunPace]: WorkoutActivityType.WHEELCHAIR_RUN_PACE,
  [HKWorkoutActivityType.wheelchairWalkPace]: WorkoutActivityType.WHEELCHAIR_WALK_PACE,
  [HKWorkoutActivityType.wrestling]: WorkoutActivityType.WRESTLING,
  [HKWorkoutActivityType.yoga]: WorkoutActivityType.YOGA,
}

export const useSyncAppleHealthData = () => {
  const [, upsertAppleWorkouts] = useUpsertAppleWorkoutsMutation()
  const [isHealthEnabled] = useControlAppleHealth()
  const [{ data: lastWorkoutData }] = useLastWorkoutQuery({ variables: { source: WorkoutSource.APPLE_HEALTHKIT } })
  const showSnackbar = useAddSnackbar()

  const syncAppleHealth = useEvent(async () => {
    if (isHealthEnabled) {
      const workouts = await Healthkit.queryWorkouts({
        from: lastWorkoutData?.lastWorkout
          ? dayjs(lastWorkoutData.lastWorkout.startDate).add(1, 'millisecond').toDate()
          : dayjs().subtract(1, 'month').toDate(),
        to: new Date(),
        energyUnit: UnitOfEnergy.Kilocalories,
        distanceUnit: UnitOfLength.Meter,
        ascending: false,
      })

      const data = await upsertAppleWorkouts({
        workouts: workouts.map((w) => ({
          activeWorkoutTimeInMilliseconds: Math.round(w.duration * 1000),
          activityType: activityTypeMapper[w.workoutActivityType] || WorkoutActivityType.OTHER,
          appleHealthkitUUID: w.uuid,
          endDate: w.endDate.toISOString(),
          startDate: w.startDate.toISOString(),
          distanceInMeters: w.totalDistance?.quantity,
        })),
      })

      if (data.data?.upsertAppleWorkouts && __DEV__) {
        void showSnackbar(`${data.data.upsertAppleWorkouts.length} träningspass synkade från Apple Hälsa`)
      }
    }
    return Promise.resolve()
  })

  return syncAppleHealth
}

export default useControlAppleHealth
