import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useEffect, useState } from 'react'

import HeaderButton from './HeaderButton'

import type { HeaderButtonProps } from './HeaderButton'

type Props = {
  readonly icon?: 'keyboard-backspace' | 'window-close'
  readonly style?: HeaderButtonProps['style']
}

const BackButton: React.FC<Props> = ({ icon = 'keyboard-backspace', style }) => {
  const navigation = useNavigation(),
        [canGoBack, setCanGoBack] = useState(false),
        isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      setCanGoBack(navigation.canGoBack())
    }
  }, [navigation, isFocused])

  return canGoBack ? (
    <HeaderButton
      icon={icon}
      style={style}
      onPress={navigation.goBack}
    />
  ) : null
}

export default BackButton
