import { Image } from 'react-native'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'

import type { PageColor } from '../contexts/Theme'
import type { FactoryProps } from '../utils/createThemedStylesHook'

const IMAGE_ASPECT_RATIO = 235 / 1252

const ACTIVITY_ICON_COLORS: Record<PageColor, string> = {
  blue: FIGMA_COLORS.ESK_BLUE_1,
  green: FIGMA_COLORS.ESK_GREEN_2,
  greenInverted: FIGMA_COLORS.ESK_GREEN_2,
  pink: FIGMA_COLORS.ESK_PINK_1,
  yellow: FIGMA_COLORS.ESK_YELLOW_4,
}

type StyleProps = {
  readonly color: PageColor,
  readonly mode: 'bottom' | 'top',
}

const useStyles = createThemedStylesHook(({ color, size: { width }, mode }: FactoryProps<StyleProps>) => {
  const actualWidth = width * 0.9
  return ({
    image: {
      width: actualWidth,
      tintColor: ACTIVITY_ICON_COLORS[color],
      top: mode === 'top' ? 90 : undefined,
      height: actualWidth * IMAGE_ASPECT_RATIO,
      alignSelf: 'center',
      position: 'absolute',
      bottom: mode === 'bottom' ? 45 : undefined,
    },
  })
})

type Props = {
  readonly mode: 'bottom' | 'top',
}

const KlassikerActivityIcons: React.FC<Props> = ({ mode = 'bottom' }) => {
  const { pageColor } = useTheme()
  const styles = useStyles({ color: pageColor, mode })

  return (
    <Image
      resizeMode='contain'
      source={require('../../assets/esk_aktivitetssymboler.png')}
      accessibilityIgnoresInvertColors
      style={styles.image}
    />
  )
}

export default KlassikerActivityIcons
