import { FlashList } from '@shopify/flash-list'
import { useCallback, useState } from 'react'
import Animated, { FadeInUp } from 'react-native-reanimated'
import { match } from 'ts-pattern'

import { UserChallengeStatus, useUserChallengesQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import Box from '../components/Box'
import FetchingComponent from '../components/FetchingComponent'
import Header from '../components/Header'
import ListEmptyComponent from '../components/ListEmptyComponent'
import { Column, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { usePublicationState } from '../contexts/EditorMode'
import { useMergePagedResults } from '../hooks/useMergePagedResults'
import useSanitizeCmsData from '../hooks/useSanitizeCmsData'
import createThemedStylesHook, { createThemedView } from '../utils/createThemedStylesHook'
import dayjs from '../utils/dayjs'
import { distanceDescription, formatNumber } from './ChallengeDetailsScreen'

import type { ESKScreen } from '../types'

const useStyles = createThemedStylesHook(({ size }) => ({
  item: {
    alignSelf: 'center',
    marginVertical: 8,
    width: size.width - 32,
  },
}))

const ListFooter = createThemedView(({ insets }) => ({
  height: 120 + insets.bottom,
}))

const ChallengeListingScreen: ESKScreen<'ChallengeListingScreen'> = ({ navigation }) => {
  const publicationState = usePublicationState(),
        [page, setPage] = useState(1),
        [{ data: queryData, fetching }, fetch] = useUserChallengesQuery({ variables: { page, publicationState } }),
        [pagedResults, { refetch, fetchMore }] = useMergePagedResults(queryData?.userChallenges, { setPage, fetching, fetch }),
        data = useSanitizeCmsData(pagedResults),
        styles = useStyles()

  const onPress = useCallback((id: string) => () => {
    navigation.navigate('ChallengeDetailsScreen', { id })
  }, [navigation])

  return (
    <Column fill>
      <Background activityIcons='none' />

      <HeaderSpacer />
      <FlashList
        data={data}
        refreshControl={<RefetchControl onRefetch={refetch} />}
        keyExtractor={(item) => item.id!}
        onEndReached={fetchMore}
        estimatedItemSize={100}
        renderItem={({
          index, item: {
            id, attributes: {
              headerImage, title, tintColor, userStatus, userProgress, goalAmount, startDate, endDate, goalType,
            },
          },
        }) => {
          const subtitle = match({ userStatus, hasStarted: new Date(startDate) < new Date(), hasCompletedChallenge: userProgress >= goalAmount })
            .with({ userStatus: UserChallengeStatus.HAS_JOINED, hasCompletedChallenge: true }, () => 'Klar!')
            .with({ hasStarted: false }, () => `Börjar ${dayjs(startDate).fromNow()}`)
            .with({ userStatus: UserChallengeStatus.CAN_JOIN }, () => 'Gå med nu')
            .otherwise(() => `${formatNumber(goalType, goalAmount - userProgress)} ${distanceDescription(goalType)} kvar`)
          return (
            <Animated.View
              entering={FadeInUp.delay(index * 100)}
              style={[styles.item, new Date(endDate).valueOf() < Date.now() ? { opacity: 0.7 } : {}]}
            >
              <Box
                title={title}
                uri={headerImage?.data?.attributes?.url}
                chevron
                icon='trophy'
                iconColor={userProgress >= goalAmount ? tintColor : '#ccc'}
                subtitle={subtitle}
                onPress={onPress(id)}
              />
            </Animated.View>
          )
        }}
        ListEmptyComponent={fetching ? <FetchingComponent /> : <ListEmptyComponent refetch={refetch} />}
        ListHeaderComponent={<Column height={16} />}
        ListFooterComponent={ListFooter}
      />
      <Header right='settings' />
    </Column>
  )
}

export default ChallengeListingScreen
