import { GenericError } from '@kingstinct/react'
import { Column } from '@kingstinct/react/primitives'
import { useNavigation } from '@react-navigation/native'
import { FlashList } from '@shopify/flash-list'
import React, { useCallback, useMemo, useState } from 'react'
import { ActivityIndicator, List } from 'react-native-paper'

import { useUserWithFitnessDataQuery } from '../clients/backend.generated'
import { useTheme } from '../contexts/Theme'
import { KlassikerWorkoutActivityTypeMappings, WorkoutActivityTypeNames } from '../types'
import dayjs from '../utils/dayjs'
import Background from './Background'
import Header from './Header'
import KlassikerActivityIcon from './KlassikerActivityIcon'
import { BottomSpacer, Row } from './Primitives'
import RefetchControl from './RefetchControl'
import { oneDecimal } from './WorkoutTracker'

const WorkoutHistoryList = () => {
  const [after, setAfter] = useState<string>()
  const navigation = useNavigation()
  const [{ data: userData, fetching: fetchingData, error }, fetch] = useUserWithFitnessDataQuery({
    variables: { after, first: 10 },
  })
  const theme = useTheme()

  const listData = useMemo(() => userData?.me.workouts.edges.map(({ node }) => node), [userData?.me.workouts.edges])

  const onPress = useCallback((item: NonNullable<typeof listData>[number]) => () => {
    navigation.navigate('WorkoutDetailsPage', { workoutId: item._id, pageColor: theme.pageColor, activityType: item.activityType })
  }, [navigation, theme.pageColor])

  const renderItem = useCallback(({ item }: { readonly item: NonNullable<typeof listData>[0] }) => {
    const source = item.appleHealthkitUUID ? ' (Apple Hälsa)' : (item.googleFitId ? ' (Google Fit)' : '')
    const description = dayjs(item.endDate).fromNow() + source

    const distance = item.distanceInMeters ? ` ${oneDecimal(item.distanceInMeters / 1000)} km` : ''

    const title = `${WorkoutActivityTypeNames[item.activityType]}${distance}`
    const right = () => <List.Icon icon='chevron-right' />
    const left = () => {
      const type = KlassikerWorkoutActivityTypeMappings[item.activityType]

      return type == null ? <Row width={48} /> : (
        <Row centerY marginLeft={12} width={36}>
          <KlassikerActivityIcon color={theme.colors.primary} type={type} />
        </Row>
      )
    }

    return (
      <List.Item
        onPress={onPress(item)}
        left={left}
        title={title}
        description={description}
        right={right}

      />
    )
  }, [onPress, theme.colors.primary])

  if (!listData) {
    return fetchingData ? <ActivityIndicator /> : <GenericError error={error} onTryAgain={fetch} />
  }

  return (
    <Column fill>
      <Background />

      <FlashList
        data={listData}
        refreshControl={(
          <RefetchControl onRefetch={() => {
            setAfter(undefined)
            fetch()
          }}
          />
        )}
        estimatedItemSize={72} // measured with onLayout
        onEndReached={() => {
          if (!fetchingData && userData?.me.workouts.pageInfo.hasNextPage && userData.me.workouts.pageInfo.endCursor) {
            setAfter(userData.me.workouts.pageInfo.endCursor)
          }
        }}
        renderItem={renderItem}
        ListHeaderComponent={<Column height={180} />}
        ListFooterComponent={BottomSpacer}
      />

      <Header right='settings' title='Träningshistorik' />
    </Column>
  )
}

export default WorkoutHistoryList
