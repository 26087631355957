import { FlashList } from '@shopify/flash-list'
import React from 'react'
import Animated, { FadeInUp } from 'react-native-reanimated'

import { CmsEnum_Componentmessagespage_Page as BannerPage, useChallengeQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import Banners from '../components/Banners'
import CmsContentComponent from '../components/CmsContentComponent'
import FetchingComponent from '../components/FetchingComponent'
import Header from '../components/Header'
import ListEmptyComponent from '../components/ListEmptyComponent'
import {
  BottomSpacer, Column, HeaderSpacer, Overlay,
} from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { filterEmpty } from '../types'

const ChallangeScreen: React.FC = () => {
  const [{ data, fetching, error }, refetch] = useChallengeQuery({ requestPolicy: 'cache-and-network' })

  return (
    <Column fill colorize width='100%' height='100%'>
      <Background activityIcons='none' />

      { fetching && !data ? <FetchingComponent />
        : (
          <FlashList
            refreshControl={<RefetchControl onRefetch={refetch} />}
            data={filterEmpty(data?.challenge?.data?.attributes?.content || [])}
            estimatedItemSize={154}
            ListEmptyComponent={(
              <ListEmptyComponent
                isError={!!error}
                refetch={refetch}
              />
            )}
            renderItem={({ item, index }) => (
              <Column centerX>
                <Animated.View entering={FadeInUp.delay(index * 256)}>
                  <CmsContentComponent data={item} />
                </Animated.View>
                <Column height={16} />
              </Column>

            )}
            ListFooterComponent={BottomSpacer}
            ListHeaderComponent={(
              <>
                <HeaderSpacer />
                <Banners bannerPage={BannerPage.ChallengeTab} />
              </>
            )}
          />
        ) }
      <Overlay />
      <Header right='settings' />
    </Column>
  )
}

export default ChallangeScreen
