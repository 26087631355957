import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import React from 'react'
import { Text } from 'react-native-paper'
import { PressableOpacity } from 'react-native-pressable-opacity'
import { Shadow } from 'react-native-shadow-2'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import Styles, { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import {
  Column, Row,
} from './Primitives'

import type { PropsWithChildren } from 'react'

const useStyles = createThemedStylesHook(() => ({
  menuItemTitle: {
    fontSize: 18, color: FIGMA_COLORS.ESK_GRAY_1, fontWeight: '700', alignSelf: 'center',
  },
  menuItemRow: {
    backgroundColor: FIGMA_COLORS.ESK_GREEN_3,
    elevation: 1,
    borderRadius: 5,
    shadowColor: 'black',
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
}))

type MenuItemProps = PropsWithChildren<{
  readonly title: string,
  readonly onPress?: () => void,
}>

const MenuItem: React.FC<MenuItemProps> = ({ title, onPress, children }) => {
  const styles = useStyles()
  const theme = useTheme()
  return (
    <PressableOpacity accessibilityRole='button' onPress={onPress}>

      <Row margin={5} marginX={20} fill>
        <Column fill>
          <Shadow
            style={[Styles.width100pct, Styles.borderRadius4]}
            distance={2}
            offset={DEFAULT_SHADOW_OFFSET}
          >
            <Row
              spaceBetween
              padding={20}
              borderRadius={5}
              fill
              backgroundColor={theme.colors.surface}
            >
              <Text style={styles.menuItemTitle}>{title}</Text>
              { children || <MaterialCommunityIcons name='chevron-right' size={20} /> }

            </Row>

          </Shadow>
        </Column>
      </Row>

    </PressableOpacity>
  )
}

export default MenuItem
