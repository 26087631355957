import { Row } from '@kingstinct/react'
import React from 'react'
import { StyleSheet } from 'react-native'
import MapView, { Polyline } from 'react-native-maps'

import { FIGMA_COLORS } from '../contexts/Theme'

import type { Coord } from '../utils/reduceCoordinatesToMeters'
import type { MapViewProps } from 'react-native-maps'

const styles = StyleSheet.create({
  mapView: {
    height: 300,
    width: 300,
    borderRadius: 10,
    borderColor: 'white',
    borderWidth: 3,
  },
  mapViewWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.00,
    elevation: 1,
    borderRadius: 10,
  },
})

interface WorkoutMapViewProps extends MapViewProps {
  readonly coords: ReadonlyArray<Pick<Coord, 'latitude' | 'longitude'>>
}

const WorkoutMapView: React.FC<WorkoutMapViewProps> = ({ coords, ...mapViewProps }) => (
  <Row backgroundColor='white' centerX padding={10} style={styles.mapViewWrapper}>
    <MapView
      style={styles.mapView}
      loadingIndicatorColor={FIGMA_COLORS.ESK_GREEN_1}
      zoomEnabled={false}
      pitchEnabled={false}
      rotateEnabled={false}
      scrollEnabled={false}
      zoomTapEnabled={false}
      zoomControlEnabled={false}
      tintColor={FIGMA_COLORS.ESK_GREEN_1}
      {...mapViewProps}
    >
      <Polyline
        strokeColor={FIGMA_COLORS.ESK_GREEN_1}
        strokeWidth={2}
        coordinates={coords.map((c) => ({ latitude: c.latitude, longitude: c.longitude }))}
      />
    </MapView>
  </Row>
)

export default WorkoutMapView
