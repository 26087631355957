import { useEvent } from '@kingstinct/react'
import Healthkit, {
  HKAuthorizationRequestStatus, HKQuantityTypeIdentifier, HKStatisticsOptions,
} from '@kingstinct/react-native-healthkit'
import { useEffect, useState } from 'react'

import { useIsHealthkitAvailable, authorizationsToRequest } from './appleHealth'
import useAppleHealthAuthorizationStatus from './useAppleHealthAuthorizationStatus'

import type { Coord } from '../utils/reduceCoordinatesToMeters'
import type { HKUnit } from '@kingstinct/react-native-healthkit'

const useGetAppleWorkoutCoords = (workoutHealthkitUuid: string | null | undefined) => {
  const [coords, setCoords] = useState<readonly Coord[] | null>(null)
  const [permissions, requestPermissions] = useAppleHealthAuthorizationStatus(authorizationsToRequest)
  const isHealthKitAvailable = useIsHealthkitAvailable()

  const updateCoords = useEvent(async (workoutHealthkitUUID: string) => {
    if (permissions === HKAuthorizationRequestStatus.shouldRequest) {
      await requestPermissions()
    }
    const routes = await Healthkit.getWorkoutRoutes(workoutHealthkitUUID)
    const c = routes.flatMap<Coord>(({ locations }) => locations)
    setCoords(c)
  })

  useEffect(() => {
    if (workoutHealthkitUuid && isHealthKitAvailable) {
      void updateCoords(workoutHealthkitUuid)
    }
  }, [isHealthKitAvailable, workoutHealthkitUuid, updateCoords])

  return coords
}

export const useGetAppleHeartrate = (workoutStart: Date, workoutEnd: Date, shouldFetch: boolean) => {
  const [coords, setCoords] = useState<{ readonly heartRateHigh?: number, readonly heartRateLow?: number, readonly heartRateAverage?: number } | null>(null)
  const [permissions, requestPermissions] = useAppleHealthAuthorizationStatus(authorizationsToRequest)
  const isHealthKitAvailable = useIsHealthkitAvailable()

  const updateCoords = useEvent(async () => {
    if (permissions === HKAuthorizationRequestStatus.shouldRequest) {
      await requestPermissions()
    }
    const heartRate = await Healthkit.queryStatisticsForQuantity(HKQuantityTypeIdentifier.heartRate, [
      HKStatisticsOptions.discreteMax,
      HKStatisticsOptions.discreteMin,
      HKStatisticsOptions.discreteAverage,
    ], workoutStart, workoutEnd, 'count/min')

    setCoords({
      heartRateAverage: heartRate.averageQuantity?.quantity,
      heartRateHigh: heartRate.maximumQuantity?.quantity,
      heartRateLow: heartRate.minimumQuantity?.quantity,
    })
  })

  useEffect(() => {
    if (isHealthKitAvailable && shouldFetch) {
      void updateCoords()
    }
  }, [isHealthKitAvailable, updateCoords, shouldFetch])

  return coords
}

export default useGetAppleWorkoutCoords
