import React from 'react'
import { Image } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { Title } from 'react-native-paper'

import { useContentPageByIdQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import CmsContent from '../components/CmsContent'
import CmsLabelsComponent from '../components/CmsLabelsComponent'
import Header from '../components/Header'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import { FIGMA_COLORS } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'

import type { ESKScreen } from '../types'

const useStyles = createThemedStylesHook(({ theme }) => ({
  image: { width: '100%', height: 221 },
  labelDivider: { width: 2, height: '100%', backgroundColor: theme.colors.accent },
  labelSubtitle: { textAlign: 'center', fontSize: 10, fontWeight: '700' },
  labelTitle: { textAlign: 'center', fontSize: 14 },
  title: {
    color: FIGMA_COLORS.GRAY_2, fontSize: 28, fontWeight: '700', marginTop: 24, textAlign: 'center',
  },
}))

const ContentPageScreen: ESKScreen<'ContentPage'> = ({ route }) => {
  const styles = useStyles()

  const [{ data }, refetch] = useContentPageByIdQuery({
    variables: {
      id: route.params.id,
    },
  })

  const contentPage = data?.contentPage?.data

  return (
    <Column fill>
      <Background activityIcons='none' />

      <ScrollView
        refreshControl={(
          <RefetchControl
            onRefetch={refetch}
          />
        )}
      >
        <HeaderSpacer />
        <Image
          source={{ uri: contentPage?.attributes?.headerImage.data?.attributes?.url }}
          accessibilityIgnoresInvertColors
          style={styles.image}
        />
        <CmsLabelsComponent labels={contentPage?.attributes?.labels} />
        <Title style={styles.title}>
          {contentPage?.attributes?.title}
        </Title>
        <Column paddingX={30} paddingTop={12}>
          <CmsContent animate data={contentPage?.attributes?.content} keyPrefix={contentPage?.id} />
        </Column>
        <BottomSpacer />
      </ScrollView>
      <Header
        backIcon='keyboard-backspace'
        right='settings'
      />
    </Column>
  )
}

export default ContentPageScreen
