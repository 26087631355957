import { Portal } from '@gorhom/portal'
import { AuthContext, useNullableState } from '@kingstinct/react'
import useAppState from '@kingstinct/react/hooks/useAppState'
import usePrevious from '@kingstinct/react/hooks/usePrevious'
import { useNavigation } from '@react-navigation/native'
import React, {
  useCallback, useContext, useEffect,
} from 'react'
import {
  Image, Linking, Platform, StyleSheet,
} from 'react-native'
import { Snackbar } from 'react-native-paper'

import { useLoginWithBankIdMutation, useStartBankIdMutation } from '../clients/backend.generated'
import { APP_SCHEME } from '../env'
import Styles from '../utils/Styles'
import RoundedButton from './RoundedButton'

const styles = StyleSheet.create({
  bankIdLogo: {
    width: 50,
    height: 50,
  },
})

const BankIdButton: React.FC = () => {
  const [{ fetching: isLoggingIn, error: loginError }, loginWithBankId] = useLoginWithBankIdMutation(),
        [{ data: startBankIdData, fetching: isStartingBankId, error: startBankIdError }, startBankId] = useStartBankIdMutation(),
        { setToken } = useContext(AuthContext),
        appState = useAppState(),
        navigation = useNavigation(),
        previousAppState = usePrevious(appState),
        [showError, setShowError, unsetShowError] = useNullableState<string>()

  useEffect(() => {
    if (loginError) {
      setShowError(loginError.message)
    }
  }, [loginError, setShowError])

  useEffect(() => {
    if (startBankIdError) {
      setShowError(startBankIdError.message)
    }
  }, [setShowError, startBankIdError])

  const onSignupwithBankId = useCallback(() => {
    void startBankId({
      appUrlSchemeCallback: Platform.OS === 'ios' ? `${APP_SCHEME}://` : undefined, // on Android URL scheme is not needed
    }).then(({ data }) => {
      if (data?.startBankId.__typename === 'BankIdSessionInitiated') {
        void Linking.openURL(data.startBankId.redirectUrl)
      } else if (data?.startBankId.__typename === 'AuthenticationFailure') {
        setShowError(data.startBankId.message)
      }
    })
  }, [setShowError, startBankId])

  useEffect(() => {
    if (startBankIdData?.startBankId.__typename === 'BankIdSessionInitiated' && appState === 'active' && previousAppState !== appState && !isLoggingIn) {
      void loginWithBankId({ sessionId: startBankIdData.startBankId.sessionId }).then(({ data }) => {
        if (data?.loginWithBankId.__typename === 'AuthenticationSuccess') {
          setToken(data.loginWithBankId.token)
        } else if (data?.loginWithBankId.__typename === 'AuthenticationFailure') {
          setShowError(data.loginWithBankId.message)
        } else if (data?.loginWithBankId.__typename === 'AuthenticationMoreInfoRequired') {
          navigation.navigate('SignupBankIdScreen', data.loginWithBankId)
        }
      })
    }
  }, [
    appState, startBankIdData, previousAppState, loginWithBankId, isLoggingIn, setToken, navigation, setShowError,
  ])

  return (
    <>
      <RoundedButton
        onPress={onSignupwithBankId}
        title='Fortsätt med BankID'
        titleColor='#183E4F'
        backgroundColor='white'
        style={Styles.margin4}
        isLoading={isLoggingIn || isStartingBankId}
        icon={(
          <Image
            source={require('../../assets/bankid_logo.png')}
            style={styles.bankIdLogo}
            accessibilityIgnoresInvertColors
          />
        )}
      />
      <Portal>
        <Snackbar
          onDismiss={unsetShowError}
          visible={!!showError}
        >
          {showError}

        </Snackbar>
      </Portal>
    </>
  )
}

export default BankIdButton
