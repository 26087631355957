import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { Banner } from 'react-native-paper'

import {
  useSetShowConnectWorkoutTrackingMutation, useSyncInfoQuery,
} from '../clients/backend.generated'
import { useTheme } from '../contexts/Theme'

const styles = StyleSheet.create({
  banner: { marginTop: 90 },
})

const HealthConnectionBanner: React.FC = () => {
  const [, setShowConnectWorkoutTracking] = useSetShowConnectWorkoutTrackingMutation(),
        [{ data }] = useSyncInfoQuery(),
        bannerVisible = data?.me.__typename === 'User' ? data.me.showConnectWorkoutTracking : false,
        theme = useTheme(),
        navigation = useNavigation()

  const actions = useMemo(() => ([
    {
      label: 'Senare',
      onPress: () => {
        void setShowConnectWorkoutTracking({ shouldBeVisible: false })
      },
    },
    {
      label: 'Koppla nu',
      onPress: () => {
        void navigation.navigate('HealthConnections', { pageColor: theme.pageColor })
        void setShowConnectWorkoutTracking({ shouldBeVisible: false })
      },
    },
  ]), [setShowConnectWorkoutTracking, theme, navigation])

  return (
    <Banner
      icon='watch'
      style={styles.banner}
      actions={actions}
      visible={bannerVisible}
    >
      Använder du en smartklocka eller en annan app när du tränar? Koppla den för att automatiskt hämta din träning.
    </Banner>
  )
}

export default HealthConnectionBanner
