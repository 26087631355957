import { getBounds } from 'geolib'

import type { Region } from 'react-native-maps'

const getRegion = (coordinates: ReadonlyArray<{readonly latitude: number, readonly longitude: number}>) => {
  if (coordinates.length === 0) {
    return {
      latitudeDelta: 0, longitudeDelta: 0, latitude: 0, longitude: 0,
    }
  }
  const {
    minLat, maxLat, minLng, maxLng,
  // eslint-disable-next-line functional/prefer-readonly-type
  } = getBounds(coordinates as any[]) as { readonly minLat: number, readonly maxLat: number, readonly minLng: number, readonly maxLng: number }
  const region: Region = {
    latitude: (minLat + maxLat) / 2,
    longitude: (minLng + maxLng) / 2,
    latitudeDelta: Math.max(1.5 * (maxLat - minLat), 0.005),
    longitudeDelta: Math.max(1.5 * (maxLng - minLng), 0.005),
  }
  return region
}

export default getRegion
