import { NationalityCode, WorkoutActivityType } from './clients/backend.generated'

import type { AuthMoreInfoRequiredFragment, SignupInput } from './clients/backend.generated'
import type { PageColor } from './contexts/Theme'
import type { NavigatorScreenParams } from '@react-navigation/native'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'

export function nonEmptyPredicate<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined
}

export function filterEmpty<TValue>(arr: readonly (TValue | null | undefined)[] | false | null | undefined) {
  return arr ? arr.filter(nonEmptyPredicate) : []
}

export type NewsTabParamList = {
  readonly SkiingNewsTab: undefined
  readonly BikingNewsTab: undefined
  readonly SwimmingNewsTab: undefined
  readonly RunningNewsTab: undefined
}

export type WorkoutTabParamList = {
  readonly SkiingWorkoutTab: undefined
  readonly BikingWorkoutTab: undefined
  readonly SwimmingWorkoutTab: undefined
  readonly RunningWorkoutTab: undefined
  readonly StrengthWorkoutTab: undefined
}

export type SharedStackParamList = {
  readonly NewsListing: NavigatorScreenParams<NewsTabParamList>
  readonly NewsArticle: { readonly id: string }
  readonly NutritionListing: undefined
  readonly NutritionArticle: { readonly id: string }
  readonly WorkoutListing: undefined
  readonly WorkoutArticle: { readonly id: string }
  readonly ContentPage: { readonly id: string }
  readonly ChallengeListingScreen: undefined
  readonly ChallengeDetailsScreen: { readonly id: string }

  readonly WorkoutHistory: undefined
}

export type HomeStackParamList = SharedStackParamList & {
  readonly HomeScreen: undefined
}

export type ChallengeStackParamList = SharedStackParamList & {
  readonly ChallengeScreen: undefined
}

export type StartStackParamList = SharedStackParamList & {
  readonly StartScreen: { readonly workoutActivityType: WorkoutActivityType } | undefined
  readonly WorkoutActivityTypeSelectionScreen: { readonly callbackScreen: keyof Pick<StartStackParamList, 'StartScreen'> }
}

export type RaceStackParamList = SharedStackParamList & {
  readonly RaceScreen: { readonly raceId?: string } | undefined
}

export type ShareStackParamList = SharedStackParamList & {
  readonly ShareScreen: undefined
}

export type TabParamList = {
  readonly HomeTab: NavigatorScreenParams<HomeStackParamList>
  readonly ChallengeTab: NavigatorScreenParams<ChallengeStackParamList>
  readonly StartTab: NavigatorScreenParams<StartStackParamList>
  readonly RaceTab: NavigatorScreenParams<RaceStackParamList>
  readonly ShareTab: NavigatorScreenParams<ShareStackParamList>
}

export type AuthStackParamList = {
  readonly AuthStartScreen: undefined
  readonly LoginScreen: undefined
  readonly NationalityCodeSelectionScreen: { readonly callbackScreen: keyof Pick<AuthStackParamList, 'SignupBankIdScreen' | 'SignupScreen'> }
  readonly SignupScreen: Partial<Pick<SignupInput, 'nationalityCode'>> | undefined
  readonly SignupBankIdScreen: Pick<AuthMoreInfoRequiredFragment, 'email' | 'firstName' | 'languageCode' | 'lastName' | 'nationalityCode' | 'personalNumber' | 'sessionId'>
}

export type ModalParamList = {
  readonly Main: NavigatorScreenParams<TabParamList>
  readonly Settings: { readonly pageColor: PageColor }
  readonly Profile: { readonly pageColor: PageColor }
  readonly HealthConnections: { readonly pageColor: PageColor }
  readonly WorkoutDetailsPage: { readonly workoutId: string, readonly pageColor: PageColor, readonly activityType: WorkoutActivityType }
}

export type ESKRootParamList = AuthStackParamList & ModalParamList & SharedStackParamList & TabParamList

declare global {
  namespace ReactNavigation {
    interface RootParamList extends ESKRootParamList {}
  }
}

export type GraphQLErrorExtensions = {
  readonly code: 'UNAUTHENTICATED'
}

export type ESKScreen<RouteName extends keyof ESKRootParamList> = React.FC<NativeStackScreenProps<ESKRootParamList, RouteName>>

export type SanitizedCmsType<TType extends { readonly __typename: string, readonly id?: string | null, readonly attributes?: unknown | null }> = {
  readonly __typename: TType['__typename']
  readonly id: string
  readonly attributes: NonNullable<TType['attributes']>
}

export const CountryNames: Record<NationalityCode, string> = {
  [NationalityCode.AD]: 'Andorra',
  [NationalityCode.AE]: 'Förenade Arabemiraten',
  [NationalityCode.AF]: 'Afghanistan',
  [NationalityCode.AG]: 'Antigua och Barbuda',
  [NationalityCode.AI]: 'Anguilla',
  [NationalityCode.AL]: 'Albanien',
  [NationalityCode.AM]: 'Armenien',
  [NationalityCode.AO]: 'Angola',
  [NationalityCode.AQ]: 'Antarktis',
  [NationalityCode.AR]: 'Argentina',
  [NationalityCode.AS]: 'Amerikanska Samoa',
  [NationalityCode.AT]: 'Österrike',
  [NationalityCode.AU]: 'Australien',
  [NationalityCode.AW]: 'Aruba',
  [NationalityCode.AX]: 'Åland',
  [NationalityCode.AZ]: 'Azerbajdzjan',
  [NationalityCode.BA]: 'Bosnien och Hercegovina',
  [NationalityCode.BB]: 'Barbados',
  [NationalityCode.BD]: 'Bangladesh',
  [NationalityCode.BE]: 'Belgien',
  [NationalityCode.BF]: 'Burkina Faso',
  [NationalityCode.BG]: 'Bulgarien',
  [NationalityCode.BH]: 'Bahrain',
  [NationalityCode.BI]: 'Burundi',
  [NationalityCode.BJ]: 'Benin',
  [NationalityCode.BL]: 'Saint-Barthélemy',
  [NationalityCode.BM]: 'Bermuda',
  [NationalityCode.BN]: 'Brunei',
  [NationalityCode.BO]: 'Bolivia',
  [NationalityCode.BQ]: 'Bonaire, Sint Eustatius and Saba',
  [NationalityCode.BR]: 'Brasilien',
  [NationalityCode.BS]: 'Bahamas',
  [NationalityCode.BT]: 'Bhutan',
  [NationalityCode.BV]: 'Bouvetön',
  [NationalityCode.BW]: 'Botswana',
  [NationalityCode.BY]: 'Belarus',
  [NationalityCode.BZ]: 'Belize',
  [NationalityCode.CA]: 'Kanada',
  [NationalityCode.CC]: 'Kokosöarna',
  [NationalityCode.CD]: 'Kongo-Kinshasa',
  [NationalityCode.CF]: 'Centralafrikanska republiken',
  [NationalityCode.CG]: 'Kongo-Brazzaville',
  [NationalityCode.CH]: 'Schweiz',
  [NationalityCode.CI]: 'Elfenbenskusten',
  [NationalityCode.CK]: 'Cooköarna',
  [NationalityCode.CL]: 'Chile',
  [NationalityCode.CM]: 'Kamerun',
  [NationalityCode.CN]: 'Kina',
  [NationalityCode.CO]: 'Colombia',
  [NationalityCode.CR]: 'Costa Rica',
  [NationalityCode.CU]: 'Kuba',
  [NationalityCode.CV]: 'Kap Verde',
  [NationalityCode.CW]: 'Curaçao',
  [NationalityCode.CX]: 'Julön',
  [NationalityCode.CY]: 'Cypern',
  [NationalityCode.CZ]: 'Tjeckien',
  [NationalityCode.DE]: 'Tyskland',
  [NationalityCode.DJ]: 'Djibouti',
  [NationalityCode.DK]: 'Danmark',
  [NationalityCode.DM]: 'Dominica',
  [NationalityCode.DO]: 'Dominikanska republiken',
  [NationalityCode.DZ]: 'Algeriet',
  [NationalityCode.EC]: 'Ecuador',
  [NationalityCode.EE]: 'Estland',
  [NationalityCode.EG]: 'Egypten',
  [NationalityCode.EH]: 'Västsahara',
  [NationalityCode.ER]: 'Eritrea',
  [NationalityCode.ES]: 'Spanien',
  [NationalityCode.ET]: 'Etiopien',
  [NationalityCode.FI]: 'Finland',
  [NationalityCode.FJ]: 'Fiji',
  [NationalityCode.FK]: 'Falklandsöarna',
  [NationalityCode.FM]: 'Mikronesiska federationen',
  [NationalityCode.FO]: 'Färöarna',
  [NationalityCode.FR]: 'Frankrike',
  [NationalityCode.GA]: 'Gabon',
  [NationalityCode.GB]: 'Storbritannien',
  [NationalityCode.GD]: 'Grenada',
  [NationalityCode.GE]: 'Georgien',
  [NationalityCode.GF]: 'Franska Guyana',
  [NationalityCode.GG]: 'Guernsey',
  [NationalityCode.GH]: 'Ghana',
  [NationalityCode.GI]: 'Gibraltar',
  [NationalityCode.GL]: 'Grönland',
  [NationalityCode.GM]: 'Gambia',
  [NationalityCode.GN]: 'Guinea',
  [NationalityCode.GP]: 'Guadeloupe',
  [NationalityCode.GQ]: 'Ekvatorialguinea',
  [NationalityCode.GR]: 'Grekland',
  [NationalityCode.GS]: 'Sydgeorgien',
  [NationalityCode.GT]: 'Guatemala',
  [NationalityCode.GU]: 'Guam',
  [NationalityCode.GW]: 'Guinea-Bissau',
  [NationalityCode.GY]: 'Guyana',
  [NationalityCode.HK]: 'Hongkong',
  [NationalityCode.HM]: 'Heard- och McDonaldöarna',
  [NationalityCode.HN]: 'Honduras',
  [NationalityCode.HR]: 'Kroatien',
  [NationalityCode.HT]: 'Haiti',
  [NationalityCode.HU]: 'Ungern',
  [NationalityCode.ID]: 'Indonesien',
  [NationalityCode.IE]: 'Irland',
  [NationalityCode.IL]: 'Israel',
  [NationalityCode.IM]: 'Isle of Man',
  [NationalityCode.IN]: 'Indien',
  [NationalityCode.IO]: 'Brittiska territoriet i Indiska Oceanen',
  [NationalityCode.IQ]: 'Irak',
  [NationalityCode.IR]: 'Iran',
  [NationalityCode.IS]: 'Island',
  [NationalityCode.IT]: 'Italien',
  [NationalityCode.JE]: 'Jersey',
  [NationalityCode.JM]: 'Jamaica',
  [NationalityCode.JO]: 'Jordanien',
  [NationalityCode.JP]: 'Japan',
  [NationalityCode.KE]: 'Kenya',
  [NationalityCode.KG]: 'Kirgizistan',
  [NationalityCode.KH]: 'Kambodja',
  [NationalityCode.KI]: 'Kiribati',
  [NationalityCode.KM]: 'Komorerna',
  [NationalityCode.KN]: 'Saint Kitts och Nevis',
  [NationalityCode.KP]: 'Nordkorea',
  [NationalityCode.KR]: 'Sydkorea',
  [NationalityCode.KW]: 'Kuwait',
  [NationalityCode.KY]: 'Caymanöarna',
  [NationalityCode.KZ]: 'Kazakstan',
  [NationalityCode.LA]: 'Laos',
  [NationalityCode.LB]: 'Libanon',
  [NationalityCode.LC]: 'Saint Lucia',
  [NationalityCode.LI]: 'Liechtenstein',
  [NationalityCode.LK]: 'Sri Lanka',
  [NationalityCode.LR]: 'Liberia',
  [NationalityCode.LS]: 'Lesotho',
  [NationalityCode.LT]: 'Litauen',
  [NationalityCode.LU]: 'Luxemburg',
  [NationalityCode.LV]: 'Lettland',
  [NationalityCode.LY]: 'Libyen',
  [NationalityCode.MA]: 'Marocko',
  [NationalityCode.MC]: 'Monaco',
  [NationalityCode.MD]: 'Moldavien',
  [NationalityCode.ME]: 'Montenegro',
  [NationalityCode.MF]: 'Saint-Martin',
  [NationalityCode.MG]: 'Madagaskar',
  [NationalityCode.MH]: 'Marshallöarna',
  [NationalityCode.MK]: 'Nordmakedonien',
  [NationalityCode.ML]: 'Mali',
  [NationalityCode.MM]: 'Myanmar',
  [NationalityCode.MN]: 'Mongoliet',
  [NationalityCode.MO]: 'Macao',
  [NationalityCode.MP]: 'Nordmarianerna',
  [NationalityCode.MQ]: 'Martinique',
  [NationalityCode.MR]: 'Mauretanien',
  [NationalityCode.MS]: 'Montserrat',
  [NationalityCode.MT]: 'Malta',
  [NationalityCode.MU]: 'Mauritius',
  [NationalityCode.MV]: 'Maldiverna',
  [NationalityCode.MW]: 'Malawi',
  [NationalityCode.MX]: 'Mexiko',
  [NationalityCode.MY]: 'Malaysia',
  [NationalityCode.MZ]: 'Moçambique',
  [NationalityCode.NA]: 'Namibia',
  [NationalityCode.NC]: 'Nya Kaledonien',
  [NationalityCode.NE]: 'Niger',
  [NationalityCode.NF]: 'Norfolkön',
  [NationalityCode.NG]: 'Nigeria',
  [NationalityCode.NI]: 'Nicaragua',
  [NationalityCode.NL]: 'Nederländerna',
  [NationalityCode.NO]: 'Norge',
  [NationalityCode.NP]: 'Nepal',
  [NationalityCode.NR]: 'Nauru',
  [NationalityCode.NU]: 'Niue',
  [NationalityCode.NZ]: 'Nya Zeeland',
  [NationalityCode.OM]: 'Oman',
  [NationalityCode.PA]: 'Panama',
  [NationalityCode.PE]: 'Peru',
  [NationalityCode.PF]: 'Franska Polynesien',
  [NationalityCode.PG]: 'Papua Nya Guinea',
  [NationalityCode.PH]: 'Filippinerna',
  [NationalityCode.PK]: 'Pakistan',
  [NationalityCode.PL]: 'Polen',
  [NationalityCode.PM]: 'Saint-Pierre och Miquelon',
  [NationalityCode.PN]: 'Pitcairnöarna',
  [NationalityCode.PR]: 'Puerto Rico',
  [NationalityCode.PS]: 'Palestina',
  [NationalityCode.PT]: 'Portugal',
  [NationalityCode.PW]: 'Palau',
  [NationalityCode.PY]: 'Paraguay',
  [NationalityCode.QA]: 'Qatar',
  [NationalityCode.RE]: 'Réunion',
  [NationalityCode.RO]: 'Rumänien',
  [NationalityCode.RS]: 'Serbien',
  [NationalityCode.RU]: 'Ryssland',
  [NationalityCode.RW]: 'Rwanda',
  [NationalityCode.SA]: 'Saudiarabien',
  [NationalityCode.SB]: 'Salomonöarna',
  [NationalityCode.SC]: 'Seychellerna',
  [NationalityCode.SD]: 'Sudan',
  [NationalityCode.SE]: 'Sverige',
  [NationalityCode.SG]: 'Singapore',
  [NationalityCode.SH]: 'Sankta Helena',
  [NationalityCode.SI]: 'Slovenien',
  [NationalityCode.SJ]: 'Svalbard och Jan Mayen',
  [NationalityCode.SK]: 'Slovakien',
  [NationalityCode.SL]: 'Sierra Leone',
  [NationalityCode.SM]: 'San Marino',
  [NationalityCode.SN]: 'Senegal',
  [NationalityCode.SO]: 'Somalia',
  [NationalityCode.SR]: 'Surinam',
  [NationalityCode.SS]: 'Sydsudan',
  [NationalityCode.ST]: 'São Tomé och Príncipe',
  [NationalityCode.SV]: 'El Salvador',
  [NationalityCode.SX]: 'Sint Maarten',
  [NationalityCode.SY]: 'Syrien',
  [NationalityCode.SZ]: 'Swaziland',
  [NationalityCode.TC]: 'Turks- och Caicosöarna',
  [NationalityCode.TD]: 'Tchad',
  [NationalityCode.TF]: 'Franska södra territorierna',
  [NationalityCode.TG]: 'Togo',
  [NationalityCode.TH]: 'Thailand',
  [NationalityCode.TJ]: 'Tadzjikistan',
  [NationalityCode.TK]: 'Tokelauöarna',
  [NationalityCode.TL]: 'Östtimor',
  [NationalityCode.TM]: 'Turkmenistan',
  [NationalityCode.TN]: 'Tunisien',
  [NationalityCode.TO]: 'Tonga',
  [NationalityCode.TR]: 'Turkiet',
  [NationalityCode.TT]: 'Trinidad och Tobago',
  [NationalityCode.TV]: 'Tuvalu',
  [NationalityCode.TW]: 'Taiwan',
  [NationalityCode.TZ]: 'Tanzania',
  [NationalityCode.UA]: 'Ukraina',
  [NationalityCode.UG]: 'Uganda',
  [NationalityCode.UM]: 'Förenta staternas mindre öar i Oceanien och Västindien',
  [NationalityCode.US]: 'USA',
  [NationalityCode.UY]: 'Uruguay',
  [NationalityCode.UZ]: 'Uzbekistan',
  [NationalityCode.VA]: 'Vatikanstaten',
  [NationalityCode.VC]: 'Saint Vincent och Grenadinerna',
  [NationalityCode.VE]: 'Venezuela',
  [NationalityCode.VG]: 'Brittiska Jungfruöarna',
  [NationalityCode.VI]: 'Amerikanska Jungfruöarna',
  [NationalityCode.VN]: 'Vietnam',
  [NationalityCode.VU]: 'Vanuatu',
  [NationalityCode.WF]: 'Wallis- och Futunaöarna',
  [NationalityCode.WS]: 'Samoa',
  [NationalityCode.YE]: 'Jemen',
  [NationalityCode.YT]: 'Mayotte',
  [NationalityCode.ZA]: 'Sydafrika',
  [NationalityCode.ZM]: 'Zambia',
  [NationalityCode.ZW]: 'Zimbabwe',
}

export const WorkoutActivityTypeNames: Record<WorkoutActivityType, string> = {
  [WorkoutActivityType.AEROBICS]: 'Aerobics',
  [WorkoutActivityType.AMERICAN_FOOTBALL]: 'Amerikansk fotboll',
  [WorkoutActivityType.ARCHERY]: 'Bågskytte',
  [WorkoutActivityType.AUSTRALIAN_FOOTBALL]: 'Australiensisk fotboll',
  [WorkoutActivityType.BADMINTON]: 'Badminton',
  [WorkoutActivityType.BARRE]: 'Barre',
  [WorkoutActivityType.BASEBALL]: 'Baseball',
  [WorkoutActivityType.BASKETBALL]: 'Basketboll',
  [WorkoutActivityType.BIATHLON]: 'Skidskytte',
  [WorkoutActivityType.BOWLING]: 'Bowling',
  [WorkoutActivityType.BOXING]: 'Boxning',
  [WorkoutActivityType.CALISTHENICS]: 'Gymnastik',
  [WorkoutActivityType.CIRCUIT_TRAINING]: 'Cirkelträning',
  [WorkoutActivityType.CLIMBING]: 'Klättring',
  [WorkoutActivityType.CORE_TRAINING]: 'Core-träning',
  [WorkoutActivityType.CRICKET]: 'Cricket',
  [WorkoutActivityType.CROSS_COUNTRY_SKIING]: 'Längdskidor',
  [WorkoutActivityType.CROSS_TRAINING]: 'Crosstraining',
  [WorkoutActivityType.CROSSFIT]: 'Crossfit',
  [WorkoutActivityType.CURLING]: 'Curling',
  [WorkoutActivityType.CYCLING]: 'Cykling',
  [WorkoutActivityType.DANCE_INSPIRED_TRAINING]: 'Dansinspirerad träning',
  [WorkoutActivityType.DANCE]: 'Dans',
  [WorkoutActivityType.DISC_SPORTS]: 'Discsport',
  [WorkoutActivityType.DIVING]: 'Dykning',
  [WorkoutActivityType.DOWNHILL_SKIING]: 'Utförsåkning',
  [WorkoutActivityType.ELEVATOR]: 'Elevator',
  [WorkoutActivityType.ELLIPTICAL]: 'Elliptisk träning',
  [WorkoutActivityType.EQUESTRIAN_SPORTS]: 'Equestrian sport',
  [WorkoutActivityType.ERGOMETER]: 'Ergometer',
  [WorkoutActivityType.ESCALATOR]: 'Rulltrappa',
  [WorkoutActivityType.FENCING]: 'Fäktning',
  [WorkoutActivityType.FISHING]: 'Fiske',
  [WorkoutActivityType.FITNESS_GAMING]: 'Fitness gaming',
  [WorkoutActivityType.FLEXIBILITY]: 'Flexibilitet',
  [WorkoutActivityType.FUNCTIONAL_STRENGTH_TRAINING]: 'Funktionell styrketräning',
  [WorkoutActivityType.GARDENING]: 'Trädgårdsarbete',
  [WorkoutActivityType.GOLF]: 'Golf',
  [WorkoutActivityType.GUIDED_BREATHING]: 'Guidad andning',
  [WorkoutActivityType.GYMNASTICS]: 'Gymnastik',
  [WorkoutActivityType.HAND_CYCLING]: 'Handcykling',
  [WorkoutActivityType.HANDBALL]: 'Handboll',
  [WorkoutActivityType.HIGH_INTENSITY_INTERVAL_TRAINING]: 'Högintensiv intervallträning',
  [WorkoutActivityType.HIKING]: 'Vandring',
  [WorkoutActivityType.HOCKEY]: 'Hockey',
  [WorkoutActivityType.HORSEBACK_RIDING]: 'Ridning',
  [WorkoutActivityType.HOUSEWORK]: 'Hushållsarbete',
  [WorkoutActivityType.HUNTING]: 'Jakt',
  [WorkoutActivityType.JUMP_ROPE]: 'Hopprep',
  [WorkoutActivityType.KETTLEBELLS]: 'Kettlebells',
  [WorkoutActivityType.KICKBOXING]: 'Kickboxning',
  [WorkoutActivityType.KITE_SKIING]: 'Drakskidåkning',
  [WorkoutActivityType.LACROSSE]: 'Lacrosse',
  [WorkoutActivityType.MARTIAL_ARTS]: 'Kampsport',
  [WorkoutActivityType.MEDITATION]: 'Meditation',
  [WorkoutActivityType.MIND_AND_BODY]: 'Body and mind',
  [WorkoutActivityType.MIXED_CARDIO]: 'Blandad cardio',
  [WorkoutActivityType.MIXED_METABOLIC_CARDIO_TRAINING]: 'Blandad metabolisk cardioträning',
  [WorkoutActivityType.OTHER]: 'Övrigt',
  [WorkoutActivityType.P90X_EXERCISES]: 'P90X-övningar',
  [WorkoutActivityType.PADDLE_SPORTS]: 'Paddelsport',
  [WorkoutActivityType.PARAGLIDING]: 'Skärmflygning',
  [WorkoutActivityType.PILATES]: 'Pilates',
  [WorkoutActivityType.PLAY]: 'Spel',
  [WorkoutActivityType.POLO]: 'Polo',
  [WorkoutActivityType.PREPARATION_AND_RECOVERY]: 'Förberedelse och återhämtning',
  [WorkoutActivityType.RACQUETBALL]: 'Racketboll',
  [WorkoutActivityType.ROLLER_SKIING]: 'Rullskidåkning',
  [WorkoutActivityType.ROWING]: 'Rodd',
  [WorkoutActivityType.RUGBY]: 'Rugby',
  [WorkoutActivityType.RUNNING]: 'Löpning',
  [WorkoutActivityType.SAILING]: 'Segling',
  [WorkoutActivityType.SCUBA_DIVING]: 'Dykning',
  [WorkoutActivityType.SKATING_SPORTS]: 'Skridskosport',
  [WorkoutActivityType.SNOW_SPORTS]: 'Snösport',
  [WorkoutActivityType.SNOWBOARDING]: 'Snowboard',
  [WorkoutActivityType.SOCCER]: 'Fotboll',
  [WorkoutActivityType.SOFTBALL]: 'Softball',
  [WorkoutActivityType.SPINNING]: 'Spinning',
  [WorkoutActivityType.SQUASH]: 'Squash',
  [WorkoutActivityType.STAIR_CLIMBING]: 'Trappklättring',
  [WorkoutActivityType.STAIRS]: 'Trappa',
  [WorkoutActivityType.STATIONARY_BIKING]: 'Motionscykling',
  [WorkoutActivityType.STEP_TRAINING]: 'Stegträning',
  [WorkoutActivityType.SURFING_SPORTS]: 'Surfsport',
  [WorkoutActivityType.SWIMMING]: 'Simning',
  [WorkoutActivityType.TABLE_TENNIS]: 'Bordtennis',
  [WorkoutActivityType.TAI_CHI]: 'Tai chi',
  [WorkoutActivityType.TEAM_SPORTS]: 'Lagsport',
  [WorkoutActivityType.TENNIS]: 'Tennis',
  [WorkoutActivityType.TILTING]: 'Tilting',
  [WorkoutActivityType.TRACK_AND_FIELD]: 'Friidrott',
  [WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING]: 'Styrketräning',
  [WorkoutActivityType.TREADMILL]: 'Löpband',
  [WorkoutActivityType.UTILITY_BIKING]: 'Brukscykling',
  [WorkoutActivityType.VOLLEYBALL]: 'Volleyboll',
  [WorkoutActivityType.WAKEBOARDING]: 'Wakeboarding',
  [WorkoutActivityType.WALKING]: 'Gång',
  [WorkoutActivityType.WATER_FITNESS]: 'Vattenfitness',
  [WorkoutActivityType.WATER_POLO]: 'Vattenpolo',
  [WorkoutActivityType.WATER_SPORTS]: 'Vattensport',
  [WorkoutActivityType.WHEELCHAIR_RUN_PACE]: 'Rullstolsträning, löpningstakt',
  [WorkoutActivityType.WHEELCHAIR_WALK_PACE]: 'Rullstolsträning, gångtakt',
  [WorkoutActivityType.WRESTLING]: 'Wrestling',
  [WorkoutActivityType.YOGA]: 'Yoga',
  [WorkoutActivityType.ZUMBA]: 'Zumba',
}

export const KlassikerWorkoutActivityTypes = [
  WorkoutActivityType.CROSS_COUNTRY_SKIING,
  WorkoutActivityType.CYCLING,
  WorkoutActivityType.RUNNING,
  WorkoutActivityType.SWIMMING,
  WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
] as const

export type KlassikerWorkoutActivityType = typeof KlassikerWorkoutActivityTypes[number]

export const KlassikerWorkoutActivityTypeNames: Record<KlassikerWorkoutActivityType, string> = {
  [WorkoutActivityType.CROSS_COUNTRY_SKIING]: 'Skidor',
  [WorkoutActivityType.CYCLING]: 'Cykel',
  [WorkoutActivityType.RUNNING]: 'Löpning',
  [WorkoutActivityType.SWIMMING]: 'Simning',
  [WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING]: 'Styrka',
}

export const KlassikerWorkoutActivityTypeMappings: Partial<Record<WorkoutActivityType, KlassikerWorkoutActivityType>> = {
  [WorkoutActivityType.BIATHLON]: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  [WorkoutActivityType.CROSS_COUNTRY_SKIING]: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  [WorkoutActivityType.CROSS_TRAINING]: WorkoutActivityType.RUNNING,
  [WorkoutActivityType.CYCLING]: WorkoutActivityType.CYCLING,
  [WorkoutActivityType.FUNCTIONAL_STRENGTH_TRAINING]: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
  [WorkoutActivityType.HAND_CYCLING]: WorkoutActivityType.CYCLING,
  [WorkoutActivityType.KETTLEBELLS]: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
  [WorkoutActivityType.ROLLER_SKIING]: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  [WorkoutActivityType.RUNNING]: WorkoutActivityType.RUNNING,
  [WorkoutActivityType.SKATING_SPORTS]: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  [WorkoutActivityType.SPINNING]: WorkoutActivityType.CYCLING,
  [WorkoutActivityType.STATIONARY_BIKING]: WorkoutActivityType.CYCLING,
  [WorkoutActivityType.SWIMMING]: WorkoutActivityType.SWIMMING,
  [WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING]: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
  [WorkoutActivityType.TREADMILL]: WorkoutActivityType.RUNNING,
  [WorkoutActivityType.UTILITY_BIKING]: WorkoutActivityType.CYCLING,
  [WorkoutActivityType.WALKING]: WorkoutActivityType.RUNNING,
  [WorkoutActivityType.WHEELCHAIR_RUN_PACE]: WorkoutActivityType.RUNNING,
  [WorkoutActivityType.WHEELCHAIR_WALK_PACE]: WorkoutActivityType.RUNNING,
}

export const WorkoutActivityTypeMappings = KlassikerWorkoutActivityTypes.reduce(
  (acc, type) => ({
    ...acc,
    [type]: Object.entries(KlassikerWorkoutActivityTypeMappings).filter(([, value]) => value === type).map(([key]) => key),
  }),
  {} as Record<KlassikerWorkoutActivityType, readonly WorkoutActivityType[]>,
)
