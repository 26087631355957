import React, { useCallback } from 'react'
import { Button, Text } from 'react-native-paper'

import { Column, Row } from './Primitives'

type ListEmptyComponentProps = {
  readonly isError?: boolean,
  readonly refetch?: () => void,
}

const ListEmptyComponent: React.FC<ListEmptyComponentProps> = ({ isError, refetch }) => {
  const onPress = useCallback(() => {
    refetch?.()
  }, [refetch])

  return (
    <Row paddingTop={150}>
      { isError ? (
        <Column center fill>
          <Text>Något gick fel</Text>
          <Button onPress={onPress}>Försök igen</Button>
        </Column>
      ) : (
        <Column center fill>
          <Text>Inget innehåll hittades</Text>
        </Column>
      )}
    </Row>
  )
}

export default ListEmptyComponent
