import * as Haptics from 'expo-haptics'
import React, { useCallback, useMemo } from 'react'
import {
  Platform,
  TouchableNativeFeedback,
} from 'react-native'
import { ActivityIndicator, Text } from 'react-native-paper'
import { PressableOpacity } from 'react-native-pressable-opacity'

import { useTheme } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import Styles from '../utils/Styles'
import { Column, Row } from './Primitives'

import type { FactoryProps } from '../utils/createThemedStylesHook'
import type { PropsWithChildren } from 'react'
import type { ViewStyle } from 'react-native'

const HEIGHT = 55

type RoundedButtonProps = {
  readonly onPress: () => void
  readonly icon?: JSX.Element
  readonly title: string
  readonly titleColor?: string
  readonly borderColor?: string
  readonly backgroundColor?: string
  readonly style?: ViewStyle
  readonly isLoading?: boolean
  readonly margin?: number
  readonly marginX?: number
  readonly marginY?: number
}

type StylesProps = FactoryProps<{
  readonly backgroundColor?: string,
  readonly borderColor?: string,
  readonly borderWidth: number,
  readonly titleColor?: string
}>

const useStyles = createThemedStylesHook(({
  theme,
  backgroundColor,
  titleColor,
  borderColor,
  borderWidth,
}: StylesProps) => ({
  row: {
    flexDirection: 'row',
    elevation: 2,
    borderColor,
    borderWidth,
    shadowColor: 'black',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    borderRadius: 30,
    height: HEIGHT,
    width: '100%',
    backgroundColor: backgroundColor || theme.colors.primary,
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: '700',
    color: titleColor || theme.colors.onPrimary,
  },
  activityIndicator: { position: 'absolute', right: 16 },
}))

const HIT_SLOP = {
  top: 10, bottom: 10, left: 10, right: 10,
}

type ButtonProps = PropsWithChildren<{ readonly rippleColor: string, readonly isLoading: boolean, readonly onPress: () => void }>

const ButtonComponent: React.FC<ButtonProps> = ({
  onPress, isLoading, children, rippleColor,
}) => (Platform.OS === 'android' ? (
  <TouchableNativeFeedback
    accessibilityRole='button'
    onPress={onPress}
    disabled={isLoading}
    hitSlop={HIT_SLOP}
    background={TouchableNativeFeedback.Ripple(rippleColor, false, 80)}
  >
    {children}
  </TouchableNativeFeedback>
) : (
  <PressableOpacity
    accessibilityRole='button'
    onPress={onPress}
    disabled={isLoading}
    style={Styles.flexOne}
    hitSlop={HIT_SLOP}
  >
    {children}
  </PressableOpacity>
))

const RoundedButton: React.FC<RoundedButtonProps> = ({
  onPress, icon, title, titleColor, borderColor, backgroundColor, style, isLoading, margin, marginX, marginY,
}) => {
  const theme = useTheme()

  const styles = useStyles(useMemo(() => ({
    backgroundColor,
    borderColor,
    borderWidth: borderColor ? 2 : 0,
    titleColor,
  }), [backgroundColor, borderColor, titleColor]))

  const onPressInternal = useCallback(() => {
    onPress()
    if (Platform.OS === 'ios') {
      void Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
    }
  }, [onPress])

  return (
    <Row marginX={marginX || margin || 20} marginY={marginY || margin || 5} height={HEIGHT} style={style}>
      <Column fill>
        <ButtonComponent
          onPress={onPressInternal}
          rippleColor={titleColor || theme.colors.onPrimary}
          isLoading={!!isLoading}
        >
          <Row style={styles.row} fill center>
            {icon }
            <Text style={styles.text}>
              {title}
            </Text>
            <ActivityIndicator
              style={styles.activityIndicator}
              animating={!!isLoading}
              color={titleColor || theme.colors.onPrimary}
            />
          </Row>
        </ButtonComponent>
      </Column>
    </Row>
  )
}

export default RoundedButton
