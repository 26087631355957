/* eslint-disable @typescript-eslint/no-var-requires */
export const ad = require('../../assets/flags/ad.png')
export const ae = require('../../assets/flags/ae.png')
export const af = require('../../assets/flags/af.png')
export const ag = require('../../assets/flags/ag.png')
export const ai = require('../../assets/flags/ai.png')
export const al = require('../../assets/flags/al.png')
export const am = require('../../assets/flags/am.png')
export const ao = require('../../assets/flags/ao.png')
export const aq = require('../../assets/flags/aq.png')
export const ar = require('../../assets/flags/ar.png')
export const as = require('../../assets/flags/as.png')
export const at = require('../../assets/flags/at.png')
export const au = require('../../assets/flags/au.png')
export const aw = require('../../assets/flags/aw.png')
export const ax = require('../../assets/flags/ax.png')
export const az = require('../../assets/flags/az.png')
export const ba = require('../../assets/flags/ba.png')
export const bb = require('../../assets/flags/bb.png')
export const bd = require('../../assets/flags/bd.png')
export const be = require('../../assets/flags/be.png')
export const bf = require('../../assets/flags/bf.png')
export const bg = require('../../assets/flags/bg.png')
export const bh = require('../../assets/flags/bh.png')
export const bi = require('../../assets/flags/bi.png')
export const bj = require('../../assets/flags/bj.png')
export const bl = require('../../assets/flags/bl.png')
export const bm = require('../../assets/flags/bm.png')
export const bn = require('../../assets/flags/bn.png')
export const bo = require('../../assets/flags/bo.png')
export const bq = require('../../assets/flags/bq.png')
export const br = require('../../assets/flags/br.png')
export const bs = require('../../assets/flags/bs.png')
export const bt = require('../../assets/flags/bt.png')
export const bv = require('../../assets/flags/bv.png')
export const bw = require('../../assets/flags/bw.png')
export const by = require('../../assets/flags/by.png')
export const bz = require('../../assets/flags/bz.png')
export const ca = require('../../assets/flags/ca.png')
export const cc = require('../../assets/flags/cc.png')
export const cd = require('../../assets/flags/cd.png')
export const cf = require('../../assets/flags/cf.png')
export const cg = require('../../assets/flags/cg.png')
export const ch = require('../../assets/flags/ch.png')
export const ci = require('../../assets/flags/ci.png')
export const ck = require('../../assets/flags/ck.png')
export const cl = require('../../assets/flags/cl.png')
export const cm = require('../../assets/flags/cm.png')
export const cn = require('../../assets/flags/cn.png')
export const co = require('../../assets/flags/co.png')
export const cr = require('../../assets/flags/cr.png')
export const cu = require('../../assets/flags/cu.png')
export const cv = require('../../assets/flags/cv.png')
export const cw = require('../../assets/flags/cw.png')
export const cx = require('../../assets/flags/cx.png')
export const cy = require('../../assets/flags/cy.png')
export const cz = require('../../assets/flags/cz.png')
export const de = require('../../assets/flags/de.png')
export const dj = require('../../assets/flags/dj.png')
export const dk = require('../../assets/flags/dk.png')
export const dm = require('../../assets/flags/dm.png')
export const dom = require('../../assets/flags/do.png')
export const dz = require('../../assets/flags/dz.png')
export const ec = require('../../assets/flags/ec.png')
export const ee = require('../../assets/flags/ee.png')
export const eg = require('../../assets/flags/eg.png')
export const eh = require('../../assets/flags/eh.png')
export const er = require('../../assets/flags/er.png')
export const es = require('../../assets/flags/es.png')
export const et = require('../../assets/flags/et.png')
export const eu = require('../../assets/flags/eu.png')
export const fi = require('../../assets/flags/fi.png')
export const fj = require('../../assets/flags/fj.png')
export const fk = require('../../assets/flags/fk.png')
export const fm = require('../../assets/flags/fm.png')
export const fo = require('../../assets/flags/fo.png')
export const fr = require('../../assets/flags/fr.png')
export const ga = require('../../assets/flags/ga.png')
export const gb = require('../../assets/flags/gb.png')
export const gd = require('../../assets/flags/gd.png')
export const ge = require('../../assets/flags/ge.png')
export const gf = require('../../assets/flags/gf.png')
export const gg = require('../../assets/flags/gg.png')
export const gh = require('../../assets/flags/gh.png')
export const gi = require('../../assets/flags/gi.png')
export const gl = require('../../assets/flags/gl.png')
export const gm = require('../../assets/flags/gm.png')
export const gn = require('../../assets/flags/gn.png')
export const gp = require('../../assets/flags/gp.png')
export const gq = require('../../assets/flags/gq.png')
export const gr = require('../../assets/flags/gr.png')
export const gs = require('../../assets/flags/gs.png')
export const gt = require('../../assets/flags/gt.png')
export const gu = require('../../assets/flags/gu.png')
export const gw = require('../../assets/flags/gw.png')
export const gy = require('../../assets/flags/gy.png')
export const hk = require('../../assets/flags/hk.png')
export const hm = require('../../assets/flags/hm.png')
export const hn = require('../../assets/flags/hn.png')
export const hr = require('../../assets/flags/hr.png')
export const ht = require('../../assets/flags/ht.png')
export const hu = require('../../assets/flags/hu.png')
export const id = require('../../assets/flags/id.png')
export const ie = require('../../assets/flags/ie.png')
export const il = require('../../assets/flags/il.png')
export const im = require('../../assets/flags/im.png')
export const ind = require('../../assets/flags/in.png')
export const io = require('../../assets/flags/io.png')
export const iq = require('../../assets/flags/iq.png')
export const ir = require('../../assets/flags/ir.png')
export const is = require('../../assets/flags/is.png')
export const it = require('../../assets/flags/it.png')
export const je = require('../../assets/flags/je.png')
export const jm = require('../../assets/flags/jm.png')
export const jo = require('../../assets/flags/jo.png')
export const jp = require('../../assets/flags/jp.png')
export const ke = require('../../assets/flags/ke.png')
export const kg = require('../../assets/flags/kg.png')
export const kh = require('../../assets/flags/kh.png')
export const ki = require('../../assets/flags/ki.png')
export const km = require('../../assets/flags/km.png')
export const kn = require('../../assets/flags/kn.png')
export const kp = require('../../assets/flags/kp.png')
export const kr = require('../../assets/flags/kr.png')
export const kw = require('../../assets/flags/kw.png')
export const ky = require('../../assets/flags/ky.png')
export const kz = require('../../assets/flags/kz.png')
export const la = require('../../assets/flags/la.png')
export const lb = require('../../assets/flags/lb.png')
export const lc = require('../../assets/flags/lc.png')
export const li = require('../../assets/flags/li.png')
export const lk = require('../../assets/flags/lk.png')
export const lr = require('../../assets/flags/lr.png')
export const ls = require('../../assets/flags/ls.png')
export const lt = require('../../assets/flags/lt.png')
export const lu = require('../../assets/flags/lu.png')
export const lv = require('../../assets/flags/lv.png')
export const ly = require('../../assets/flags/ly.png')
export const ma = require('../../assets/flags/ma.png')
export const mc = require('../../assets/flags/mc.png')
export const md = require('../../assets/flags/md.png')
export const me = require('../../assets/flags/me.png')
export const mf = require('../../assets/flags/mf.png')
export const mg = require('../../assets/flags/mg.png')
export const mh = require('../../assets/flags/mh.png')
export const mk = require('../../assets/flags/mk.png')
export const ml = require('../../assets/flags/ml.png')
export const mm = require('../../assets/flags/mm.png')
export const mn = require('../../assets/flags/mn.png')
export const mo = require('../../assets/flags/mo.png')
export const mp = require('../../assets/flags/mp.png')
export const mq = require('../../assets/flags/mq.png')
export const mr = require('../../assets/flags/mr.png')
export const ms = require('../../assets/flags/ms.png')
export const mt = require('../../assets/flags/mt.png')
export const mu = require('../../assets/flags/mu.png')
export const mv = require('../../assets/flags/mv.png')
export const mw = require('../../assets/flags/mw.png')
export const mx = require('../../assets/flags/mx.png')
export const my = require('../../assets/flags/my.png')
export const mz = require('../../assets/flags/mz.png')
export const na = require('../../assets/flags/na.png')
export const nc = require('../../assets/flags/nc.png')
export const ne = require('../../assets/flags/ne.png')
export const nf = require('../../assets/flags/nf.png')
export const ng = require('../../assets/flags/ng.png')
export const ni = require('../../assets/flags/ni.png')
export const nl = require('../../assets/flags/nl.png')
export const no = require('../../assets/flags/no.png')
export const np = require('../../assets/flags/np.png')
export const nr = require('../../assets/flags/nr.png')
export const nu = require('../../assets/flags/nu.png')
export const nz = require('../../assets/flags/nz.png')
export const om = require('../../assets/flags/om.png')
export const pa = require('../../assets/flags/pa.png')
export const pe = require('../../assets/flags/pe.png')
export const pf = require('../../assets/flags/pf.png')
export const pg = require('../../assets/flags/pg.png')
export const ph = require('../../assets/flags/ph.png')
export const pk = require('../../assets/flags/pk.png')
export const pl = require('../../assets/flags/pl.png')
export const pm = require('../../assets/flags/pm.png')
export const pn = require('../../assets/flags/pn.png')
export const pr = require('../../assets/flags/pr.png')
export const ps = require('../../assets/flags/ps.png')
export const pt = require('../../assets/flags/pt.png')
export const pw = require('../../assets/flags/pw.png')
export const py = require('../../assets/flags/py.png')
export const qa = require('../../assets/flags/qa.png')
export const re = require('../../assets/flags/re.png')
export const ro = require('../../assets/flags/ro.png')
export const rs = require('../../assets/flags/rs.png')
export const ru = require('../../assets/flags/ru.png')
export const rw = require('../../assets/flags/rw.png')
export const sa = require('../../assets/flags/sa.png')
export const sb = require('../../assets/flags/sb.png')
export const sc = require('../../assets/flags/sc.png')
export const sd = require('../../assets/flags/sd.png')
export const se = require('../../assets/flags/se.png')
export const sg = require('../../assets/flags/sg.png')
export const sh = require('../../assets/flags/sh.png')
export const si = require('../../assets/flags/si.png')
export const sj = require('../../assets/flags/sj.png')
export const sk = require('../../assets/flags/sk.png')
export const sl = require('../../assets/flags/sl.png')
export const sm = require('../../assets/flags/sm.png')
export const sn = require('../../assets/flags/sn.png')
export const so = require('../../assets/flags/so.png')
export const sr = require('../../assets/flags/sr.png')
export const ss = require('../../assets/flags/ss.png')
export const st = require('../../assets/flags/st.png')
export const sv = require('../../assets/flags/sv.png')
export const sx = require('../../assets/flags/sx.png')
export const sy = require('../../assets/flags/sy.png')
export const sz = require('../../assets/flags/sz.png')
export const tc = require('../../assets/flags/tc.png')
export const td = require('../../assets/flags/td.png')
export const tf = require('../../assets/flags/tf.png')
export const tg = require('../../assets/flags/tg.png')
export const th = require('../../assets/flags/th.png')
export const tj = require('../../assets/flags/tj.png')
export const tk = require('../../assets/flags/tk.png')
export const tl = require('../../assets/flags/tl.png')
export const tm = require('../../assets/flags/tm.png')
export const tn = require('../../assets/flags/tn.png')
export const to = require('../../assets/flags/to.png')
export const tr = require('../../assets/flags/tr.png')
export const tt = require('../../assets/flags/tt.png')
export const tv = require('../../assets/flags/tv.png')
export const tw = require('../../assets/flags/tw.png')
export const tz = require('../../assets/flags/tz.png')
export const ua = require('../../assets/flags/ua.png')
export const ug = require('../../assets/flags/ug.png')
export const um = require('../../assets/flags/um.png')
export const us = require('../../assets/flags/us.png')
export const uy = require('../../assets/flags/uy.png')
export const uz = require('../../assets/flags/uz.png')
export const va = require('../../assets/flags/va.png')
export const vc = require('../../assets/flags/vc.png')
export const ve = require('../../assets/flags/ve.png')
export const vg = require('../../assets/flags/vg.png')
export const vi = require('../../assets/flags/vi.png')
export const vn = require('../../assets/flags/vn.png')
export const vu = require('../../assets/flags/vu.png')
export const wf = require('../../assets/flags/wf.png')
export const ws = require('../../assets/flags/ws.png')
export const xk = require('../../assets/flags/xk.png')
export const ye = require('../../assets/flags/ye.png')
export const yt = require('../../assets/flags/yt.png')
export const za = require('../../assets/flags/za.png')
export const zm = require('../../assets/flags/zm.png')
export const zw = require('../../assets/flags/zw.png')
