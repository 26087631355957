import useEvent from '@kingstinct/react/hooks/useEvent'
import { createNavigationContainerRef, useNavigation } from '@react-navigation/native'

import { CmsEnum_Componentlinkgenericlink_Linktopage as LinkToPage, CmsEnum_Newsarticle_Sporttype as SportType } from '../clients/backend.generated'
import { useTheme } from '../contexts/Theme'
import { useOpenInKlassikernThemedBrowser } from '../utils/browser'

import type { CmsLinkFragment } from '../clients/backend.generated'
import type { NewsTabParamList } from '../types'

export const navigationRef = createNavigationContainerRef()

const SPORT_TYPE_MAP: Record<SportType, keyof NewsTabParamList> = {
  [SportType.BIKING]: 'BikingNewsTab',
  [SportType.RUNNING]: 'RunningNewsTab',
  [SportType.SKIING]: 'SkiingNewsTab',
  [SportType.SWIMMING]: 'SwimmingNewsTab',
}

export const useHandleLinkLazy = () => {
  const openInKlassikernThemedBrowser = useOpenInKlassikernThemedBrowser()
  const navigation = useNavigation()
  const theme = useTheme()

  return useEvent((link: CmsLinkFragment) => {
    if (link?.externalUrl) {
      void openInKlassikernThemedBrowser(link.externalUrl)
    } else if (link?.linkToPage) {
      if ([LinkToPage.Profile, LinkToPage.HealthConnections].includes(link.linkToPage)) {
        navigation.navigate(link.linkToPage, { pageColor: theme.pageColor })
      } else {
        navigation.navigate(link.linkToPage as keyof ReactNavigation.RootParamList)
      }
    } else if (link?.linkToNewsForSport) {
      navigation.navigate('NewsListing', { screen: SPORT_TYPE_MAP[link.linkToNewsForSport] })
    } else if (link?.linkToContentPage?.data?.id) {
      navigation.navigate('ContentPage', { id: link.linkToContentPage.data.id })
    } else if (link?.newsArticle?.data?.id) {
      const externalUrl = link.newsArticle.data.attributes?.externalUrl
      if (externalUrl) {
        void openInKlassikernThemedBrowser(externalUrl)
      } else {
        navigation.navigate('NewsArticle', { id: link.newsArticle.data.id })
      }
    } else if (link?.linkToRace?.data?.id) {
      navigation.navigate('RaceTab', { screen: 'RaceScreen', params: { raceId: link.linkToRace.data.id } })
    }
  })
}

const useHandleLink = (link?: CmsLinkFragment | null | undefined) => {
  const handleLinkLazy = useHandleLinkLazy()

  return useEvent(() => {
    if (link) {
      handleLinkLazy(link)
    }
  })
}

export default useHandleLink
