import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { PortalProvider } from '@gorhom/portal'
import { AuthContext, useDecodedToken } from '@kingstinct/react'
import useEvent from '@kingstinct/react/hooks/useEvent'
import { NavigationContainer, useIsFocused } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import * as Linking from 'expo-linking'
import React, { useContext, useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import { AnimatedTabBarNavigator, TabButtonLayout } from 'react-native-animated-nav-tab-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { useActiveWorkoutQuery } from './clients/backend.generated'
import sentry, { routingInstrumentation } from './clients/sentry'
import SnackbarPresentationView from './components/SnackbarPresentationView'
import { EditorContext } from './contexts/EditorMode'
import {
  FIGMA_COLORS, NavigationLightTheme, ThemeProvider, useTheme,
} from './contexts/Theme'
import useListenToUpdates from './hooks/useListenToUpdates'
import usePingMinaSidor from './hooks/usePingMinaSidor'
import usePushNotifications, { useAskForPushPermissions } from './hooks/usePushNotifications'
import useSyncHealth from './hooks/useSyncHealth'
import { navigationRef } from './RootNavigation'
import AuthStartScreen from './screens/auth/AuthStartScreen'
import LoginScreen from './screens/auth/LoginScreen'
import SignupBankIdScreen from './screens/auth/SignupBankIdScreen'
import SignupScreen from './screens/auth/SignupScreen'
import ChallengeDetailsScreen from './screens/ChallengeDetailsScreen'
import ChallengeListingScreen from './screens/ChallengeListingScreen'
import ChallengeScreen from './screens/ChallengeScreen'
import ContentPageScreen from './screens/ContentPageScreen'
import HealthConnectionScreen from './screens/HealthConnectionsScreen'
import HomeScreen from './screens/HomeScreen'
import NationalityCodeSelectionScreen from './screens/NationalityCodeSelectionScreen'
import NewsDetailsScreen from './screens/NewsDetailsScreen'
import NewsListingTabs from './screens/NewsListingTabs'
import NutritionDetailsScreen from './screens/NutritionDetailsScreen'
import NutritionListingScreen from './screens/NutritionListingScreen'
import ProfileScreen from './screens/ProfileScreen'
import RaceScreen from './screens/race/RaceScreen'
import SettingsScreen from './screens/SettingsScreen'
import ShareScreen from './screens/share/ShareScreen'
import WorkoutActivityTypeSelectionScreen from './screens/start/WorkoutActivityTypeSelectionScreen'
import WorkoutHistoryScreen from './screens/start/WorkoutHistoryScreen'
import StartScreen from './screens/StartScreen'
import WorkoutListingTabs from './screens/WorkoutArticleListingTabs'
import WorkoutDetailsScreen from './screens/WorkoutArticleScreen'
import WorkoutDetails from './screens/WorkoutDetailsScreen'

import type {
  AuthStackParamList, ESKRootParamList, HomeStackParamList, ModalParamList, TabParamList,
  ChallengeStackParamList, RaceStackParamList, StartStackParamList, ShareStackParamList,
} from './types'
import type { LinkingOptions } from '@react-navigation/native'

const TAB_ICON_NAME_MAP: Record<keyof TabParamList, keyof typeof MaterialCommunityIcons.glyphMap> = {
  HomeTab: 'home-outline',
  ChallengeTab: 'trophy-outline',
  StartTab: 'play-circle-outline',
  RaceTab: 'medal-outline',
  ShareTab: 'comment-outline',
}

const Stack = createNativeStackNavigator<ChallengeStackParamList & HomeStackParamList & RaceStackParamList & ShareStackParamList & StartStackParamList>()

const Auth = createNativeStackNavigator<AuthStackParamList>()

const SharedStack = (
  <>
    <Stack.Screen name='NewsListing' component={NewsListingTabs} />
    <Stack.Screen name='NewsArticle' component={NewsDetailsScreen} />
    <Stack.Screen name='NutritionListing' component={NutritionListingScreen} />
    <Stack.Screen name='NutritionArticle' component={NutritionDetailsScreen} />
    <Stack.Screen name='WorkoutListing' component={WorkoutListingTabs} />
    <Stack.Screen name='WorkoutArticle' component={WorkoutDetailsScreen} />
    <Stack.Screen name='ContentPage' component={ContentPageScreen} />
    <Stack.Screen name='WorkoutHistory' component={WorkoutHistoryScreen} />
    <Stack.Screen name='ChallengeListingScreen' component={ChallengeListingScreen} />
    <Stack.Screen name='ChallengeDetailsScreen' component={ChallengeDetailsScreen} />
  </>
)

const HomeStack: React.FC = () => (
  <ThemeProvider color='greenInverted'>
    <Stack.Navigator initialRouteName='HomeScreen' screenOptions={{ headerShown: false }}>
      <Stack.Screen name='HomeScreen' component={HomeScreen} />
      {SharedStack}
    </Stack.Navigator>
  </ThemeProvider>
)

const AuthStack: React.FC = () => (
  <>
    <Auth.Navigator initialRouteName='AuthStartScreen' screenOptions={{ headerShown: false }}>
      <Auth.Screen name='AuthStartScreen' component={AuthStartScreen} />
      <Auth.Screen name='LoginScreen' component={LoginScreen} />
      <Auth.Screen name='NationalityCodeSelectionScreen' component={NationalityCodeSelectionScreen} />
      <Auth.Screen name='SignupScreen' component={SignupScreen} />
      <Auth.Screen name='SignupBankIdScreen' component={SignupBankIdScreen} />
    </Auth.Navigator>
    <SnackbarPresentationView />
  </>

)

const ChallengeStack: React.FC = () => (
  <ThemeProvider color='yellow'>
    <Stack.Navigator initialRouteName='ChallengeScreen' screenOptions={{ headerShown: false }}>
      <Stack.Screen name='ChallengeScreen' component={ChallengeScreen} />
      {SharedStack}
    </Stack.Navigator>
  </ThemeProvider>
)

const StartStack: React.FC = () => (
  <ThemeProvider color='pink'>
    <Stack.Navigator initialRouteName='StartScreen' screenOptions={{ headerShown: false }}>
      <Stack.Screen name='StartScreen' component={StartScreen} />
      <Stack.Screen name='WorkoutActivityTypeSelectionScreen' component={WorkoutActivityTypeSelectionScreen} options={{ presentation: 'fullScreenModal' }} />
      {SharedStack}
    </Stack.Navigator>
  </ThemeProvider>
)

const RaceStack: React.FC = () => (
  <Stack.Navigator initialRouteName='RaceScreen' screenOptions={{ headerShown: false }}>
    <Stack.Screen name='RaceScreen' component={RaceScreen} />
    {SharedStack}
  </Stack.Navigator>
)

const ShareStack: React.FC = () => (
  <ThemeProvider color='blue'>
    <Stack.Navigator initialRouteName='ShareScreen' screenOptions={{ headerShown: false }}>
      <Stack.Screen name='ShareScreen' component={ShareScreen} />
      {SharedStack}
    </Stack.Navigator>
  </ThemeProvider>
)

const Tab = AnimatedTabBarNavigator<TabParamList>()

interface TabBarIconProps {
  readonly focused: boolean
  readonly color: string
  readonly size: number
}

function createTabBarIcon(routeName: keyof TabParamList): (props: TabBarIconProps) => React.ReactNode {
  return ({ color/* , focused */, size }: TabBarIconProps) => (
    <MaterialCommunityIcons
      color={color}
      name={TAB_ICON_NAME_MAP[routeName]}
      size={size}
    />
  )
}

const TabNav: React.FC = () => {
  const theme = useTheme()
  const isFocused = useIsFocused()
  const [
    {
      data,
    },
  ] = useActiveWorkoutQuery({ requestPolicy: 'cache-only' })

  return (
    <>
      <Tab.Navigator
        appearance={{
          activeTabBackgrounds: [
            FIGMA_COLORS.ESK_GREEN_2, FIGMA_COLORS.ESK_YELLOW_1, FIGMA_COLORS.ESK_PINK_1, FIGMA_COLORS.ESK_GREEN_2, FIGMA_COLORS.ESK_BLUE_1,
          ],
          shadow: true,
          floating: true,
          tabButtonLayout: TabButtonLayout.VERTICAL,
        }}
        backBehavior='none'
        initialRouteName={data?.activeWorkout ? 'StartTab' : 'ChallengeTab'}
        screenOptions={({ route }) => ({
          headerShown: false,
          tabBarIcon: createTabBarIcon(route.name),
          tabBarActiveTintColor: theme.colors.primary,
          tabBarInactiveTintColor: 'gray',
        })}
      >

        <Tab.Screen name='HomeTab' component={HomeStack} options={{ tabBarLabel: 'Hem' }} />
        <Tab.Screen name='ChallengeTab' component={ChallengeStack} options={{ tabBarLabel: 'Utmana' }} />
        <Tab.Screen name='StartTab' component={StartStack} options={{ tabBarLabel: 'Start' }} />
        <Tab.Screen name='RaceTab' component={RaceStack} options={{ tabBarLabel: 'Lopp' }} />
        <Tab.Screen name='ShareTab' component={ShareStack} options={{ tabBarLabel: 'Dela' }} />

      </Tab.Navigator>

      <SnackbarPresentationView style={{ paddingBottom: 110 }} isVisibleToUser={isFocused} />
    </>
  )
}

const ModalStack = createNativeStackNavigator<ModalParamList>()

const LoggedInStack = () => {
  usePushNotifications()
  useSyncHealth()
  const ask = useAskForPushPermissions()

  useEffect(() => {
    void ask()
  }, [ask])

  return (
    <ModalStack.Navigator
      screenOptions={{
        presentation: 'modal',
        headerShown: false,
      }}
      initialRouteName='Main'
    >
      <ModalStack.Screen
        name='Main'
        options={{ headerShown: false }}
        component={TabNav}
      />
      <ModalStack.Screen
        name='Settings'
        component={SettingsScreen}
      />
      <ModalStack.Screen
        name='Profile'
        component={ProfileScreen}
      />
      <ModalStack.Screen
        name='HealthConnections'
        component={HealthConnectionScreen}
      />
      <ModalStack.Screen
        name='WorkoutDetailsPage'
        component={WorkoutDetails}
      />
    </ModalStack.Navigator>
  )
}

const useLinking = () => {
  const { toggleEditorMode } = useContext(EditorContext)

  const handleUrl = useEvent((url: string) => {
    const parsed = Linking.parse(url)

    const id = parsed.path
    if (parsed?.hostname === 'preview-draft') {
      toggleEditorMode(true)
      const pageType = parsed?.queryParams?.pageType as keyof ESKRootParamList
      navigationRef.navigate(pageType, id ? { id } : undefined)
    } else if (parsed?.hostname === 'preview-publish') {
      const pageType = parsed?.queryParams?.pageType as keyof ESKRootParamList
      navigationRef.navigate(pageType, id ? { id } : undefined)
    }
  })

  const linking: LinkingOptions<ReactNavigation.RootParamList> = useMemo(() => ({
    prefixes: [Linking.createURL('/')],

    async getInitialURL() {
      const url = await Linking.getInitialURL()

      if (url) {
        handleUrl(url)
      }

      return url
    },

    subscribe(listener) {
      const linkingSubscription = Linking.addEventListener('url', ({ url }) => {
        listener(url)
        handleUrl(url)
      })

      return () => {
        linkingSubscription.remove()
      }
    },
  }), [handleUrl])

  return linking
}

const Navigation: React.FC = () => {
  const { hasToken, token } = useContext(AuthContext)
  const decodedToken = useDecodedToken<{readonly sub: string}>(token)

  useEffect(() => {
    sentry.setUser({
      id: decodedToken?.sub,
    })
  }, [decodedToken?.sub])

  useListenToUpdates()
  usePingMinaSidor()

  const linking = useLinking()

  return (
    <SafeAreaProvider>
      <PortalProvider>
        <NavigationContainer
          theme={NavigationLightTheme}
          linking={linking}
          ref={navigationRef}
          onReady={() => {
            // Register the navigation container with the instrumentation
            if (Platform.OS !== 'web') {
              routingInstrumentation.registerNavigationContainer(navigationRef)
            }
          }}
        >

          { hasToken
            ? <LoggedInStack />
            : <AuthStack /> }
        </NavigationContainer>
      </PortalProvider>
    </SafeAreaProvider>
  )
}

export default Navigation
