import React from 'react'
import Animated, {
  FadeInDown, FadeInUp,
} from 'react-native-reanimated'

import {
  Row,
} from '../../components/Primitives'
import ReactionRowItem from './ReactionRowItem'

import type {

  ReactionType,
} from '../../clients/backend.generated'
import type {
  GestureResponderEvent,
} from 'react-native'

const ReactionRow: React.FC<{readonly reactionCounts: readonly any[],
  readonly showAllReactions: (e: GestureResponderEvent) => void,
  readonly onPress: (reaction: ReactionType) => void,
  readonly userReactions: readonly ReactionType[]
}> = React.memo(({
  reactionCounts, showAllReactions, onPress, userReactions,
}) => (
  <Animated.View entering={FadeInUp} exiting={FadeInDown}>
    <Row marginTop={5}>
      { reactionCounts.map(({ reaction, count }) => (
        <ReactionRowItem
          key={reaction}
          hasUserReacted={userReactions.includes(reaction)}
          count={count}
          onPress={onPress}
          reaction={reaction}
          showAllReactions={showAllReactions}
        />
      ))}
    </Row>
  </Animated.View>
))

export default React.memo(ReactionRow)
