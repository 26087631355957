import { AuthContext, useNullableState } from '@kingstinct/react'
import dayjs from 'dayjs'
import React, { useCallback, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Divider, Snackbar } from 'react-native-paper'
import unreachable from 'ts-unreachable'

import { useSignUpMutation, LanguageCode } from '../../clients/backend.generated'
import Background from '../../components/Background'
import ControlledNationalityCodeInput from '../../components/ControlledNationalityCodeInput'
import ControlledTextInput from '../../components/ControlledTextInput'
import DatePicker from '../../components/DatePicker'
import ErrorText from '../../components/ErrorText'
import GenderCodeInput from '../../components/GenderCodeInput'
import Header from '../../components/Header'
import Label from '../../components/Label'
import {
  BottomSpacer, BottomSpacerType, Column, HeaderSpacer,
} from '../../components/Primitives'
import RoundedButton from '../../components/RoundedButton'
import Styles from '../../utils/Styles'

import type { SignupInput } from '../../clients/backend.generated'
import type { ESKScreen } from '../../types'
import type { SubmitHandler } from 'react-hook-form'

type FormData = Omit<SignupInput, 'birthDate'> & {
  readonly birthDate: Date
  readonly repeatPassword: string
}

const SignupScreen: ESKScreen<'SignupScreen'> = ({ route: { params } }) => {
  const { setToken } = useContext(AuthContext),
        {
          handleSubmit, setError, setValue, ...form
        } = useForm<FormData>({
          defaultValues: {
            languageCode: LanguageCode.sv,
          },
        }),
        [{ fetching }, signupMutation] = useSignUpMutation(),
        [errorMessage, setErrorMessage, unsetErrorMessage] = useNullableState<string>()

  const { errors } = form.formState

  useEffect(() => {
    if (params?.nationalityCode != null) setValue('nationalityCode', params.nationalityCode)
  }, [params?.nationalityCode, setValue])

  const onSubmit = useCallback<SubmitHandler<FormData>>(async ({ birthDate, repeatPassword, ...input }) => {
    if (repeatPassword !== input.password) {
      setError('repeatPassword', { message: 'Vänligen kontrollera att du skrivit samma lösenord' })
      return
    }

    const { data, error } = await signupMutation({
      input: {
        ...input,
        birthDate: dayjs(birthDate).format('YYYY-MM-DD'),
      },
    })

    if (data?.signup == null) {
      if (error?.message) {
        setErrorMessage(error?.message)
      } else {
        unsetErrorMessage()
      }
    } else if (data.signup.__typename === 'AuthenticationSuccess') {
      setToken(data.signup.token)
    } else if (data.signup.__typename === 'AuthenticationFailure') {
      setErrorMessage(data.signup.message)
    } else {
      unreachable(data.signup)
    }
  }, [
    setToken, setError, setErrorMessage, signupMutation, unsetErrorMessage,
  ])

  return (
    <Column fill>
      <Background
        waveMode='bottom'
        activityIcons='none'
      />

      <KeyboardAwareScrollView
        enableOnAndroid
        extraHeight={80}
        extraScrollHeight={80}
        // https://github.com/APSL/react-native-keyboard-aware-scroll-view/issues/502
        keyboardOpeningTime={0}
        keyboardShouldPersistTaps='handled'
      >
        <HeaderSpacer />
        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'words',
            autoComplete: 'name-given',
            autoCorrect: false,
            autoFocus: true,
            keyboardType: 'default',
            textContentType: 'givenName',
          }}
          label='Förnamn'
          name='firstName'
          nextFocusName='lastName'
          rules={{
            maxLength: { value: 50, message: 'Max 50 tecken' },
            required: { value: true, message: 'Var vänlig fyll i förnamn' },
          }}
        />

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'words',
            autoComplete: 'name-family',
            autoCorrect: false,
            keyboardType: 'default',
            textContentType: 'familyName',
          }}
          label='Efternamn'
          name='lastName'
          nextFocusName='birthDate'
          rules={{
            maxLength: { value: 50, message: 'Max 50 tecken' },
            required: { value: true, message: 'Var vänlig fyll i efternamn' },
          }}
        />

        <Label title='Födelsedatum'>
          <Controller
            control={form.control}
            name='birthDate'
            render={({ field: { value, ...rest } }) => (
              <DatePicker
                initialDate={dayjs().subtract(30, 'years').toDate()}
                label='Födelsedatum'
                maximumDate={dayjs().subtract(10, 'years').toDate()}
                minimumDate={dayjs().subtract(120, 'years').toDate()}
                value={value ? new Date(value) : undefined}
                {...rest}
              />
            )}
            rules={{
              required: { value: true, message: 'Var vänlig fyll i födelsedatum' },
            }}
          />
          <ErrorText>{errors.birthDate?.message}</ErrorText>
        </Label>

        <ControlledNationalityCodeInput
          callbackScreen='SignupScreen'
          form={form}
          name='nationalityCode'
        />

        <Label title='Kön'>
          <Controller
            control={form.control}
            name='genderCode'
            render={({ field: { onChange, value } }) => (
              <GenderCodeInput onChange={onChange} value={value} />
            )}
            rules={{
              required: { value: true, message: 'Var vänlig fyll i kön' },
            }}
          />
          <ErrorText>{errors.genderCode?.message}</ErrorText>
        </Label>

        <Divider style={Styles.marginBottom16} />

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'none',
            autoComplete: 'email',
            autoCorrect: false,
            keyboardType: 'email-address',
            textContentType: 'emailAddress',
            returnKeyType: 'next',
          }}
          label='E-postadress'
          name='email'
          nextFocusName='password'
          rules={{
            pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: 'Ej giltig e-postadress' },
            required: { value: true, message: 'Var vänlig fyll i e-postadress' },
          }}
        />

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'none',
            autoComplete: 'password-new',
            autoCorrect: false,
            secureTextEntry: true,
            textContentType: 'newPassword',
          }}
          label='Lösenord'
          name='password'
          nextFocusName='repeatPassword'
          rules={{
            minLength: { value: 6, message: 'Minst 6 tecken' },
            required: { value: true, message: 'Var vänlig fyll i lösenord' },
          }}
        />

        <ControlledTextInput
          form={form}
          inputProps={{
            autoCapitalize: 'none',
            autoCorrect: false,
            onSubmitEditing: handleSubmit(onSubmit),
            returnKeyType: 'done',
            secureTextEntry: true,
          }}
          label='Repetera lösenord'
          name='repeatPassword'
          rules={{
            minLength: { value: 6, message: 'Minst 6 tecken' },
            required: { value: true, message: 'Var vänlig fyll i lösenord' },
          }}
        />

        <RoundedButton
          title='Skapa användare'
          onPress={handleSubmit(onSubmit)}
          style={Styles.margin16}
          isLoading={fetching}
        />

        <BottomSpacer
          type={BottomSpacerType.noBottomNavigation}
        />
      </KeyboardAwareScrollView>
      <Header />

      <Snackbar
        onDismiss={unsetErrorMessage}
        visible={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </Column>
  )
}

export default SignupScreen
