import { useBoolean } from '@kingstinct/react'
import { ImageBackground, StyleSheet } from 'react-native'

import type { ImageURISource } from 'react-native'

const styles = StyleSheet.create({
  image: {
    aspectRatio: 1.7, // Almost the golden ratio of 1.618
    flex: 1,
  },
})

export interface FullWidthImageProps {
  readonly overlay?: React.ReactNode
  readonly uri: ImageURISource['uri']
}

const FullWidthImage: React.FC<FullWidthImageProps> = ({ overlay, uri }) => {
  const [hasImageError, setImageError] = useBoolean()

  if (!uri || hasImageError) return null

  return (
    <ImageBackground
      accessibilityIgnoresInvertColors
      onError={setImageError}
      source={{ uri }}
      style={styles.image}
    >
      {overlay}
    </ImageBackground>
  )
}

export default FullWidthImage
