import React from 'react'
import { Image, View } from 'react-native'
import { PressableOpacity } from 'react-native-pressable-opacity'
import { Shadow } from 'react-native-shadow-2'

import { CmsEnum_Componentcontentpuff_Style as CmsStyle } from '../clients/backend.generated'
import { FIGMA_COLORS } from '../contexts/Theme'
import useHandleLink from '../hooks/useHandleLink'
import createThemedStylesHook, { createThemedText, createThemedView } from '../utils/createThemedStylesHook'
import { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'

import type { CmsPuffFragment } from '../clients/backend.generated'

function backgroundColorForStyle(style: CmsPuffFragment['style']): string {
  switch (style) {
    case CmsStyle.GREEN: return FIGMA_COLORS.ESK_GREEN_1
    default: return FIGMA_COLORS.YELLOW
  }
}

function fontColorForStyle(style: CmsPuffFragment['style']): string {
  switch (style) {
    case CmsStyle.GREEN: return FIGMA_COLORS.ESK_VIT
    default: return FIGMA_COLORS.ESK_GRAY_2
  }
}

export const TitleContainer = createThemedView(({ style }: { readonly style: CmsPuffFragment['style'] }) => ({
  backgroundColor: backgroundColorForStyle(style),
  borderRadius: 16,
  height: 32,
  justifyContent: 'center',
  minWidth: '61.8%', // The Golden Ratio :-)
  overflow: 'hidden',
  position: 'absolute',
  top: 159 - 32 + 6,
}))

export const Title = createThemedText(({ style }: { readonly style: CmsPuffFragment['style'] }) => ({
  color: fontColorForStyle(style),
  fontSize: 12,
  fontWeight: '700',
  textAlign: 'center',
  textTransform: 'uppercase',
}))

const SubtitleContainer = createThemedView(({ size }) => ({
  backgroundColor: FIGMA_COLORS.ESK_VIT,
  justifyContent: 'center',
  minHeight: 62,
  padding: 8,
  paddingTop: 12,
  width: size.width - 64,
}))

const Subtitle = createThemedText(() => ({
  color: FIGMA_COLORS.ESK_GRAY_2,
  fontSize: 20,
  fontWeight: '700',
  lineHeight: 22,
  textAlign: 'center',
}))

const useStyles = createThemedStylesHook(({ size }) => ({
  card: {
    alignItems: 'center',
    borderRadius: 8,
    overflow: 'hidden',
  },
  image: {
    height: 159,
    width: size.width - 64,
  },
}))

interface Props {
  readonly data: CmsPuffFragment
}

const CmsPuffComponent: React.FC<Props> = ({
  data: {
    image, style, subtitle, title, link,
  },
}) => {
  const styles = useStyles(),
        handleLink = useHandleLink(link)

  return (
    <PressableOpacity onPress={handleLink}>
      <Shadow
        distance={2}
        offset={DEFAULT_SHADOW_OFFSET}
      >
        <View style={styles.card}>
          <Image
            accessibilityIgnoresInvertColors
            source={{ uri: image.data?.attributes?.url }}
            style={styles.image}
          />
          <SubtitleContainer>
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleContainer>
          {/* Title is abolutely positioned and must be on top of Subtitle */}
          <TitleContainer style={style}>
            <Title style={style}>{title}</Title>
          </TitleContainer>
        </View>
      </Shadow>
    </PressableOpacity>
  )
}

export default CmsPuffComponent
