import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import {
  AuthContext, Column, Row, Styles,
} from '@kingstinct/react'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { Alert, Platform, ScrollView } from 'react-native'
import { ActivityIndicator, List } from 'react-native-paper'

import { useDeleteUserMutation, useProfilePageQuery } from '../clients/backend.generated'
import Background from '../components/Background'
import Header from '../components/Header'
import { BottomSpacer, BottomSpacerType, HeaderSpacer } from '../components/Primitives'
import RoundedButton from '../components/RoundedButton'
import SnackbarPresentationView from '../components/SnackbarPresentationView'
import { FIGMA_COLORS, ThemeProvider } from '../contexts/Theme'
import { CountryNames } from '../types'

import type { ESKScreen } from '../types'

const ProfileScreen: ESKScreen<'Profile'> = ({ route }) => {
  const { clearToken } = useContext(AuthContext),
        [{ data, fetching }] = useProfilePageQuery(),
        [, deleteUser] = useDeleteUserMutation()

  return (
    <ThemeProvider color={route?.params?.pageColor || 'green'}>
      <Column fill>
        <Background activityIcons='none' />
        { fetching ? (
          <Row padding={50} center>
            <ActivityIndicator />
          </Row>
        ) : (
          <ScrollView contentContainerStyle={Styles.flexOne}>
            <HeaderSpacer />
            <Row center>
              <Column backgroundColor='white' padding={16} borderRadius={32}>
                <MaterialCommunityIcons
                  name='account-circle-outline'
                  size={32}
                  color={FIGMA_COLORS.ESK_GRAY_2}
                />
              </Column>
            </Row>

            { data?.me ? (
              <Column padding={16} marginTop={32}>
                <List.Item
                  title={data.me.firstName}
                  description='Förnamn'
                />

                <List.Item
                  title={data.me.lastName}
                  description='Efternamn'
                />

                { data.me.personalNumber ? (
                  <List.Item
                    // The asterisk is not centered in the font we use, so we use the unicode character instead
                    // U+2217 ASTERISK OPERATOR ∗ (this is centered in some fonts, but not others)
                    title={`${data.me.personalNumber.substring(0, 8)}-\u2217\u2217\u2217\u2217`}
                    description='Personnummer'
                  />
                ) : (data.me.birthDate ? (
                  <List.Item
                    title={dayjs(data.me.birthDate).format('DD MMMM YYYY')}
                    description='Födelsedatum'
                  />
                ) : null)}

                <List.Item
                  title={CountryNames[data.me.nationalityCode]}
                  description='Nationalitet'
                />

                <List.Item
                  title={data.me.email}
                  description='E-postadress'
                />
              </Column>
            ) : null }

            <Column fill />

            <Row>
              <RoundedButton
                title='Ta bort användare'
                onPress={() => {
                  Alert.alert(
                    'Är du säker?',
                    'Du kommer att bli utloggad och ditt konto kommer att tas bort. Det går inte att ångra detta.',
                    [
                      { text: 'Avbryt', style: 'cancel' },
                      {
                        text: 'OK',
                        style: 'destructive',
                        onPress: async () => {
                          await deleteUser({ })
                          clearToken()
                        },
                      },
                    ],
                    { cancelable: true },
                  )
                }}
                style={Styles.flexOne}
              />
            </Row>

            <BottomSpacer
              type={BottomSpacerType.noBottomNavigation}
            />
          </ScrollView>
        )}

        <Header
          title='Profil'
          right='close'
          backIcon='window-close'
          insetTop={Platform.OS === 'ios' ? 30 : undefined}
        />
        <SnackbarPresentationView />
      </Column>
    </ThemeProvider>
  )
}

export default ProfileScreen
