import { useAppState, useEvent } from '@kingstinct/react'
import useAddSnackbar from '@kingstinct/react/hooks/useAddSnackbar'
import { useCallback, useEffect } from 'react'

import {
  useGoogleFitSyncMutation, useSyncInfoQuery,
} from '../clients/backend.generated'
import { useSyncAppleHealthData } from './appleHealth'
import useOnPressConnectWithGoogle from './useOnPressConnectWithGoogle'

import type { GoogleFitSyncMutation } from '../clients/cache.generated'

export const useSyncGoogleFit = () => {
  const [{ data }] = useSyncInfoQuery(),
        [{ data: syncData }, syncGoogleFit] = useGoogleFitSyncMutation(),
        isConnectedWithGoogleFit = data?.me?.isConnectedWithGoogleFit,
        [reconnect] = useOnPressConnectWithGoogle(),
        showSnackbar = useAddSnackbar()

  const handlePotentialErrors = useEvent((syncResponse: GoogleFitSyncMutation) => {
    if (syncResponse.googleFitSync.__typename === 'GoogleFitError') {
      showSnackbar('Något gick fel vid synkronisering med Google Fit, försök igen senare', { id: 'google-fit-error' })
    } else if (syncResponse.googleFitSync.__typename === 'GoogleFitErrorShouldReauthenticate') {
      showSnackbar(syncResponse.googleFitSync.message, {
        id: 'google-fit-broken',
        actions: [
          {
            label: 'Koppla igen',
            onPress: async () => {
              await reconnect()
              await syncGoogleFit({})
            },
          },
        ],
      })
    }
  })

  useEffect(() => {
    if (syncData) {
      void handlePotentialErrors(syncData)
    }
  }, [syncData, handlePotentialErrors])

  return useCallback(async () => {
    if (isConnectedWithGoogleFit) {
      await syncGoogleFit({})
    }
    return Promise.resolve()
  }, [isConnectedWithGoogleFit, syncGoogleFit])
}

const useSyncHealth = () => {
  const syncGoogleFit = useSyncGoogleFit()
  const syncAppleHealth = useSyncAppleHealthData()
  const appState = useAppState()

  useEffect(() => {
    if (appState === 'active') {
      void syncGoogleFit()
      void syncAppleHealth()
    }
  }, [syncGoogleFit, appState, syncAppleHealth])
}

export default useSyncHealth
