import React from 'react'
import { Image, StyleSheet } from 'react-native'

import * as flag from '../utils/flagIndex'

import type { NationalityCode } from '../clients/backend.generated'

const styles = StyleSheet.create({
  image: {
    height: 32,
    width: 1.6 * 32,
  },
})

function source(code: NationalityCode) {
  // This switch case is just there because you can't name variables "in" and "do"
  const isoCode = code.toLowerCase()
  switch (isoCode) {
    case 'in': return flag.ind
    case 'do': return flag.dom
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    default: return flag[isoCode]
  }
}

interface Props {
  readonly code: NationalityCode
}

const Flag: React.FC<Props> = ({ code }: Props) => (
  <Image accessibilityIgnoresInvertColors source={source(code)} style={styles.image} />
)

export default React.memo(Flag)
