import { Styles } from '@kingstinct/react'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { Image } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import {
  ProgressBar, Surface, Text, Title,
} from 'react-native-paper'

import {
  CmsEnum_Userchallenge_Goaltype as GoalType, useJoinUserChallengeMutation, UserChallengeStatus, useUserChallengeByIdQuery,
} from '../clients/backend.generated'
import Background from '../components/Background'
import CmsContent from '../components/CmsContent'
import Header from '../components/Header'
import { BottomSpacer, Column, HeaderSpacer } from '../components/Primitives'
import RefetchControl from '../components/RefetchControl'
import RoundedButton from '../components/RoundedButton'
import { FIGMA_COLORS } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'

import type { UserChallengeByIdQuery } from '../clients/backend.generated'
import type { ESKScreen } from '../types'
import type { PropsWithChildren } from 'react'

const useStyles = createThemedStylesHook(({ theme }) => ({
  image: { width: '100%', height: 221 },
  labelDivider: { width: 2, height: '100%', backgroundColor: theme.colors.accent },
  labelSubtitle: { textAlign: 'center', fontSize: 10, fontWeight: '700' },
  labelTitle: { textAlign: 'center', fontSize: 14 },
  title: {
    color: FIGMA_COLORS.GRAY_2, fontSize: 28, fontWeight: '700', marginTop: 24, textAlign: 'center',
  },
}))

type ChallengeProps = {
  readonly userChallenge: NonNullable<NonNullable<NonNullable<Required<UserChallengeByIdQuery>['userChallenge']>['data']>['attributes']>
}

export const formatNumber = (goalType: GoalType, number: number) => (goalType === GoalType.WORKOUT_DISTANCE_TOTAL_IN_METERS ? Math.round(number / 100) / 10 : number)

export const distanceDescription = (goalType: GoalType) => (goalType === GoalType.WORKOUT_COUNT ? 'träningspass' : (goalType === GoalType.WORKOUT_DISTANCE_TOTAL_IN_METERS ? 'km' : 'minuter'))

const userProgressBarStyle = {
  padding: 16, backgroundColor: 'white', flex: 1, borderRadius: 5, marginBottom: 16,
}

const congratulationsText = { paddingBottom: 20, fontSize: 16, fontWeight: 'bold' } as const

const UserProgressBar: React.FC<PropsWithChildren<ChallengeProps>> = ({ userChallenge, children }) => {
  const description = distanceDescription(userChallenge.goalType),
        start = dayjs(userChallenge.startDate),
        end = dayjs(userChallenge.endDate),
        sameMonth = start.month() === end.month(),
        sameYear = start.year() === end.year(),
        progress = Math.min(1, userChallenge.userProgress / userChallenge.goalAmount),
        hasStarted = dayjs().isAfter(start),
        hasJoined = userChallenge.userStatus === UserChallengeStatus.HAS_JOINED

  return (
    <Surface style={userProgressBarStyle}>
      <Column style={Styles.alignItemsCenter}>
        {children}
        <Text style={Styles.paddingBottom16}>{ `${start.format(sameMonth && sameYear ? 'D' : (sameYear ? 'D MMMM' : 'D MMMM YYYY'))} - ${end.format('D MMMM YYYY')}`}</Text>
        { hasStarted && hasJoined ? (
          <Text style={progress !== 1 ? Styles.paddingBottom16 : {}}>
            <Text style={Styles.bold}>{ formatNumber(userChallenge.goalType, userChallenge.userProgress) }</Text>
            { ` av ${formatNumber(userChallenge.goalType, userChallenge.goalAmount)} ${description}`}
          </Text>
        ) : (
          <Text>
            { `${formatNumber(userChallenge.goalType, userChallenge.goalAmount)} ${description}`}
          </Text>
        ) }

      </Column>

      { progress !== 1 && hasStarted && hasJoined ? (
        <ProgressBar
          progress={progress}
        />
      ) : null }

    </Surface>
  )
}

const UserProgressContent: React.FC<ChallengeProps> = ({ userChallenge }) => {
  const [, joinChallenge] = useJoinUserChallengeMutation()

  const onPressJoin = useCallback(() => joinChallenge({ challengeId: userChallenge.id }), [joinChallenge, userChallenge.id])

  if (userChallenge.userStatus === UserChallengeStatus.CAN_JOIN) {
    return (
      <UserProgressBar userChallenge={userChallenge}>
        <RoundedButton
          onPress={onPressJoin}
          style={Styles.marginBottom16}
          title='Gå med i utmaning'
        />
      </UserProgressBar>
    )
  }

  if (userChallenge.userProgress >= userChallenge.goalAmount) {
    return (
      <>
        <UserProgressBar userChallenge={userChallenge}>
          <Text style={congratulationsText}>Grattis, du har klarat utmaningen!</Text>
        </UserProgressBar>

        <CmsContent
          animate
          data={userChallenge.lockedContent}
          keyPrefix={userChallenge.id}
        />
      </>
    )
  }

  return <UserProgressBar userChallenge={userChallenge} />
}

const ChallengeDetailsScreen: ESKScreen<'ChallengeDetailsScreen'> = ({ route }) => {
  const styles = useStyles()

  const [{ data }, refetch] = useUserChallengeByIdQuery({
    variables: {
      id: route.params.id,
    },
  })

  const challengeData = data?.userChallenge?.data

  return (
    <Column fill>
      <Background activityIcons='none' />

      <ScrollView
        refreshControl={(
          <RefetchControl
            onRefetch={refetch}
          />
        )}
      >
        <HeaderSpacer />
        <Image
          source={{ uri: challengeData?.attributes?.headerImage.data?.attributes?.url }}
          accessibilityIgnoresInvertColors
          style={styles.image}
        />
        {/* <CmsLabelsComponent labels={challengeData?.attributes?.labels} /> */}
        <Title style={styles.title}>
          {challengeData?.attributes?.title}
        </Title>
        <Column paddingX={30} paddingTop={12}>
          {
            challengeData?.attributes ? <UserProgressContent userChallenge={challengeData.attributes} /> : null
          }
          <CmsContent
            animate
            data={challengeData?.attributes?.content}
            keyPrefix={challengeData?.id}
          />
        </Column>
        <BottomSpacer />
      </ScrollView>
      <Header
        backIcon='keyboard-backspace'
        right='settings'
      />
    </Column>
  )
}

export default ChallengeDetailsScreen
