import React from 'react'
import {
  Image, StyleSheet, View,
} from 'react-native'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import KlassikerActivityIcons from './KlassikerActivityIcons'

import type { PageColor } from '../contexts/Theme'
import type { FactoryProps } from '../utils/createThemedStylesHook'

const ASPECT_RATIO_TOP_IMAGE = 640 / 1500
const ASPECT_RATIO_BOTTOM_IMAGE = 948 / 1500

const COLORS: Record<PageColor, { readonly waveColor: string, readonly backgroundColor: string }> = {
  blue: { backgroundColor: FIGMA_COLORS.ESK_BLUE_3, waveColor: FIGMA_COLORS.ESK_BLUE_2 },
  green: { backgroundColor: FIGMA_COLORS.ESK_GREEN_4, waveColor: FIGMA_COLORS.ESK_GREEN_3 },
  greenInverted: { backgroundColor: FIGMA_COLORS.ESK_GREEN_3, waveColor: FIGMA_COLORS.ESK_GREEN_4 },
  pink: { backgroundColor: FIGMA_COLORS.ESK_PINK_3, waveColor: FIGMA_COLORS.ESK_PINK_2 },
  yellow: { backgroundColor: FIGMA_COLORS.ESK_YELLOW_3, waveColor: FIGMA_COLORS.ESK_YELLOW_2 },
}

type StyleProps = FactoryProps<{ readonly color: PageColor }>;

const useStyles = createThemedStylesHook(({ color, size: { width } }: StyleProps) => ({
  topImage: {
    width,
    tintColor: COLORS[color].waveColor,
    top: 0,
    marginTop: 25,
    height: width * ASPECT_RATIO_TOP_IMAGE,
  },
  topBarImage: {
    width,
    tintColor: COLORS[color].waveColor,
    top: 0,
    marginTop: -32,
    height: width * ASPECT_RATIO_TOP_IMAGE,
  },
  bottomImage: {
    width,
    tintColor: COLORS[color].waveColor,
    bottom: 0,
    height: width * ASPECT_RATIO_BOTTOM_IMAGE,
    position: 'absolute',
  },
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: COLORS[color].backgroundColor,
  },
}))

type Props = {
  readonly waveMode?: 'bottom' | 'top'
  readonly activityIcons?: 'bottom' | 'none' | 'top'
  readonly isTopBar?: boolean
}

const Background: React.FC<Props> = ({ waveMode = 'top', activityIcons, isTopBar }) => {
  const theme = useTheme()
  const styles = useStyles({ color: theme.pageColor })

  return (
    <View style={styles.container}>
      { waveMode === 'top' ? (
        <Image
          resizeMode='contain'
          source={require('../../assets/esk_wave_top.png')}
          accessibilityIgnoresInvertColors
          style={isTopBar ? styles.topBarImage : styles.topImage}
        />
      ) : null }

      { waveMode === 'bottom' ? (
        <Image
          resizeMode='contain'
          source={require('../../assets/esk_wave_bottom.png')}
          accessibilityIgnoresInvertColors
          style={styles.bottomImage}
        />
      ) : null }

      {activityIcons !== 'none' ? (
        <KlassikerActivityIcons
          mode={activityIcons || waveMode}
        />
      ) : null }
    </View>
  )
}

export default Background
