import useAddSnackbar from '@kingstinct/react/hooks/useAddSnackbar'
import storage from '@react-native-async-storage/async-storage'
import { nativeApplicationVersion, nativeBuildVersion } from 'expo-application'
import * as Updates from 'expo-updates'
import { useEffect } from 'react'

import { cacheStorage } from '../clients/urql'

const CACHE_CLEARED_KEY = 'CACHE_CLEARED_FOR_VERSION'

const useListenToUpdates = () => {
  const showSnackbar = useAddSnackbar()

  useEffect(() => {
    if (Updates.isEmergencyLaunch) {
      void cacheStorage.clear()
    }
  }, [])

  useEffect(() => {
    const fn = async () => {
      if (nativeApplicationVersion && nativeBuildVersion) {
        const version = await storage.getItem(CACHE_CLEARED_KEY)

        const currentVersion = `${nativeApplicationVersion} - ${nativeBuildVersion}`
        if (version !== currentVersion) {
          await cacheStorage.clear()
          await storage.setItem(CACHE_CLEARED_KEY, currentVersion)
        }
      }
    }
    void fn()
  }, [])

  useEffect(() => {
    const onUpdateAvailable = async () => {
      await Updates.fetchUpdateAsync()

      showSnackbar('En app-uppdatering finns tillgänglig', {
        actions: [
          {
            label: 'Uppdatera nu',
            onPress: async () => {
              await cacheStorage.clear()
              void Updates.reloadAsync()
            },
          },
        ],
      })
    }

    const subscription = Updates.addListener((event: Updates.UpdateEvent) => {
      if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
        void onUpdateAvailable()
      }
    })

    return () => {
      subscription.remove()
    }
  }, [showSnackbar])
}

export default useListenToUpdates
