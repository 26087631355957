import { useAppState, useEvent } from '@kingstinct/react'
import Healthkit from '@kingstinct/react-native-healthkit'
import { useEffect, useState } from 'react'

import { authorizationsToRequest } from './appleHealth'

import type {
  HKAuthorizationRequestStatus,
  HealthkitReadAuthorization,
} from '@kingstinct/react-native-healthkit'

const useAppleHealthAuthorizationStatus = (forPermissions: readonly HealthkitReadAuthorization[]) => {
  const [status, setStatus] = useState<HKAuthorizationRequestStatus>()
  const appState = useAppState()

  const refreshPermission = useEvent(async (p: readonly (HealthkitReadAuthorization)[]) => {
    const authorization = await Healthkit.getRequestStatusForAuthorization(p)
    setStatus(authorization)
  })

  useEffect(() => {
    if (forPermissions && appState) {
      void refreshPermission(forPermissions)
    }
  }, [refreshPermission, forPermissions, appState])

  const request = useEvent(async () => {
    const res = await Healthkit.requestAuthorization(authorizationsToRequest)
    void refreshPermission(forPermissions)
    return res
  })

  return [status, request] as const
}

export default useAppleHealthAuthorizationStatus
