import React from 'react'
import {
  Image, StyleSheet,
} from 'react-native'
import { Text } from 'react-native-paper'
import { PressableOpacity } from 'react-native-pressable-opacity'
import { Shadow } from 'react-native-shadow-2'

import { FIGMA_COLORS, useTheme } from '../contexts/Theme'
import useHandleLink from '../hooks/useHandleLink'
import createThemedStylesHook from '../utils/createThemedStylesHook'
import { DEFAULT_SHADOW_OFFSET } from '../utils/Styles'
import { Column, Row } from './Primitives'

import type { CmsMinipuffFragment } from '../clients/backend.generated'

interface Props {
  readonly data: CmsMinipuffFragment
}

const useStyles = createThemedStylesHook(({ theme, size: { width } }) => ({
  shadow: { width: '100%', borderRadius: 5 },
  textWrapper: {
    borderRadius: 40, backgroundColor: theme.colors.primary, minWidth: '61.8%', height: 28,
  },
  text: {
    fontSize: 14, fontWeight: '700', color: FIGMA_COLORS.ESK_GRAY_4, textAlign: 'center',
  },
  image: {
    ...StyleSheet.absoluteFillObject, tintColor: theme.colors.primary, width, height: 75,
  },
}))

const CmsMiniPuffComponent: React.FC<Props> = ({ data }) => {
  const theme = useTheme(),
        styles = useStyles(),
        handleLink = useHandleLink(data?.link)

  return (
    <PressableOpacity onPress={handleLink}>
      <Column marginY={10}>
        <Shadow
          style={styles.shadow}
          offset={DEFAULT_SHADOW_OFFSET}
          distance={2}
        >
          <Column width='100%' backgroundColor={theme.colors.accent} borderRadius={5} padding={10} center style={{ overflow: 'hidden' }} height={75}>
            <Image
              source={require('../../assets/pattern.png')}
              style={styles.image}
              resizeMode='cover'
              accessibilityIgnoresInvertColors
            />
            <Row
              center
              style={styles.textWrapper}
              paddingX={10}
              paddingY={2}
            >

              <Text style={styles.text}>
                {data.title}
              </Text>
            </Row>
          </Column>
        </Shadow>
      </Column>
    </PressableOpacity>
  )
}

export default CmsMiniPuffComponent
